import React, { Component } from "react";
import { BrowserRouter, Route, Switch, withRouter } from "react-router-dom";
import { Loading } from "react-admin";

import { loadAppConfig } from "./lib/appConfig";
import { RAManageApp } from "./ra-manage";
import { RARecipApp } from "./ra-recip";
// import { RAViewApp } from "./ra-view";
import { RAMainApp } from "./ra-main";
import { hasHash, authorize, isAuthenticated } from "./lib/auth0";

// HoC to intercept unauthenticated requests and send them to Auth0
const withAuth = (Element) => {
  return (props) => {
    if (isAuthenticated() || hasHash()) return <Element {...props} />;
    authorize();
  };
};

// Add auth and routing information with HOC
const WrappedRARecipApp = withRouter(RARecipApp);
const WrappedRAManageApp = withAuth(withRouter(RAManageApp));
const WrappedRAMainApp = withAuth(RAMainApp);

// This App wrapper loads the app config and then
// manages routing between our two React-Admin apps
class App extends Component {
  constructor(props) {
    super(props);
    this.state = { loaded: false, _isMounted: false };
  }

  componentDidMount() {
    this._isMounted = true;
    loadAppConfig().then(() => {
      if (this._isMounted) this.setState({ loaded: true });
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    if (!this.state.loaded) {
      return (
        <Loading
          loadingPrimary="Loading"
          loadingSecondary="Bandie is loading, just a moment please"
        />
      );
    }

    return (
      <BrowserRouter>
        <Switch>
          <Route path="/recip/:key">
            <WrappedRARecipApp />
          </Route>
          <Route path="/manage/:bandid">
            <WrappedRAManageApp />
          </Route>
          <Route>
            <WrappedRAMainApp />
          </Route>
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
