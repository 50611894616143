import React from "react";
import { useForm, useFormState } from "react-final-form";
import {
  ReferenceManyField,
  Datagrid,
  SaveButton,
  DeleteButton,
  Button,
  fetchEnd,
  fetchStart,
  useRefresh,
  showNotification,
  useDataProvider,
  SimpleForm,
} from "react-admin";

import { BandieTooltip } from "../ra-common/BandieTip";
import Tooltip from "@material-ui/core/Tooltip";
import IconContentAdd from "@material-ui/icons/Add";
import IconContentEdit from "@material-ui/icons/Edit";
import IconCancel from "@material-ui/icons/Cancel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// import { GetTimezoneIfExists } from "../ra-common/time";

// Wrapper that can get the submit handler of the inner form,
// and send it to back to the caller via a callback.
const QCSubmitExtractor = ({ children, cb }) => {
  const form = useForm();
  if (cb) cb(form.submit);
  return children;
};

// Our version of SimpleForm
export const EventForm = ({ cb, ...props }) => {
  return (
    <SimpleForm {...props} variant="standard">
      <QCSubmitExtractor cb={cb}>{props.children}</QCSubmitExtractor>
    </SimpleForm>
  );
};

const CreateEventButton = ({ Form, eventDefaults, eventType, members, title, disabled }) => {
  const [showDialog, setShowDialog] = React.useState(false);
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  // Submit handler of the inner final form
  let submitHandler;

  const handleOpenDialog = () => setShowDialog(true);

  const handleCloseDialog = () => setShowDialog(false);

  const handleSave = () => {
    submitHandler();
    setShowDialog(false);
  };

  const handleSubmit = async (values) => {
    fetchStart();
    // console.log('Values',values)
    try {
      await dataProvider.create(eventType, { data: values, });
      refresh();
      setShowDialog(false);
      fetchEnd();
    } catch (e) {
      console.log(`error in handleSubmit: ${e.message}`);
      fetchEnd();
      showNotification(e.message, "error");
    }
  };

  return (
    <>
      <Button onClick={handleOpenDialog} disabled={disabled} label="ra.action.create">
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseDialog}
        aria-label={title}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Form
            record={{}}
            initialValues={eventDefaults}
            submitOnEnter={false}
            resource={eventType}
            save={handleSubmit}
            members={members}
            toolbar={null}
            cb={(f) => { submitHandler = f; }}
          />
        </DialogContent>
        <DialogActions>
          <SaveButton
            // didn't look yet what the equivalent is of below
            // saving={isSubmitting}
            onClick={handleSave}
            // need to provide something here or it crashes
            // we handle everything in `handleSave`
            handleSubmitWithRedirect={() => { }}
          />
          <Button label="ra.action.cancel" onClick={handleCloseDialog}>
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const EditEventButton = ({ Form, record, eventType, title, members, dayId, }) => {
  const [showDialog, setShowDialog] = React.useState(false);
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const event = record;

  let submitHandler; // Submit handler of the inner final form

  const handleOpenDialog = () => setShowDialog(true);
  const handleCloseDialog = () => setShowDialog(false);

  const handleSave = () => {
    submitHandler();
    setShowDialog(false);
  };
  const { form } = useForm()

  const handleSubmit = async (values) => {
    fetchStart();
    try {
      await dataProvider.update(eventType, {
        id: event.id,
        data: values,
      });
      form.reset()
      refresh();
      setShowDialog(false);
      fetchEnd();
    } catch (e) {
      console.log(`error in handleSubmit: ${e.message}`);
      fetchEnd();
      showNotification(e.message, "error");
    }
  };

  // Default values for members dropdown requires record (event) values and dayid
  let defaultValues = { ...event, dayid: dayId };

  return (
    <>
      <Button onClick={handleOpenDialog} label="ra.action.edit">
        <IconContentEdit />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseDialog}
        aria-label={title}
      >
        <DialogTitle>
          <Tooltip title={"ID #" + record.id} placement="right" arrow>
            <span>{title}</span>
          </Tooltip>
        </DialogTitle>
        <DialogContent>
          <Form
            initialValues={defaultValues}
            submitOnEnter={false}
            resource={eventType}
            save={handleSubmit}
            toolbar={null}
            members={members}
            cb={(f) => {
              submitHandler = f;
            }}
          />
        </DialogContent>
        <DialogActions>
          <DeleteButton resource={eventType} record={event} redirect={false} />
          <div style={{ flex: "1 0 0" }} />
          <Button label="ra.action.cancel" onClick={handleCloseDialog}>
            <IconCancel />
          </Button>
          <SaveButton
            // didn't look yet what the equivalent is of below
            // saving={isSubmitting}
            onClick={handleSave}
            // need to provide something here or it crashes
            // we handle everything in `handleSave`
            handleSubmitWithRedirect={() => { }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

const ReferenceEventsGridHeader = ({ title }) => <h3 style={{ color: "#999" }}>{title}</h3>

/**
* We were computing the dirty state manually,
* somewhere the internal state differs between what is actually saved
* and what is computed. The error seems to be related to the boolWithNotes
* structures. For simplicity, this seems to be the most budget friendly fix.
*
* The issue with BoolWithNotes has been resolved, this is no longer needed
*/
/*
const useIsDirty = () => {
  const { initialValues, values } = useFormState()
  console.log('dirtyFields', dirty, dirtyFields)
  return dirty
  return useMemo(() => {
    const initWithDefaults = cloneDeep(initialValues)
    defaultsDeep(initWithDefaults, values)
    const dirty = !isEqual(initWithDefaults, values)
    const diffObj = diff(initWithDefaults, values)
    console.log('initWithDefaults', initWithDefaults)
    console.log('Dirty', dirty, diffObj)
    if (dirty) {
      console.log(diffObj)
      const cleaned = removeEmpty(diffObj)
    }
    return dirty
  }, [initialValues, values])
}
*/

// Used to embed a list of referenced events that relate to the given day
// Props:
//  - eventType: plural resource name (string)
//  - form: A component containing an <EventForm/> that has all the
//          fields for creating/editing this type of event
//  - name: capitalized human-readable name for this event type
//  - title: capitalized human-readable plural name
export const ReferenceDayEventsGrid = props => {
  const { eventType, form, name, title, tooltip, enableCreate, enableEdit, ...rest } = props
  const day = rest.record;
  const { dirty } = useFormState()

  // Day record must exist prior to creating events
  if (!day.id || !day.date) return <div>Save day prior to entering {eventType}.</div>;

  // Fetch band, crew and team list from Day record to pass as members prop to Edit and Create buttons
  const { band, crew, team } = day.data ?? {}
  const dayMembers = [...(band || []), ...(crew || []), ...(team || [])];
  const header = <ReferenceEventsGridHeader title={title} />
  // const raDateInfo = { date: day.date, timezone: GetTimezoneIfExists(day.data) }

  return (
    <>
      {tooltip ? <BandieTooltip tooltip={tooltip}>{header}</BandieTooltip> : header}

      <ReferenceManyField
        {...rest} addLabel={false} reference={eventType} source="date" target="date"
        sort={({ field: "start.date", order: "ASC" }, { field: "start.time", order: "ASC" })}
      >
        <Datagrid>
          {rest.children}
          {enableEdit && <EditEventButton
            Form={form} record={day} eventType={eventType}
            title={"Edit " + name} members={dayMembers} dayId={day.id}
          />}
        </Datagrid>
      </ReferenceManyField>

      {enableCreate && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <CreateEventButton
          eventDefaults={{ dayId: day.id, defaultTimezone: day?.data?.defaultTimezone }}
          Form={form} eventType={eventType}
          title={"Create " + name} members={dayMembers}
          disabled={dirty}
        />
        {dirty && <div style={{ fontSize: 13, marginLeft: 10, color: '#9F9F9F' }}>Please save first before creating an event</div>}
      </div>}

    </>
  );
};

export const ReferenceShowEventsGrid = props => {
  const { eventType, form, name, title, tooltip, enableCreate, enableEdit, ...rest } = props
  const show = rest.record;
  const {
    dirty,
    // ...formState 
  } = useFormState()
  // console.log('dirty', formState)

  // Day record must exist prior to creating events
  if (!show.id || !show.dayId) return <div>Save show prior to entering {name}.</div>;

  const header = <ReferenceEventsGridHeader title={title} />

  return (
    <>
      {tooltip ? <BandieTooltip tooltip={tooltip}>{header}</BandieTooltip> : header}

      <ReferenceManyField
        {...rest} addLabel={false} reference={eventType} source="id" target="showId"
        sort={({ field: "start.date", order: "ASC" }, { field: "start.time", order: "ASC" })}
      >
        <Datagrid>
          {rest.children}
          {enableEdit && <EditEventButton
            Form={form} record={show} eventType={eventType}
            title={"Edit " + name} showId={show.id}
          />}
        </Datagrid>
      </ReferenceManyField>

      {enableCreate && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <CreateEventButton
          eventDefaults={{ dayId: show.dayId, showId: show.id }}
          Form={form} eventType={eventType}
          title={"Create " + name}
          disabled={dirty}
        />
        {dirty && <div style={{ fontSize: 13, marginLeft: 10, color: '#9F9F9F' }}>Please save first before creating an event</div>}
      </div>}

    </>
  );
};
