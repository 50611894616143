import { createTheme } from "@material-ui/core/styles";
import indigo from "@material-ui/core/colors/indigo";
import red from "@material-ui/core/colors/red";

export default createTheme({
  palette: {
    primary: indigo,
    secondary: {
      main: "#22A89E",
      contrastText: "#fff",
    },
    error: red,
  },
  overrides: {
    MuiFormGroup: {
      root: {
        width: "auto",
      },
    },
    RaInput: {
      p: {
        whiteSpace: "pre-line",
      },
    },
    RaFormInput: {
      input: {
        width: "492px",
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: "#daf0ee",
        color: "black",
        padding: 20,
        fontSize: 13,
        boxShadow: "0px 11px 15px -7px rgba(0,0,0,0.2)",
      },
      arrow: {
        color: "#daf0ee",
      },
    },

    // Hack to improve display of popup modal forms
    MuiFormControl: {
      root: {
        marginRight: 20,
      },
    },
    MuiTextField: {
      root: {
        minWidth: 240,
      },
    },

    // Workaround for scrollable tabs until this PR gets released:
    // https://github.com/marmelab/react-admin/issues/1689
    MuiTabs: {
      scrollable: {
        width: 0,
      },
    },

    // Make inline tables use all available width
    MuiTable: {
      root: {
        width: "inherit !important",
        minWidth: "100%",
      },
    },

    // Allow rich text link modal to show above
    MuiCard: {
      root: {
        overflow: "visible",
      },
    },
  },
});
