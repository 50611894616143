import React, { Component } from "react";
import { Loading } from "react-admin";

// This implements a simple black & white page that is
// optimized for printing.
export class PrintGuestlistPage extends Component {
  state = {};

  async componentDidMount() {
    const { dataProvider, match } = this.props
    const result = await dataProvider.getOne("shows", { id: match.params.showId })
    this.setState({ show: result.data });
  }

  // Check each time the render() completes.
  // When `state.day` transitions from undefined to truthy,
  // the page has fully rendered, and we can trigger the print dialog
  componentDidUpdate(_, prevState) {
    if (prevState.show === undefined && this.state.show) {
      window.print();
    }
  }

  render() {
    const { pressList, bandName } = this.props
    const { show } = this.state

    if (!show) {
      return <Loading
        loadingPrimary="Loading"
        loadingSecondary="Bandie is loading, just a moment please"
      />
    }

    // This component does double-duty:
    // Render press or guest list depending on the `pressList` prop
    let list, title;
    if (pressList) {
      title = "Press List";
      list = show?.pressPasses?.list ?? []
    } else {
      title = "Guest List";
      list = show?.guestList?.list ?? [];
    }

    return (
      <div style={styles.page}>
        <h1>{bandName}</h1>
        <h2>{title} — {show ? show.date : ""}</h2>
        <p style={styles.poweredBy}>Powered by BandieApp.com</p>
        <table style={styles.table}>
          <tbody>
            {list.map((item) => (
              <tr key={item.name}>
                <td style={styles.cell}>{item.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

const styles = {
  page: {
    textAlign: "center",
    padding: 20,
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
  },
  table: {
    marginTop: "2em",
    width: "100%",
    borderCollapse: "collapse",
  },
  cell: {
    padding: "0.5em 0",
    borderTop: "0.1pt solid black",
    borderBottom: "0.1pt solid black",
  },
  poweredBy: {
    fontSize: "75%",
    fontStyle: "italic",
  },
};
