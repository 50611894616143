import React from "react";
import {
  SimpleForm,
  Toolbar,
  SaveButton,
  FormDataConsumer,
  FileInput,
  FileField,
} from "react-admin";
import {
  AdvanceHeader,
  CondTextField,
  CondLongTextInput,
  getAccommQuestionText,
  getMeetAndGreetQuestionText,
  AddlQuestionsRecip,
  RAConditional,
  FileDownloadLink,
  AdvanceInfoSectionV2
} from "../ra-common/advance";
import { getProp } from "../lib/util";

const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const PromoterAdvanceEditV2 = (props) => {
  return (
    <SimpleForm toolbar={<CustomToolbar />} variant="standard" {...props}>
      <h1 style={{ color: "#777" }}>{props.record.data_v2.title}</h1>
      <AdvanceHeader show="data_v2.general">
        General Show Questions
      </AdvanceHeader>

      <CondLongTextInput
        show="data_v2.general.venueCapacity"
        source="response.general.venueCapacity"
        label="What is the venue capacity?"
      />
      <CondLongTextInput
        show="data_v2.general.otherActs"
        source="response.general.otherActs"
        label="Are there any other acts performing?"
      />
      <CondLongTextInput
        show="data_v2.general.allAges"
        source="response.general.allAges"
        label="Is the show all ages?"
      />

      <AdvanceHeader show="data_v2.transportation">
        Transportation
      </AdvanceHeader>

      <CondLongTextInput
        show="data_v2.transportation.shuttle"
        source="response.transportation.shuttle"
        label="Is a shuttle or transport provided by the venue? If so, please provide details."
      />

      <AddlQuestionsRecip path="transportation.addlQuestions" />

      <AdvanceHeader show="data_v2.accommodations">
        Accommodations
      </AdvanceHeader>

      <CondTextField
        source="data_v2.accommodations.details"
        label="Existing Details"
        richText={true}
      />
      <FormDataConsumer>
        {({ formData, ...rest }) => (
          <CondLongTextInput
            show="data_v2.accommodations.question"
            source="response.accommodations.response"
            label={getAccommQuestionText(
              getProp(formData, "data_v2.accommodations.question")
            )}
            {...rest}
          />
        )}
      </FormDataConsumer>

      <AdvanceHeader show="data_v2contacts">Contacts</AdvanceHeader>
      <CondLongTextInput
        show="data_v2.contacts.dayOfShow"
        source="response.contacts.dayOfShow"
        label="Who is the day of show contact?"
      />
      <CondLongTextInput
        show="data_v2.contacts.production"
        source="response.contacts.production"
        label="Who is the production/sound contact?"
      />
      <CondLongTextInput
        show="data_v2.contacts.sound"
        source="response.contacts.sound"
        label="Who is the sound contact?"
      />
      <CondLongTextInput
        show="data_v2.contacts.lighting"
        source="response.contacts.lighting"
        label="Who is the lighting contact?"
      />
      <CondLongTextInput
        show="data_v2.contacts.backline"
        source="response.contacts.backline"
        label="Who is the backline contact?"
      />
      <CondLongTextInput
        show="data_v2.contacts.hospitality"
        source="response.contacts.hospitality"
        label="Who is the hospitality contact?"
      />
      <CondLongTextInput
        show="data_v2.contacts.transport"
        source="response.contacts.transport"
        label="Who is the transport contact?"
      />
      <CondLongTextInput
        show="data_v2.contacts.merchandise"
        source="response.contacts.merchandise"
        label="Who is the merchandise contact?"
      />
      <CondLongTextInput
        show="data_v2.contacts.marketing"
        source="response.contacts.marketing"
        label="Who is the marketing/social media contact?"
      />
      <CondLongTextInput
        show="data_v2.contacts.payment"
        source="response.contacts.payment"
        label="Who do we collect payment from after the show?"
      />

      <AddlQuestionsRecip path="contacts.addlQuestions" />

      <AdvanceHeader show="data_v2.timings">Timings</AdvanceHeader>
      <CondLongTextInput
        show="data_v2.timings.loadIn"
        source="response.timings.loadIn"
        label="What is our load in time?"
      />
      <CondLongTextInput
        show="data_v2.timings.soundcheck"
        source="response.timings.soundcheck"
        label="What is our soundcheck time?"
      />
      <CondLongTextInput
        show="data_v2.timings.darkStage"
        source="response.timings.darkStage"
        label="Are there any dark stage times that must be observed?"
      />
      <CondLongTextInput
        show="data_v2.timings.doors"
        source="response.timings.doors"
        label="What is doors time?"
      />
      <CondLongTextInput
        show="data_v2.timings.setLength"
        source="response.timings.setLength"
        label="What is our set length?"
      />
      <CondLongTextInput
        show="data_v2.timings.setStart"
        source="response.timings.setStart"
        label="What is our set start time?"
      />
      <CondLongTextInput
        show="data_v2.timings.otherBands"
        source="response.timings.otherBands"
        label="Are there any other band set times that we need to be aware of?"
      />
      <CondLongTextInput
        show="data_v2.timings.showCurfew"
        source="response.timings.showCurfew"
        label="Is there a show curfew? (ie. time the show must end due to noise bylaws, scheduling, etc.?)"
      />
      <CondLongTextInput
        show="data_v2.timings.venueCurfew"
        source="response.timings.venueCurfew"
        label="Is there a venue curfew? (ie. do we need to be completed load-out at a certain time?)"
      />

      <AdvanceHeader show="data_v2.loadIn">Load-in / Parking</AdvanceHeader>
      <CondLongTextInput
        show="data_v2.loadIn.instructions"
        source="response.loadIn.instructions"
        label="Are there any specific load-in instructions (location of load-in, etc.)?"
      />
      <CondLongTextInput
        show="data_v2.loadIn.tempParking"
        source="response.loadIn.tempParking"
        label="Is there specific parking available for load-in?"
      />
      <CondLongTextInput
        show="data_v2.loadIn.permParking"
        source="response.loadIn.permParking"
        label="Where do we park after load-in?"
      />

      <AdvanceHeader show="data_v2.production">Production / Stage</AdvanceHeader>

      <CondLongTextInput
        show="data_v2.production.backlineConfirmed"
        source="response.production.backlineConfirmed"
        label="I have attached our backline list. Can you confirm our backline needs will be met?"
      />
      <CondLongTextInput
        show="data_v2.production.fohConsole"
        source="response.production.fohConsole"
        label="What is the FOH console?"
      />
      <CondLongTextInput
        show="data_v2.production.stageSize"
        source="response.production.stageSize"
        label="What are the stage dimensions?"
      />
      <CondLongTextInput
        show="data_v2.production.projector"
        source="response.production.projector"
        label="Are there screens? If so, do you need us to provide visuals?"
      />
      <CondLongTextInput
        show="data_v2.production.banner"
        source="response.production.banner"
        label="Can we put stage signage/banners onstage during our set?"
      />

      <AddlQuestionsRecip path="production.addlQuestions" />

      <CondTextField
        source="data_v2.production.monitorDetails"
        label="Monitor Details"
      />
      <CondLongTextInput
        show="data_v2.production.monitorDetails"
        source="response.production.monitorDetails"
        label="Additional notes about monitor details?"
      />

      <CondTextField
        source="data_v2.production.iemFrequencies"
        label="IEM Frequencies"
      />
      <CondLongTextInput
        show="data_v2.production.iemFrequencies"
        source="response.production.iemFrequencies"
        label="Additional notes about IEM frequencies?"
      />

      <AdvanceHeader show="data_v2.hospitality">Hospitality</AdvanceHeader>

      <CondLongTextInput
        show="data_v2.hospitality.greenRoom"
        source="response.hospitality.greenRoom"
        label="Is there a green room provided for the band?"
      />

      <CondLongTextInput
        show="data_v2.hospitality.privateBathrooms"
        source="response.hospitality.privateBathrooms"
        label="Are there private bathroom facilities available for the band?"
      />

      <CondLongTextInput
        show="data_v2.hospitality.showers"
        source="response.hospitality.showers"
        label="Are shower facilities available at the venue?"
      />

      <CondLongTextInput
        show="data_v2.hospitality.info"
        source="response.hospitality.info"
        label="I have attached our rider to this email. Please let us know what you will be providing for hospitality!"
      />

      <CondLongTextInput
        show="data_v2.hospitality.meals"
        source="response.hospitality.meals"
        label="Are any meals being provided? If so, please provide details, meal timings, etc."
      />

      <CondLongTextInput
        show="data_v2.hospitality.mealBuyout"
        source="response.hospitality.mealBuyout"
        label="Is there a meal buyout for band and crew for the show? Please specify."
      />

      <CondLongTextInput
        show="data_v2.hospitality.wifi"
        source="response.hospitality.wifi"
        label="Is wifi available at the venue? If so, please provide network name and password."
      />

      <AdvanceHeader show="data_v2.merchandise">Merchandise</AdvanceHeader>

      <CondLongTextInput
        show="data_v2.merchandise.sellerProvided"
        source="response.merchandise.sellerProvided"
        label="Is a Merch Seller provided?"
      />

      <CondLongTextInput
        show="data_v2.merchandise.split"
        source="response.merchandise.split"
        label="What is the merch split?"
      />

      <CondLongTextInput
        show="data_v2.merchandise.limit"
        source="response.merchandise.limit"
        label="Is there a limit to the number of items that we can sell?"
      />

      <CondLongTextInput
        show="data_v2.merchandise.priceMatch"
        source="response.merchandise.priceMatch"
        label="Will we need to price match with the headlining artist?"
      />

      <CondLongTextInput
        show="data_v2.merchandise.racksProvided"
        source="response.merchandise.racksProvided"
        label="Are merch racks provided by the venue?"
      />

      <CondLongTextInput
        show="data_v2.merchandise.location"
        source="response.merchandise.location"
        label="Where in the venue will the merch area be located?"
      />

      <AdvanceHeader show="data_v2.marketing">Marketing</AdvanceHeader>

      <CondLongTextInput
        show="data_v2.marketing.announceDate"
        source="response.marketing.announceDate"
        label="What is the announce date for the show?"
      />

      <CondLongTextInput
        show="data_v2.marketing.ticketPrice"
        source="response.marketing.ticketPrice"
        label="What is the ticket price?"
      />

      <CondLongTextInput
        show="data_v2.marketing.onSaleDate"
        source="response.marketing.onSaleDate"
        label="When do tickets go onsale?"
      />

      <CondLongTextInput
        show="data_v2.marketing.ticketOutlet"
        source="response.marketing.ticketOutlet"
        label="What are the ticket outlets?"
      />

      <CondLongTextInput
        show="data_v2.marketing.sponsors"
        source="response.marketing.sponsors"
        label="Are there any event sponsors/presenting radio stations, etc. that we should be aware of?"
      />

      <CondLongTextInput
        show="data_v2.marketing.socialMedia"
        source="response.marketing.socialMedia"
        label="Can you please send me all the social media links/handles/hashtags for the venue/event?"
      />

      <CondLongTextInput
        show="data_v2.marketing.plan"
        source="response.marketing.plan"
        label="What is your marketing/promotional plan for the event?"
      />

      <CondLongTextInput
        show="data_v2.marketing.requests"
        source="response.marketing.requests"
        label="If there is anything you would like us to do in terms of press/online/social media promotion on our end, please let us know here."
      />

      <FormDataConsumer>
        {({ formData, ...rest }) => (
          <CondLongTextInput
            show="data_v2.marketing.meetAndGreet"
            source="response.marketing.meetAndGreet"
            label={getMeetAndGreetQuestionText(
              getProp(formData, "data_v2.marketing.meetAndGreet")
            )}
            {...rest}
          />
        )}
      </FormDataConsumer>

      <AdvanceHeader show="data_v2.info.bandSocialMedia">
        Band Social Media
      </AdvanceHeader>
      <CondTextField
        source="data_v2.info.bandSocialMedia.facebook"
        label="Facebook"
      />
      <CondTextField
        source="data_v2.info.bandSocialMedia.instagram"
        label="Instagram"
      />
      <CondTextField source="data_v2.info.bandSocialMedia.tiktok" label="Tiktok" />
      <CondTextField
        source="data_v2.info.bandSocialMedia.twitter"
        label="Twitter"
      />
      <CondTextField
        source="data_v2.info.bandSocialMedia.website"
        label="Website"
      />
      <AdvanceHeader show="data_v2.pressPasses">Press Pass</AdvanceHeader>

      <CondLongTextInput
        show="data_v2.pressPasses.contact"
        source="response.pressPasses.contact"
        label="If we receive press pass requests, who should we talk to in order to have those requests authorized?"
      />

      <CondLongTextInput
        show="data_v2.pressPasses.instructions"
        source="response.pressPasses.instructions"
        label="Are there any specific press pass instructions? (how to pick up pass, photography restrictions, etc.)"
      />

      <CondLongTextInput
        show="data_v2.pressPasses.submitDeadline"
        source="response.pressPasses.submitDeadline"
        label="When do you need press pass requests submitted by?"
      />

      <AdvanceHeader show="data_v2.guestList">Guest List</AdvanceHeader>

      <CondLongTextInput
        show="data_v2.guestList.numberOfSpots"
        source="response.guestList.numberOfSpots"
        label="Do we get any guest list spots for the show? If so, how many?"
      />

      <CondLongTextInput
        show="data_v2.guestList.instructions"
        source="response.guestList.instructions"
        label="Are there any specific guest list instructions? (how to pick up tickets, etc.)"
      />

      <CondLongTextInput
        show="data_v2.guestList.submitDeadline"
        source="response.guestList.submitDeadline"
        label="When do you need us to submit our guest list by? Who do we submit them to?"
      />

      <AddlQuestionsRecip path="pressPasses.addlQuestions" />

      <AdvanceHeader show="data_v2.addlQuestions">
        Additional Questions
      </AdvanceHeader>
      <AddlQuestionsRecip path="addlQuestions" />

      <AdvanceHeader show="data_v2.attachments">Attachments</AdvanceHeader>
      <FileDownloadLink
        source="data_v2.attachments.techRequirements"
        label="Stage Plot & Tech Requirements (PDF)"
      />
      <FileDownloadLink
        source="data_v2.attachments.backlineRequests"
        label="Backline Requests (PDF)"
      />
      <FileDownloadLink
        source="data_v2.attachments.lighting"
        label="Lighting Document (PDF)"
      />
      <FileDownloadLink
        source="data_v2.attachments.visuals"
        label="Visuals for Screens (download)"
      />
      <FileDownloadLink
        source="data_v2.attachments.rider"
        label="Hospitality Rider (PDF)"
      />
      <FileDownloadLink
        source="data_v2.attachments.longBios"
        label="Long Bio (PDF)"
      />
      <FileDownloadLink
        source="data_v2.attachments.shortBios"
        label="Short Bio (PDF)"
      />
      <FileDownloadLink
        source="data_v2.attachments.pressPhotos"
        label="Press Photos (Download)"
      />
      <FileDownloadLink
        source="data_v2.attachments.socialLinks"
        label="Band Social Media Links"
      />
      <FileDownloadLink
        source="data_v2.attachments.bandLogos"
        label="Band Logos (Download)"
      />
      <FileDownloadLink source="data_v2.attachments.mp3s" label="MP3s (Download)" />
      <FileDownloadLink source="data_v2.attachments.others" label="Others" />


      <AdvanceHeader show="data_v2.bandContacts">Band Contacts</AdvanceHeader>
      <CondTextField
        source="data_v2.bandContacts.dayOfShowContact"
        label="Day of Show Contact"
      />
      <CondTextField
        source="data_v2.bandContacts.merchContact"
        label="Merch Contact"
      />
      <CondTextField
        source="data_v2.bandContacts.socialMediaContact"
        label="Social Media Contact"
      />
      <CondTextField
        source="data_v2.bandContacts.soundTechContact"
        label="Sound Tech Contact"
      />
      <CondTextField
        source="data_v2.bandContacts.managerContact"
        label="Manager Contact"
      />
      <CondTextField
        source="data_v2.bandContacts.agentContact"
        label="Agent Contact"
      />
      <CondTextField
        source="data_v2.bandContacts.publicistContact"
        label="Publicist Contact"
      /> 

      <AdvanceHeader show="data_v2.info">Additional Information</AdvanceHeader>
      <AdvanceInfoSectionV2 />

      <RAConditional source="data_v2.info.allowFileUpload">
        {(props) => {
          return (
            <FileInput
              {...props}
              multiple
              source="response.attachments"
              label="Max file size 20MB"
              accept="image/*,.pdf"
              maxSize={20000000}
            >
              <FileField
                source="src"
                title="title"
                download="Additional attachments"
              />
            </FileInput>
          );
        }}
      </RAConditional>
    </SimpleForm>
  );
};
