import React from "react";
import RichTextInput from "ra-input-rich-text";
import TrinarySwitch from "../components/TrinarySwitch";

export const BoolWithNotesInput = props => {
  const { source, label } = props
  return (
    <div style={{ marginBottom: 19 }}>
      <span style={{ fontSize: "0.75rem", color: "#757575" }}>{label}</span>
      <br />
      <div style={{ display: "flex", alignItems: "center" }}>
        <TrinarySwitch source={source + ".checked"} />
        &nbsp;
        <div style={{ width: "430px" }}>
          <RichTextInput
            toolbar={[["bold", "italic", "underline", "link"]]}
            label={""}
            source={source + ".notes"}
          />
        </div>
      </div>
    </div>
  );
}

export default BoolWithNotesInput
