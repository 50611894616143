import React from "react";
import {
  List,
  Datagrid,
  FileInput,
  FileField,
  SimpleForm,
  TextField,
  Create,
  DeleteButton,
  useRecordContext,
  Button,
} from "react-admin";
import { AdvanceHeader } from "../ra-common/advance";
import { BandieTooltip } from "../ra-common/BandieTip";
import moment from "moment-timezone";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import { downloadFile } from "../ra-common/fetchResource";

export const FormattedCreatedTime = (props) => {
  const record = useRecordContext(props);
  
  return record ? <span>{moment(record.createdAt).format(' D MMMM, h:mm A YYYY')}</span> : null;
}
FormattedCreatedTime.defaultProps = { label: 'Added On' };
export const FileList = ({ permissions, ...props }) => {
  return (
    <List
      {...props}
      title="Files"
      bulkActionButtons={false}
      pagination={null}
      exporter={false}
    >
      <Datagrid pagination={null}>
        <TextField source="title" label="File Name" sortable={false} />
        <FormattedCreatedTime source="createdAt" />
        <DownloadButton />
        <DeleteButton resource={'file'}  redirect={false} />
      </Datagrid>
    </List>
  );
};

const DownloadButton = (props)=>{
  const { record: { id } } = props;
  const bandId = window.location.pathname.split('/')[2]

 return<Button label="Download"  onClick={()=>downloadFile(bandId,id)}  children={<DownloadIcon />}/>
}

export const FileCreate = (props) => {
  return (
  <Create title={"Files"} {...props}>
    <SimpleForm variant="standard">
    <BandieTooltip
          tooltip={
            <>
              <div>
                This page is a list of all of the files that you are storing. They can include: 
                <ul>
                <li>Stage plot</li> 
                <li>Tech Requirement</li> 
                <li>Set lists</li> 
                <li>Riders </li>
                <li>Backline Requests</li> 
                <li>Lighting Document</li> 
                <li>Visuals for screens </li> 
                <li>Hospitality Rider</li> 
                <li>Band Logos</li> 
                <li>MP3s</li>
                </ul>
              </div>
            </>
          }
        >
        <AdvanceHeader>Attachments</AdvanceHeader>
        </BandieTooltip>
      <FileInput
        source="fileUpload"
        label="File (Max 20MB)"
        maxSize={20000000}
      >
        <FileField source="file" title="title" download />
      </FileInput>
    </SimpleForm>
  </Create>)
};
