import { getBearer } from "../lib/auth0";
import {useState, useEffect} from 'react'
import FileSaver from 'file-saver';

export const getFiles = (bandId) => {
  const url = `/api/v1/bands/${bandId}/files`;
  return fetch(url, { headers: { Authorization: getBearer() } }).then(
    (response) => response.json()
  );
};

export const useFiles = (bandId) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    getFiles(bandId)
      .then(setFiles)
      .then(() => setLoading(false));
  }, [bandId]);
  return [files, loading];
};

export const getFileById = (bandId,fileId) =>{
  const url = `/api/v1/bands/${bandId}/files/${fileId}`
  return fetch(url, {method:'GET'}).then(
    (response) => response.json()
  );
}

export const useFile = (bandId, fileId) => {
  const [file, setFile] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    getFileById(bandId,fileId)
      .then(setFile)
      .then(() => setLoading(false));
  }, [fileId,bandId]);
  return [file, loading];
};

export const downloadFile = async (bandId, fileId) => {
  try {
    const file =  await getFileById(bandId, fileId)
    FileSaver.saveAs(file.src, file.title);
  } catch (error) {
    console.error(error);
  }
};


export const getDay = (bandId,dayId) => {
  if (!bandId || !dayId) {
    return Promise.reject("bandId or dayId is null");
  }
  const url = `/api/v1/bands/${bandId}/days/${dayId}`;
  return fetch(url, { headers: { Authorization: getBearer() } }).then(
    (response) => response.json()
  );
};

export const useDay = (bandId,dayId) => {
  const [day, setDay] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    getDay(bandId,dayId)
      .then(setDay)
      .then(() => setLoading(false))
      .catch(err => setLoading(false))
  }, [bandId,dayId]);
  return [day, loading];
};

export const createBasicShow = async (dayId,bandId)=>{
  if(!dayId || !bandId) return null
  const url = `/api/v1/bands/${bandId}/shows`
  const showBody = {dayId: dayId}
  try {
    const res = await fetch(url, { method:"POST",headers: { Authorization: getBearer(),"Content-Type": "application/json"}, body:JSON.stringify(showBody) })
    const data = await res.json()
    console.log('Data from request', data)
    return data
  } catch (error) {
    console.log('error creating show:', error)
    return null
  }
}
