import React from "react";
import { Toolbar, SaveButton, DeleteButton } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
});

const defaultDeleteUserMessage = `Are you sure you want to permanently delete this user? You will not be able to recover this member
or add them to future events.`

export const DeleteUserMemberButton = ({ deleteMessage, ...rest }) => {
  const { name, email, id } = rest.record ?? {}
  const confirmationContent = deleteMessage || defaultDeleteUserMessage;
  const confirmationTitle = `Delete: ${name || email || id || "user"}`;
  return (
    <PessimisticDelete
      {...rest}
      content={confirmationContent}
      title={confirmationTitle}
    />
  );
};

export const DeleteBandButton = (props) => {
  const { name } = props.record
  const confirmationTitle = `Deleting ${name}`;
  const confirmationContent = `Are you sure you want to permanently delete this band? You will not be able to recover this band or its data.`;
  return (
    <PessimisticDelete
      {...props}
      content={confirmationContent}
      title={confirmationTitle}
    />
  );
};

const PessimisticDelete = (props) => {
  const { content, title } = props;
  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton />
      <DeleteButton
        mutationMode="pessimistic"
        confirmContent={content}
        confirmTitle={title}
      />
    </Toolbar>
  );
};
