// Mapping of available "day types"
export const dayTypes = [
  { id: "show", name: "Show Day" },
  { id: "rehearsal", name: "Rehearsal Day" },
  { id: "travel", name: "Travel Day" },
  { id: "songwriting", name: "Songwriting Day" },
  { id: "press", name: "Press/Radio Day" },
  { id: "studio", name: "Studio Day" },
  { id: "conference", name: "Conference/Awards Day" },
  { id: "dayoff", name: "Day Off" },
  { id: "other", name: "Other" },
];

export const eventTypes = [
  { type: "itineraryItem", name: "Misc Itinerary Items" },
  { type: "prodSched", name: "Production Schedule" },
  { type: "meeting", name: "Engagements" },
  { type: "meetAndGreet", name: "Meet and Greet" },
  { type: "transportation", name: "Transportation Bookings" },
  { type: "flight", name: "Flights/Trains" },
  { type: "accommodation", name: "Accommodations" },
  { type: "pressEngagement", name: "Press Engagements" },
];
