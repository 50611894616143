import React from "react";
import { useInput } from "react-admin";

// HOC version of useInput hook
// This is so we can use this hook in our class component below
// https://stackoverflow.com/a/54227055
export function withInput(C) {
  return function WrappedTimeInput(props) {
    // ff stands for FinalForm
    const ff = useInput(props);
    return <C {...props} ff={ff} />;
  };
}
