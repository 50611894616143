import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";

export const BandieTooltip = ({ tooltip, children }) => {
  return (
    <div style={{ display: "flex" }} >
      {children}
      <Tooltip title={tooltip} arrow>
        <ContactSupportIcon style={{ marginTop: 16, marginLeft: 12, color: "#bfe3e0" }} />
      </Tooltip>
    </div>
  );
};

const BandieTip = ({ tooltip, Component, ...rest }) =>
  <BandieTooltip tooltip={tooltip}>
    {Component ? <Component {...rest} /> : null}
  </BandieTooltip>

export default BandieTip