/*
Since the Advance UI is split between the ra-manage and the ra-recip instances,
This module provides utility functions and components that are used by both.
*/

import React from "react";
import { getProp } from "../lib/util";

import { TextField, FormDataConsumer, RichTextField, Button } from "react-admin";
import RichTextInput from "ra-input-rich-text";
import get from "lodash/get";
import { isArray, isEmpty } from "lodash";
import { downloadFile } from "./fetchResource";

// These styles mimic the standard RA form styles
const questionStyle = {
  color: "#777",
  fontSize: "90%",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  whiteSpace: "pre-line",
};

// Title to be used in RA Show or Edit components
export const AdvanceTitle = ({ record }) => {
  return (
    <span>
      <b>{record.bandName}</b> Advance {record.date}
    </span>
  );
};

// https://stackoverflow.com/a/23674838
function _any(iterable) {
  for (var index = 0; index < iterable.length; index++) {
    if (iterable[index]) return true;
  }
  return false;
}

// Main header used to display the user-defined title
export const AdvanceMainHeader = (props) => (
  <h1 style={{ color: "#777" }}>{props.record.data.title}</h1>
);

// This is a subheader to break up a long RA EditForm, ShowLayout, etc
export const AdvanceHeader = (props) => {
  let show, data;
  if (props.show === undefined) {
    // If no "show" prop was given, default to true
    show = true;
  } else {
    // If a "show" prop was given, load that path from the record
    data = getProp(props.record, props.show);
    if (Object.prototype.toString.call(data) === "[object Array]") {
      // If it's an array, then it's true iff it has at least one truthy value
      show = _any(data);
    } else if (Object.prototype.toString.call(data) === "[object Object]") {
      // If it's an object, then it's true iff it has at least one truthy value
      show = _any(Object.values(data));
    } else {
      // otherwise, it's its own truthiness
      show = !!data;
    }
  }

  if (show)
    return (
      <div
        style={{
          color: "#777",
          fontWeight: "bold",
          fontSize: "130%",
          marginTop: 35,
          marginBottom: 35,
        }}
      >
        {props.children}
      </div>
    );

  // else
  return null;
};

// Works like UrlField, but only shows if the value exists
export const CondUrlField = (props) => {
  const url = getProp(props.record, props.source);
  if (!url) return null;
  return (
    <p>
      <a style={infoStyle} rel="noopener noreferrer" target="_blank" href={url}>
        {props.label}
      </a>
    </p>
  );
};


export const FileDownloadLink = (props) => {
  const {attachments} = props.record.data_v2
  const {label, source} = props
  const {bandID} = props.record
  const key = source.split('.').slice(-1)
  const url = getProp(props.record, props.source);
  if (!url) return null;

 return (
     <div>
      <p style={infoStyle} rel="noopener noreferrer" target="_blank" href="#">
        {label}
      </p>
        {attachments[key].map(file=>{
          return (
            <div key={file.id}> 
            <Button onClick={()=>downloadFile(bandID,file.id)}>
              <>{file.title}</>
            </Button>
            </div>
          )
        })}
     </div>
 );
};

const FileAnchor = ({ label, title, src }) => {
  return (
    <p>
      <a style={infoStyle} rel="noopener noreferrer" target="_blank" href={src} download={title} >
        {label} {title}
      </a>
    </p>
  );
};

export const CondFileField = (props) => {
  const val = get(props.record, props.source) ?? {};
  if (isEmpty(val)) return null;
  if (isArray(val)) {
    return <>
      <p>{props.label}</p>
      <div style={{ paddingLeft: 20 }}>
        {val.map((file, i) => <FileAnchor src={file.src} title={file.title} key={file.title} />)}
      </div>
    </>
  }
  return <FileAnchor label={props.label} src={val.src} title={val.title} />;
};

const infoStyle = {
  color: "#777",
  fontSize: "90%",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  whiteSpace: "pre-line",
};

export const RAConditional = ({ children, ...props }) => {
  if (!get(props.record, props.source)) return null;
  return children(props);
};

// Same as TextField, but conditional
// and add the label in the same colour/format as other Q/A pairs
export const CondTextField = (props) => {
  const Field = props.richText ? RichTextField : TextField;

  if (getProp(props.record, props.source)) {
    return (
      <p>
        <span style={infoStyle}>{props.label}</span>
        <br />
        <Field {...props} />
      </p>
    );
  }
  return null;
};

// Renders the message from the band to the recipient
export const AdvanceInfoSection = (props) => (
  <div>
    <p
      style={infoStyle}
      dangerouslySetInnerHTML={{ __html: props.record.data.info.numInParty }}
    ></p>
    <p
      style={infoStyle}
      dangerouslySetInnerHTML={{
        __html: props.record.data.info.specialRequests,
      }}
    ></p>
    {props.record.data.info.taxInformation ? (
      <span>
        Tax Information:
        <span
          style={infoStyle}
          dangerouslySetInnerHTML={{
            __html: props.record.data.info.taxInformation,
          }}
        ></span>
      </span>
    ) : null}

    <p
      style={infoStyle}
      dangerouslySetInnerHTML={{ __html: props.record.data.info.signOff }}
    ></p>
  </div>
);
export const AdvanceInfoSectionV2 = (props) => (
  <div>
    <p
      style={infoStyle}
      dangerouslySetInnerHTML={{ __html: props.record.data_v2.info.numInParty }}
    ></p>
    <p
      style={infoStyle}
      dangerouslySetInnerHTML={{
        __html: props.record.data_v2.info.specialRequests,
      }}
    ></p>
    {props.record.data.info.taxInformation ? (
      <span>
        Tax Information:
        <span
          style={infoStyle}
          dangerouslySetInnerHTML={{
            __html: props.record.data_v2.info.taxInformation,
          }}
        ></span>
      </span>
    ) : null}

    <p
      style={infoStyle}
      dangerouslySetInnerHTML={{ __html: props.record.data_v2.info.signOff }}
    ></p>
  </div>
);

// Shows a question and answer, only if the question is enabled
export const AdvanceAnswer = ({ show, label, ...props }) => {
  const { record } = props;
  if (!getProp(record, show, false)) return null;
  // The label parameter will also accept a function in the form: (record) => label
  const labelVal = typeof label === "function" ? label(record) : label;
  return (
    <div>
      <p style={questionStyle}>{labelVal}</p>
      <RichTextField {...props} label={labelVal} />
    </div>
  );
};

// Generates multiple <AdvanceAnswer>s for user defined questions at the given path
// ex: contacts.addlQuestions
//     addlQuestions
export const AddlQuestionsAnswers = ({ path, record}) => {

  // gather the data version number in order to decide how to access the properties nested as data.additionalQuestions or data_v2.additionalQuestions etc
  const dataVersionPath = record?.version === 'v_2' ? 'data_v2.' : 'data.' 
  const questions = getProp(record, dataVersionPath + path);
  if (!questions) return null;

  return questions.map((q, i) => (
    <AdvanceAnswer
      show={dataVersionPath + path + "." + i + ".question"}
      source={"response." + path + "." + i + ".answer"}
      label={q.question}
      key={i}
      record={record}
    />
  ));
};

// Generates multiple <CondLongTextInput>s (as above)
export const AddlQuestionsRecip = ({ path, ...rest }) => (
  <FormDataConsumer {...rest}>
    {({ formData, ...rest2 }) => {
      const dataVersionPath = formData?.version === 'v_2' ? 'data_v2.' : 'data.' 
      const questions = getProp(formData, dataVersionPath + path);
      if (!questions) return null;

      return questions.map((q, i) => (
        <CondLongTextInput
          source={"response." + path + "." + i + ".answer"}
          label={q.question}
          key={i}
          {...rest2}
        />
      ));
    }}
  </FormDataConsumer>
);

const labelStyles = {
  marginTop: 30,
  marginBottom: 0,
  color: "#777",
  fontSize: "90%",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
};
const longTextInputStyles = {
  width: "98%",
  marginLeft: 15,
  marginTop: 0,
};

// Renders if the property referenced by `show` exists.
// If no `show` path is given, then it defaults to showing.
export const CondLongTextInput = (props) => {
  const { show, label, ...rest } = props;
  if (!show || getProp(props.record, show, false))
    return (
      <React.Fragment>
        <p style={labelStyles}>{label}</p>
        <RichTextInput
          toolbar={[["bold", "italic", "underline", "link"]]}
          multiline
          style={longTextInputStyles}
          label=""
          {...rest}
        />
      </React.Fragment>
    );
  return null;
};

// Chooses the default accomm question based on the day's providedByVenue values
export function getDefaultAccommQuestion(day) {
  const checked = getProp(day, "data.accommodations.providedByVenue.checked");
  const notes = getProp(day, "data.accommodations.providedByVenue.notes");

  if (checked === true && notes) return "provided";
  if (checked === false) return "notProvided";
  return "unknown";
}
const accommQuestions = [
  "Invalid question index",
  "I see that you are providing accommodations as described above. Can you please send me the name and address of the hotel, and the confirmation numbers for our rooms?",
  "Are you providing accommodations? If so, can you please send me the name and address of the hotel, and the confirmation numbers for our rooms?",
  "I see that you are not providing accommodations. If you have any partnerships or discounts with accommodations nearby, or any accommodations that you can recommend, please include them here.",
];
export function getAccommQuestionText(key) {
  if (key === "provided") return accommQuestions[1];
  if (key === "notProvided") return accommQuestions[2];
  if (key === "unknown") return accommQuestions[3];
  return accommQuestions[0];
}

// Chooses the default meetAndGreet question based on the day's meetAndGreet.required value
export function getDefaultMeetAndGreetQuestion(day) {
  const checked = getProp(day, "data.accommodations.providedByVenue.checked");

  if (checked === true) return "required";
  if (checked === false) return "";
  return "unknown";
}
const meetAndGreetQuestions = [
  "Invalid question index",
  "It states in the contract that we will be participating in a meet and greet if required. Can you please let us know if this is in fact required, and if so, please confirm details: timing, location, if there will be a Meet & Greet escort, if a photographer will be present, etc.",
  "Is a meet and greet required? If so, please confirm details: timing, location, if there will be a Meet & Greet escort, if a photographer will be present, etc.",
];
export function getMeetAndGreetQuestionText(key) {
  if (key === "required") return meetAndGreetQuestions[1];
  if (key === "unknown") return meetAndGreetQuestions[2];
  return meetAndGreetQuestions[0];
}
