import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_ERROR,
  AUTH_GET_PERMISSIONS,
} from "react-admin";
import { logout, SCOPE, hasScope } from "../lib/auth0.js";

export default (type, params) => {
  // Login is handled outside of react-admin so we don't do anything here
  if (type === AUTH_LOGIN) {
    return Promise.resolve();
  }

  // AUTH_LOGOUT happens when the user selects the logout menu option.
  // We call our own logout() which in turn redirects via Auth0
  if (type === AUTH_LOGOUT) {
    logout();
    return Promise.resolve();
  }

  // AUTH_ERROR is called by the dataProvider when the API rejects our request
  // We intercept api auth problems so we can logout() to trigger a new login session
  if (type === AUTH_ERROR) {
    const status = params.status;
    if (status === 401) {
      logout();
      return Promise.reject();
    }
    return Promise.resolve();
  }

  // react-admin uses the concept of a "role" instead of a collection of scopes.
  // Currently we distinguish between "admin" and not. If they are not admin,
  // they are assumed to be a manager of something.
  // FIXME: If they are not a manager, they will still be able to log in,
  // but will not see any bands or be able to make any AJAX API requests.
  if (type === AUTH_GET_PERMISSIONS) {
    if (hasScope(SCOPE.ADMIN)) {
      return Promise.resolve("admin");
    }
  }

  return Promise.resolve();
};
