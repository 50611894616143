import React, { useEffect, useState } from "react";
import { goToCustomerPortal } from "../components/subscription";
import { updateUserinfo } from "../lib/auth0";

export const AccountPage = () => {
  const [userInfo, setUserInfo] = useState({})
  useEffect(() => {
    updateUserinfo().then(setUserInfo)
  }, []);

  return (
    <div
      style={{
        display: "grid",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1>Account: {userInfo.email}</h1>
      <h3>Payment</h3>

      <div
        onClick={goToCustomerPortal}
        style={{
          backgroundColor: "#d2eaef",
          borderRadius: 8,
          margin: 2,
          cursor: "pointer",
          flexDirection: "column",
          display: "flex",
          flex: 1,
          padding: 10,
        }}
      >
        <div style={{ color: "#2d4044", fontSize: 16, fontWeight: "bold" }}>
          Manage Subscriptions with Stripe's Customer Portal
        </div>
      </div>
      <div>
        <h3>List of associated bands</h3>
        {userInfo.members?.map((member) => {
          return (
            <div
              key={member.bandID}
              style={{ padding: 5, fontSize: 16 }}
            >
              {" · "}
              {member.bandName}
            </div>
          );
        })}
      </div>
    </div>
  );
};
