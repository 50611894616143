import { useEffect, useState } from "react";
import { getBearer } from "./auth0";
import moment from "moment-timezone";

export const bandHasSubscription = (band) => {
  return moment().isBefore(moment.unix(band.subscriptionExpiryTimestamp))
}

export const getBand = bandId => {
  return fetch(`/api/v1/bands/${bandId}`, {
    headers: { Authorization: getBearer() },
  })
    .then((response) => response.json())
}

export const useBand = (bandId) => {
  const [band, setBand] = useState({});
  useEffect(() => {
    if (bandId) getBand(bandId).then(setBand);
  }, [bandId]);
  return band;
};