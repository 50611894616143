import React from "react";
import {
  SimpleForm,
  TextInput,
  BooleanInput,
  FileInput,
  FileField,
  ArrayInput,
  RadioButtonGroupInput,
  SimpleShowLayout,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
import { getProp } from "../lib/util";
import {
  AdvanceHeader,
  getDefaultAccommQuestion,
  getAccommQuestionText,
  getDefaultMeetAndGreetQuestion,
  AdvanceMainHeader,
  CondUrlField,
  CondTextField,
  AdvanceInfoSection,
  AdvanceAnswer,
  getMeetAndGreetQuestionText,
  AddlQuestionsAnswers,
  CondFileField,
} from "../ra-common/advance";
import BandieTip from "../ra-common/BandieTip";
import { BandieTooltip } from "../ra-common/BandieTip";
import { SimpleFormIterator } from "../ra-common/SimpleFormIterator";

export const AdvanceShowV1 = (props) => {
  return (
    <SimpleShowLayout {...props}>
      <AdvanceMainHeader />
      <AdvanceHeader>General Show Questions</AdvanceHeader>

      <AdvanceAnswer
        show="data.general.venueCapacity"
        source="response.general.venueCapacity"
        label="What is the venue capacity?"
      />
      <AdvanceAnswer
        show="data.general.otherActs"
        source="response.general.otherActs"
        label="Are there any other acts performing?"
      />
      <AdvanceAnswer
        show="data.general.allAges"
        source="response.general.allAges"
        label="Is the show all ages?"
      />

      <AdvanceHeader>Transportation</AdvanceHeader>

      <AdvanceAnswer
        show="data.transportation.shuttle"
        source="response.transportation.shuttle"
        label="Is a shuttle or transport provided by the venue? If so, please provide details."
      />

      <AdvanceHeader>Transportation - Additional Questions</AdvanceHeader>
      <AddlQuestionsAnswers path="transportation.addlQuestions" />

      <AdvanceHeader>Accommodations</AdvanceHeader>

      <CondTextField
        source="data.accommodations.details"
        label="Existing Details"
        richText={true}
      />

      <AdvanceAnswer
        show="data.accommodations.question"
        source="response.accommodations.response"
        label={(r) =>
          getAccommQuestionText(getProp(r, "data.accommodations.question"))
        }
      />

      <AdvanceHeader>Contacts</AdvanceHeader>
      <AdvanceAnswer
        show="data.contacts.dayOfShow"
        source="response.contacts.dayOfShow"
        label="Who is the day of show contact?"
      />
      <AdvanceAnswer
        show="data.contacts.production"
        source="response.contacts.production"
        label="Who is the production contact?"
      />
      <AdvanceAnswer
        show="data.contacts.sound"
        source="response.contacts.sound"
        label="Who is the sound contact?"
      />
      <AdvanceAnswer
        show="data.contacts.lighting"
        source="response.contacts.lighting"
        label="Who is the lighting contact?"
      />
      <AdvanceAnswer
        show="data.contacts.backline"
        source="response.contacts.backline"
        label="Who is the backline contact?"
      />
      <AdvanceAnswer
        show="data.contacts.hospitality"
        source="response.contacts.hospitality"
        label="Who is the hospitality contact?"
      />
      <AdvanceAnswer
        show="data.contacts.transport"
        source="response.contacts.transport"
        label="Who is the transport contact?"
      />
      <AdvanceAnswer
        show="data.contacts.merchandise"
        source="response.contacts.merchandise"
        label="Who is the merchandise contact?"
      />
      <AdvanceAnswer
        show="data.contacts.marketing"
        source="response.contacts.marketing"
        label="Who is the marketing/social media contact?"
      />
      <AdvanceAnswer
        show="data.contacts.payment"
        source="response.contacts.payment"
        label="Who do we collect payment from after the show?"
      />

      <AdvanceHeader>Contacts - Additional Questions</AdvanceHeader>
      <AddlQuestionsAnswers path="contacts.addlQuestions" />

      <AdvanceHeader>Timings</AdvanceHeader>
      <AdvanceAnswer
        show="data.timings.loadIn"
        source="response.timings.loadIn"
        label="What is our load in time?"
      />
      <AdvanceAnswer
        show="data.timings.soundcheck"
        source="response.timings.soundcheck"
        label="What is our soundcheck time?"
      />
      <AdvanceAnswer
        show="data.timings.darkStage"
        source="response.timings.darkStage"
        label="Are there any dark stage times that must be observed?"
      />
      <AdvanceAnswer
        show="data.timings.doors"
        source="response.timings.doors"
        label="What is doors time?"
      />
      <AdvanceAnswer
        show="data.timings.setLength"
        source="response.timings.setLength"
        label="What is our set length?"
      />
      <AdvanceAnswer
        show="data.timings.setStart"
        source="response.timings.setStart"
        label="What is our set start time?"
      />
      <AdvanceAnswer
        show="data.timings.otherBands"
        source="response.timings.otherBands"
        label="Are there any other band set times that we need to be aware of?"
      />
      <AdvanceAnswer
        show="data.timings.showCurfew"
        source="response.timings.showCurfew"
        label="Is there a show curfew? (ie. time the show must end due to noise bylaws, scheduling, etc.?)"
      />
      <AdvanceAnswer
        show="data.timings.venueCurfew"
        source="response.timings.venueCurfew"
        label="Is there a venue curfew? (ie. do we need to be completed load-out at a certain time?)"
      />

      <AdvanceHeader>Load-in / Parking</AdvanceHeader>
      <AdvanceAnswer
        show="data.loadIn.instructions"
        source="response.loadIn.instructions"
        label="Are there any specific load-in instructions (location of load-in, etc.)?"
      />
      <AdvanceAnswer
        show="data.loadIn.tempParking"
        source="response.loadIn.tempParking"
        label="Is there specific parking available for load-in?"
      />
      <AdvanceAnswer
        show="data.loadIn.permParking"
        source="response.loadIn.permParking"
        label="Where do we park after load-in?"
      />

      <AdvanceHeader>Production / Stage</AdvanceHeader>

      <AdvanceAnswer
        show="data.production.backlineConfirmed"
        source="response.production.backlineConfirmed"
        label="I have attached our backline list. Can you confirm our backline needs will be met?"
      />
      <AdvanceAnswer
        show="data.production.fohConsole"
        source="response.production.fohConsole"
        label="What is the FOH console?"
      />
      <AdvanceAnswer
        show="data.production.stageSize"
        source="response.production.stageSize"
        label="What are the stage dimensions?"
      />
      <AdvanceAnswer
        show="data.production.projector"
        source="response.production.projector"
        label="Are there screens? If so, do you need us to provide visuals?"
      />
      <AdvanceAnswer
        show="data.production.banner"
        source="response.production.banner"
        label="Can we put stage signage/banners onstage during our set?"
      />

      <AdvanceHeader>Production / Stage - Additional Questions</AdvanceHeader>
      <AddlQuestionsAnswers path="production.addlQuestions" />

      <CondTextField
        source="data.production.monitorDetails"
        label="Monitor Details"
      />
      <AdvanceAnswer
        show="data.production.monitorDetails"
        source="response.production.monitorDetails"
        label="Response to monitor details."
      />
      <CondTextField
        source="data.production.iemFrequencies"
        label="IEM Frequencies"
      />
      <AdvanceAnswer
        show="data.production.iemFrequencies"
        source="response.production.iemFrequencies"
        label="Response to IEM Frequencies"
      />

      <AdvanceHeader>Hospitality</AdvanceHeader>

      <AdvanceAnswer
        show="data.hospitality.greenRoom"
        source="response.hospitality.greenRoom"
        label="Is there a green room provided for the band?"
      />

      <AdvanceAnswer
        show="data.hospitality.privateBathrooms"
        source="response.hospitality.privateBathrooms"
        label="Are there private bathroom facilities available for the band?"
      />

      <AdvanceAnswer
        show="data.hospitality.showers"
        source="response.hospitality.showers"
        label="Are shower facilities available at the venue?"
      />

      <AdvanceAnswer
        show="data.hospitality.info"
        source="response.hospitality.info"
        label="I have attached our rider to this email. Please let us know what you will be providing for hospitality!"
      />

      <AdvanceAnswer
        show="data.hospitality.meals"
        source="response.hospitality.meals"
        label="Are any meals being provided? If so, please provide details, meal timings, etc."
      />

      <AdvanceAnswer
        show="data.hospitality.mealBuyout"
        source="response.hospitality.mealBuyout"
        label="Is there a meal buyout for band and crew for the show? Please specify."
      />

      <AdvanceAnswer
        show="data.hospitality.wifi"
        source="response.hospitality.wifi"
        label="Is wifi available at the venue? If so, please provide network name and password."
      />

      <AdvanceHeader>Merchandise</AdvanceHeader>

      <AdvanceAnswer
        show="data.merchandise.sellerProvided"
        source="response.merchandise.sellerProvided"
        label="Is a Merch Seller provided?"
      />

      <AdvanceAnswer
        show="data.merchandise.split"
        source="response.merchandise.split"
        label="What is the merch split?"
      />

      <AdvanceAnswer
        show="data.merchandise.limit"
        source="response.merchandise.limit"
        label="Is there a limit to the number of items that we can sell?"
      />

      <AdvanceAnswer
        show="data.merchandise.priceMatch"
        source="response.merchandise.priceMatch"
        label="Will we need to price match with the headlining artist?"
      />

      <AdvanceAnswer
        show="data.merchandise.racksProvided"
        source="response.merchandise.racksProvided"
        label="Are merch racks provided by the venue?"
      />

      <AdvanceAnswer
        show="data.merchandise.location"
        source="response.merchandise.location"
        label="Where in the venue will the merch area be located?"
      />

      <AdvanceHeader>Marketing</AdvanceHeader>

      <AdvanceAnswer
        show="data.marketing.announceDate"
        source="response.marketing.announceDate"
        label="What is the announce date for the show?"
      />

      <AdvanceAnswer
        show="data.marketing.ticketPrice"
        source="response.marketing.ticketPrice"
        label="What is the ticket price?"
      />

      <AdvanceAnswer
        show="data.marketing.onSaleDate"
        source="response.marketing.onSaleDate"
        label="When do tickets go onsale?"
      />

      <AdvanceAnswer
        show="data.marketing.ticketOutlet"
        source="response.marketing.ticketOutlet"
        label="What are the ticket outlets?"
      />

      <AdvanceAnswer
        show="data.marketing.sponsors"
        source="response.marketing.sponsors"
        label="Are there any event sponsors/presenting radio stations, etc. that we should be aware of?"
      />

      <AdvanceAnswer
        show="data.marketing.socialMedia"
        source="response.marketing.socialMedia"
        label="Can you please send me all the social media links/handles/hashtags for the venue/event?"
      />

      <AdvanceAnswer
        show="data.marketing.plan"
        source="response.marketing.plan"
        label="What is your marketing/promotional plan for the event?"
      />

      <AdvanceAnswer
        show="data.marketing.requests"
        source="response.marketing.requests"
        label="If there is anything you would like us to do in terms of press/online/social media promotion on our end, please let us know here."
      />

      <AdvanceAnswer
        show="data.marketing.meetAndGreet"
        source="response.marketing.meetAndGreet"
        label={(r) =>
          getMeetAndGreetQuestionText(getProp(r, "data.marketing.meetAndGreet"))
        }
      />

      <AdvanceHeader>Press Pass</AdvanceHeader>

      <AdvanceAnswer
        show="data.pressPasses.contact"
        source="response.pressPasses.contact"
        label="If we receive press pass requests, who should we talk to in order to have those requests authorized?"
      />

      <AdvanceAnswer
        show="data.pressPasses.instructions"
        source="response.pressPasses.instructions"
        label="Are there any specific press pass instructions? (how to pick up pass, photography restrictions, etc.)"
      />

      <AdvanceAnswer
        show="data.pressPasses.submitDeadline"
        source="response.pressPasses.submitDeadline"
        label="When do you need press pass requests submitted by?"
      />

      <AdvanceHeader>Press Pass - Additional Questions</AdvanceHeader>
      <AddlQuestionsAnswers path="pressPasses.addlQuestions" />

      <AdvanceHeader>Guest List</AdvanceHeader>

      <AdvanceAnswer
        show="data.guestList.numberOfSpots"
        source="response.guestList.numberOfSpots"
        label="Do we get any guest list spots for the show? If so, how many?"
      />

      <AdvanceAnswer
        show="data.guestList.instructions"
        source="response.guestList.instructions"
        label="Are there any specific guest list instructions? (how to pick up tickets, etc.)"
      />

      <AdvanceAnswer
        show="data.guestList.submitDeadline"
        source="response.guestList.submitDeadline"
        label="When do you need us to submit our guest list by? Who do we submit them to?"
      />

      <AdvanceHeader>Additional Questions</AdvanceHeader>
      <AddlQuestionsAnswers path="addlQuestions" />

      <AdvanceHeader>Attachments</AdvanceHeader>
      <CondUrlField
        source="data.attachments.techRequirementsUrl"
        label="Stage Plot & Tech Requirements (PDF)"
      />
      <CondUrlField
        source="data.attachments.backlineRequestsUrl"
        label="Backline Requests (PDF)"
      />
      <CondUrlField
        source="data.attachments.lightingUrl"
        label="Lighting Document (PDF)"
      />
      <CondUrlField
        source="data.attachments.visualsUrl"
        label="Visuals for Screens (download)"
      />
      <CondUrlField
        source="data.attachments.riderUrl"
        label="Hospitality Rider (PDF)"
      />
      <CondFileField
        source="data.attachments.longBioUrl"
        label="Long Bio (PDF)"
      />
      <CondFileField
        source="data.attachments.shortBioUrl"
        label="Short Bio (PDF)"
      />
      <CondFileField
        source="data.attachments.pressPhotosUrl"
        label="Press Photos (Download)"
      />
      <CondTextField
        source="data.attachments.socialLinks"
        label="Band Social Media Links"
      />
      <CondUrlField
        source="data.attachments.bandLogosUrl"
        label="Band Logos (Download)"
      />
      <CondUrlField source="data.attachments.mp3sUrl" label="MP3s (Download)" />
      <CondUrlField
        source="data.attachments.otherUrl"
        label="Other (Download)"
      />

      <AdvanceHeader>Band Contacts</AdvanceHeader>
      <CondTextField
        source="data.bandContacts.dayOfShowContact"
        label="Day of Show Contact"
      />
      <CondTextField
        source="data.bandContacts.merchContact"
        label="Merch Contact"
      />
      <CondTextField
        source="data.bandContacts.socialMediaContact"
        label="Social Media Contact"
      />
      <CondTextField
        source="data.bandContacts.soundTechContact"
        label="Sound Tech Contact"
      />
      <CondTextField
        source="data.bandContacts.managerContact"
        label="Manager Contact"
      />
      <CondTextField
        source="data.bandContacts.agentContact"
        label="Agent Contact"
      />
      <CondTextField
        source="data.bandContacts.publicistContact"
        label="Publicist Contact"
      />

      <AdvanceHeader>Additional Information</AdvanceHeader>
      <AdvanceInfoSection />

      <CondFileField source="response.attachments" label="Attachments" />
    </SimpleShowLayout>
  );
};

export const AdvanceFormV1 = ({
  band,
  dayHasVal,
  day,
  generateTotalNumInParty,
  dataProvider: _,
  ...rest
}) => {
  return (
    <SimpleForm {...rest} variant="standard">

      <BandieTip
        tooltip="You can create multiple advances for the same day, to allow you to send the right questions and right information to the right person! Remember to title your advances appropriately (ie. General Advance, Tech Advance, etc.) so it is easy to keep track of each advance."
        Component={TextInput}
        source="data.title"
        label="Advance Title"
        defaultValue="General Advance"
      />
      <p>
        Toggle the selector by a question to include it on the advance for the
        promoter.
      </p>
      <BandieTooltip tooltip={"General questions about the show."}>
        <AdvanceHeader>General Show Questions</AdvanceHeader>
      </BandieTooltip>
      <BooleanInput
        source="data.general.venueCapacity"
        label="What is the venue capacity?"
        defaultValue={!dayHasVal("data.show.venueCapacity")}
      />
      <BooleanInput
        source="data.general.otherActs"
        label="Are there any other acts performing?"
        defaultValue={
          !dayHasVal("data.show.headliner") || !dayHasVal("data.show.openers")
        }
      />
      <BooleanInput
        source="data.general.allAges"
        label="Is the show all ages?"
        defaultValue={!dayHasVal("data.show.age")}
      />
      <BandieTooltip
        tooltip={"Questions around transportation details related to the show."}
      >
        <AdvanceHeader>Transportation</AdvanceHeader>
      </BandieTooltip>
      <BooleanInput
        source="data.transportation.shuttle"
        label="Is a shuttle or transport provided by the venue? If so, please provide details."
        defaultValue={
          // False iff either:
          // * provided === false
          // * provided === true, and booked, and details
          !(
            getProp(day, "data.transportation.provided.checked") === false ||
            (getProp(day, "data.transportation.booked.checked") &&
              getProp(day, "data.transportation.provided.checked") &&
              dayHasVal("data.transportation.provided.notes"))
          )
        }
      />
      <BandieTip
        tooltip="Have specific transportation questions not covered by the auto-questionnaire? Add them here!"
        Component={AdvanceHeader}
      >
        Transportation - Additional Questions
      </BandieTip>
      <ArrayInput source="data.transportation.addlQuestions" label="">
        <SimpleFormIterator>
          <TextInput multiline source="question" label="Question" fullWidth />
        </SimpleFormIterator>
      </ArrayInput>
      <BandieTooltip
        tooltip={
          "Ask questions about the accommodations provided by the promoter."
        }
      >
        <AdvanceHeader>Accommodations</AdvanceHeader>
      </BandieTooltip>
      <RichTextInput
        toolbar={[["bold", "italic", "underline", "link"]]}
        source="data.accommodations.details"
        label="Existing Details"
        defaultValue={getProp(day, "data.accommodations.providedByVenue.notes")}
      />
      <BandieTooltip
        tooltip={
          "Select 'omit this question' to remove any questions about accommodations."
        }
      >
        <RadioButtonGroupInput
          source="data.accommodations.question"
          choices={[
            { id: "", name: "[omit this question]" },
            { id: "provided", name: getAccommQuestionText("provided") },
            { id: "unknown", name: getAccommQuestionText("unknown") },
            { id: "notProvided", name: getAccommQuestionText("notProvided") },
          ]}
          defaultValue={getDefaultAccommQuestion(day)}
          label=""
          fullWidth
        />
      </BandieTooltip>
      <BandieTooltip
        tooltip={
          "Gather information about the people you may need to contact on the day of the show."
        }
      >
        <AdvanceHeader>Contacts</AdvanceHeader>
      </BandieTooltip>
      <BooleanInput
        source="data.contacts.dayOfShow"
        label="Who is the day of show contact?"
        defaultValue={true}
      />
      <BooleanInput
        source="data.contacts.production"
        label="Who is the production contact?"
        defaultValue={true}
      />
      <BooleanInput
        source="data.contacts.sound"
        label="Who is the sound contact?"
        defaultValue={true}
      />
      <BooleanInput
        source="data.contacts.lighting"
        label="Who is the lighting contact?"
        defaultValue={true}
      />
      <BooleanInput
        source="data.contacts.backline"
        label="Who is the backline contact?"
        defaultValue={true}
      />
      <BooleanInput
        source="data.contacts.hospitality"
        label="Who is the hospitality contact?"
        defaultValue={true}
      />
      <BooleanInput
        source="data.contacts.transport"
        label="Who is the transport contact?"
        defaultValue={true}
      />
      <BooleanInput
        source="data.contacts.merchandise"
        label="Who is the merchandise contact?"
        defaultValue={true}
      />
      <BooleanInput
        source="data.contacts.marketing"
        label="Who is the marketing/social media contact?"
        defaultValue={true}
      />
      <BooleanInput
        source="data.contacts.payment"
        label="Who do we collect payment from after the show?"
        defaultValue={true}
      />
      <BandieTip
        tooltip="Need to get information for contacts not covered by the auto-questionnaire? Add them here!"
        Component={AdvanceHeader}
      >
        Contacts - Additional Questions
      </BandieTip>
      <ArrayInput source="data.contacts.addlQuestions" label="">
        <SimpleFormIterator>
          <TextInput multiline source="question" label="Question" fullWidth />
        </SimpleFormIterator>
      </ArrayInput>
      <BandieTooltip
        tooltip={"Questions around the days schedule and time frames."}
      >
        <AdvanceHeader>Timings</AdvanceHeader>
      </BandieTooltip>
      <BooleanInput
        source="data.timings.loadIn"
        label="What is our load in time?"
        defaultValue={true}
      />
      <BooleanInput
        source="data.timings.soundcheck"
        label="What is our soundcheck time?"
        defaultValue={true}
      />
      <BooleanInput
        source="data.timings.darkStage"
        label="Are there any dark stage times that must be observed?"
        defaultValue={true}
      />
      <BooleanInput
        source="data.timings.doors"
        label="What is doors time?"
        defaultValue={true}
      />
      <BooleanInput
        source="data.timings.setLength"
        label="What is our set length?"
        defaultValue={true}
      />
      <BooleanInput
        source="data.timings.setStart"
        label="What is our set start time?"
        defaultValue={true}
      />
      <BooleanInput
        source="data.timings.otherBands"
        label="Are there any other band set times that we need to be aware of?"
        defaultValue={true}
      />
      <BooleanInput
        source="data.timings.showCurfew"
        label="Is there a show curfew? (ie. time the show must end due to noise bylaws, scheduling, etc.?)"
        defaultValue={true}
      />
      <BooleanInput
        source="data.timings.venueCurfew"
        label="Is there a venue curfew? (ie. do we need to be completed load-out at a certain time?)"
        defaultValue={true}
      />
      <BandieTooltip
        tooltip={
          "Ask for information about the parking and load-in details for the show."
        }
      >
        <AdvanceHeader>Load-in / Parking</AdvanceHeader>
      </BandieTooltip>
      <BooleanInput
        source="data.loadIn.instructions"
        label="Are there any specific load-in instructions (location of load-in, etc.)?"
        defaultValue={true}
      />
      <BooleanInput
        source="data.loadIn.tempParking"
        label="Is there specific parking available for load-in?"
        defaultValue={true}
      />
      <BooleanInput
        source="data.loadIn.permParking"
        label="Where do we park after load-in?"
        defaultValue={true}
      />

      <BandieTooltip
        tooltip={"Questions around the show production and details."}
      >
        <AdvanceHeader>Production / Stage</AdvanceHeader>
      </BandieTooltip>

      <BooleanInput
        source="data.production.backlineConfirmed"
        label="I have attached our backline list. Can you confirm our backline needs will be met?"
        defaultValue={getProp(
          day,
          "data.production.backlineProvided.checked",
          false
        )}
      />
      <BooleanInput
        source="data.production.fohConsole"
        label="What is the FOH console?"
        defaultValue={
          // True iff either:
          // * checked === false or undefined
          // * checked === true, but details are empty
          getProp(day, "data.production.fohConsole.checked", false) === false ||
          (getProp(day, "data.production.fohConsole.checked") &&
            !dayHasVal("data.production.fohConsole.notes"))
        }
      />
      <BooleanInput
        source="data.production.stageSize"
        label="What are the stage dimensions?"
        defaultValue={!dayHasVal("data.production.stageSize")}
      />
      <BooleanInput
        source="data.production.projector"
        label="Are there screens? If so, do you need us to provide visuals?"
        defaultValue={!dayHasVal("data.production.visuals")}
      />
      <BooleanInput
        source="data.production.banner"
        label="Can we put stage signage/banners onstage during our set?"
        defaultValue={!dayHasVal("data.production.banner")}
      />

      <BandieTip
        tooltip="Need to get information for contacts not covered by the auto-questionnaire? Add them here!"
        Component={AdvanceHeader}
      >
        Production / Stage - Additional Questions
      </BandieTip>

      <ArrayInput source="data.production.addlQuestions" label="">
        <SimpleFormIterator>
          <TextInput multiline source="question" label="Question" fullWidth />
        </SimpleFormIterator>
      </ArrayInput>
      <TextInput
        multiline
        source="data.production.monitorDetails"
        label="Monitor Details"
        defaultValue={band?.production?.monitorDetails}
      />
      <TextInput
        multiline
        source="data.production.iemFrequencies"
        label="IEM Frequencies"
        defaultValue={band?.production?.iemFrequencies}
      />

      <BandieTooltip tooltip={"Questions around provided hotels/hospitality."}>
        <AdvanceHeader>Hospitality</AdvanceHeader>
      </BandieTooltip>

      <BooleanInput
        source="data.hospitality.greenRoom"
        label="Is there a green room provided for the band?"
        defaultValue={!dayHasVal("data.hospitality.greenRoom")}
      />
      <BooleanInput
        source="data.hospitality.privateBathrooms"
        label="Are there private bathroom facilities available for the band?"
        defaultValue={!dayHasVal("data.hospitality.privateBathrooms")}
      />
      <BooleanInput
        source="data.hospitality.showers"
        label="Are shower facilities available at the venue?"
        defaultValue={!dayHasVal("data.hospitality.showers")}
      />
      <BooleanInput
        source="data.hospitality.info"
        label="I have attached our rider to this email. Please let us know what you will be providing for hospitality!"
        defaultValue={true}
      />
      <BooleanInput
        source="data.hospitality.meals"
        label="Are any meals being provided? If so, please provide details, meal timings, etc."
        defaultValue={!dayHasVal("data.hospitality.meals")}
      />
      <BooleanInput
        source="data.hospitality.mealBuyout"
        label="Is there a meal buyout for band and crew for the show? Please specify."
        defaultValue={!dayHasVal("data.hospitality.mealBuyout")}
      />
      <BooleanInput
        source="data.hospitality.wifi"
        label="Is wifi available at the venue? If so, please provide network name and password."
        defaultValue={!dayHasVal("data.hospitality.wifiNetwork")}
      />

      <BandieTooltip tooltip={"Merchandising questions."}>
        <AdvanceHeader>Merchandise</AdvanceHeader>
      </BandieTooltip>

      <BooleanInput
        source="data.merchandise.sellerProvided"
        label="Is a Merch Seller provided?"
        defaultValue={!dayHasVal("data.merchandise.sellerProvided")}
      />
      <BooleanInput
        source="data.merchandise.split"
        label="What is the merch split?"
        defaultValue={
          !(
            // False if either:
            // * provided === false
            // * provided === true, but details are empty
            (
              getProp(day, "data.merchandise.split.checked") === false ||
              (getProp(day, "data.merchandise.split.checked") &&
                !dayHasVal("data.merchandise.split.notes"))
            )
          )
        }
      />
      <BooleanInput
        source="data.merchandise.limit"
        label="Is there a limit to the number of items that we can sell?"
        defaultValue={
          !(
            // False iff either:
            // * provided === false
            // * provided === true, but details are empty
            (
              getProp(day, "data.merchandise.limit.checked") === false ||
              (getProp(day, "data.merchandise.limit.checked") &&
                !dayHasVal("data.merchandise.limit.notes"))
            )
          )
        }
      />
      <BooleanInput
        source="data.merchandise.priceMatch"
        label="Will we need to price match with the headlining artist?"
        defaultValue={!dayHasVal("data.merchandise.priceMatch")}
      />
      <BooleanInput
        source="data.merchandise.racksProvided"
        label="Are merch racks provided by the venue?"
        defaultValue={!dayHasVal("data.merchandise.racksProvided")}
      />
      <BooleanInput
        source="data.merchandise.location"
        label="Where in the venue will the merch area be located?"
        defaultValue={!dayHasVal("data.merchandise.location")}
      />

      <BandieTooltip
        tooltip={"Questions around how marketing for this gig will be done."}
      >
        <AdvanceHeader>Marketing</AdvanceHeader>
      </BandieTooltip>

      <BooleanInput
        source="data.marketing.announceDate"
        label="What is the announce date for the show?"
        defaultValue={!dayHasVal("data.marketing.announceDate")}
      />
      <BooleanInput
        source="data.marketing.ticketPrice"
        label="What is the ticket price?"
        defaultValue={!dayHasVal("data.marketing.ticketPrice")}
      />
      <BooleanInput
        source="data.marketing.onSaleDate"
        label="When do tickets go onsale?"
        defaultValue={!dayHasVal("data.marketing.onSaleDate")}
      />
      <BooleanInput
        source="data.marketing.ticketOutlet"
        label="What are the ticket outlets?"
        defaultValue={!dayHasVal("data.marketing.ticketOutlet")}
      />
      <BooleanInput
        source="data.marketing.sponsors"
        label="Are there any event sponsors/presenting radio stations, etc. that we should be aware of?"
        defaultValue={!dayHasVal("data.marketing.sponsors")}
      />
      <BooleanInput
        source="data.marketing.socialMedia"
        label="Can you please send me all the social media links/handles/hashtags for the venue/event?"
        defaultValue={true}
      />
      <BooleanInput
        source="data.marketing.plan"
        label="What is your marketing/promotional plan for the event?"
        defaultValue={true}
      />
      <BooleanInput
        source="data.marketing.requests"
        label="If there is anything you would like us to do in terms of press/online/social media promotion on our end, please let us know here."
        defaultValue={true}
      />
      <RadioButtonGroupInput
        source="data.marketing.meetAndGreet"
        choices={[
          { id: "", name: "[omit this question]" },
          { id: "required", name: getMeetAndGreetQuestionText("required") },
          { id: "unknown", name: getMeetAndGreetQuestionText("unknown") },
        ]}
        defaultValue={getDefaultMeetAndGreetQuestion(day)}
        label="Meet and Greet"
        fullWidth
      />

      <BandieTooltip tooltip={"Questions to include regarding Press Passes."}>
        <AdvanceHeader>Press Pass</AdvanceHeader>
      </BandieTooltip>

      <BooleanInput
        source="data.pressPasses.contact"
        label="If we receive press pass requests, who should we talk to in order to have those requests authorized?"
        defaultValue={true}
      />
      <BooleanInput
        source="data.pressPasses.instructions"
        label="Are there any specific press pass instructions? (how to pick up pass, photography restrictions, etc.)"
        defaultValue={true}
      />
      <BooleanInput
        source="data.pressPasses.submitDeadline"
        label="When do you need press pass requests submitted by?"
        defaultValue={true}
      />
      <BandieTip
        tooltip="Have additional questions or details about press passes not covered by the auto-questionnaire? Add them here!"
        Component={AdvanceHeader}
      >
        Press Pass - Additional Questions
      </BandieTip>
      <ArrayInput source="data.pressPasses.addlQuestions" label="">
        <SimpleFormIterator>
          <TextInput multiline source="question" label="Question" fullWidth />
        </SimpleFormIterator>
      </ArrayInput>

      <BandieTooltip tooltip={"Questions to include regarding guest lists."}>
        <AdvanceHeader>Guest List</AdvanceHeader>
      </BandieTooltip>
      <BooleanInput
        source="data.guestList.numberOfSpots"
        label="Do we get any guest list spots for the show? If so, how many?"
        defaultValue={!dayHasVal("data.guestList.numberOfSpots")}
      />
      <BooleanInput
        source="data.guestList.instructions"
        label="Are there any specific guest list instructions? (how to pick up tickets, etc.)"
        defaultValue={true}
      />
      <BooleanInput
        source="data.guestList.submitDeadline"
        label="When do you need us to submit our guest list by? Who do we submit them to?"
        defaultValue={true}
      />
      <BandieTip
        tooltip="Still have more questions? Last chance to add them here!"
        Component={AdvanceHeader}
      >
        Additional Questions
      </BandieTip>
      <ArrayInput source="data.addlQuestions" label="">
        <SimpleFormIterator>
          <TextInput multiline source="question" label="Question" fullWidth />
        </SimpleFormIterator>
      </ArrayInput>

      <BandieTip
        tooltip="List of you bands social Media accounts"
        Component={AdvanceHeader}
      >
        Band Social Media
      </BandieTip>
      <TextInput
        source="data.info.bandSocialMedia.facebook"
        label="Facebook"
        defaultValue={band?.info?.bandSocialMedia?.facebook}
      />
      <TextInput
        source="data.info.bandSocialMedia.instagram"
        label="Instagram"
        defaultValue={band?.info?.bandSocialMedia?.instagram}
      />
      <TextInput
        source="data.info.bandSocialMedia.tiktok"
        label="Tiktok"
        defaultValue={band?.info?.bandSocialMedia?.tiktok}
      />
      <TextInput
        source="data.info.bandSocialMedia.twitter"
        label="Twitter"
        defaultValue={band?.info?.bandSocialMedia?.twitter}
      />
      <TextInput
        source="data.info.bandSocialMedia.website"
        label="Website"
        defaultValue={band?.info?.bandSocialMedia?.website}
      />

      <BandieTip
        tooltip="Upload your pdfs to Google Drive, Dropbox, or another file storage site, and add the urls in the Attachments list."
        Component={AdvanceHeader}
      >
        Attachments
      </BandieTip>

      <FileInput
        source="data.attachments.longBioUrl"
        label="Long Bio (PDF Max 20MB)"
        accept=".pdf"
        maxSize={20000000}
      >
        <FileField source="src" title="title" download="Long Bio" />
      </FileInput>
      <FileInput
        source="data.attachments.shortBioUrl"
        label="Short Bio (PDF Max 20MB)"
        accept=".pdf"
        maxSize={20000000}
      >
        <FileField source="src" title="title" download="Short Bio" />
      </FileInput>
      <FileInput
        multiple
        source="data.attachments.pressPhotosUrl"
        label="Press Photos (Image Max 20MB)"
        accept="image/*"
        maxSize={20000000}
      >
        <FileField source="src" title="title" download="Press Photos" />
      </FileInput>

      <TextInput
        source="data.attachments.techRequirementsUrl"
        label="Stage Plot & Tech Requirements PDF URL"
        defaultValue={band?.attachments?.techRequirementsUrl}
        fullWidth
      />
      <TextInput
        source="data.attachments.backlineRequestsUrl"
        label="Backline Requests PDF URL"
        defaultValue={band?.attachments?.backlineRequestsUrl}
        fullWidth
      />
      <TextInput
        source="data.attachments.lightingUrl"
        label="Lighting Document PDF URL"
        defaultValue={band?.attachments?.lightingUrl}
        fullWidth
      />
      <TextInput
        source="data.attachments.visualsUrl"
        label="Visuals for Screens Download URL"
        defaultValue={band?.attachments?.visualsUrl}
        fullWidth
      />
      <TextInput
        source="data.attachments.riderUrl"
        label="Hospitality Rider PDF URL"
        defaultValue={band?.attachments?.riderUrl}
        fullWidth
      />
      <TextInput
        source="data.attachments.socialLinks"
        label="Band Social Media Links"
        defaultValue={band?.attachments?.socialLinks}
        fullWidth
      />
      <TextInput
        source="data.attachments.bandLogosUrl"
        label="Band Logos (Download)"
        defaultValue={band?.attachments?.bandLogosUrl}
        fullWidth
      />
      <TextInput
        source="data.attachments.mp3sUrl"
        label="MP3s (Download)"
        defaultValue={band?.attachments?.mp3sUrl}
        fullWidth
      />
      <TextInput
        source="data.attachments.otherUrl"
        label="Other (Download)"
        fullWidth
      />

      <BandieTooltip
        tooltip={
          "Fill in band and crew contact details that might be important for the promoter"
        }
      >
        <AdvanceHeader>Band Contacts</AdvanceHeader>
      </BandieTooltip>
      <TextInput
        source="data.bandContacts.dayOfShowContact"
        label="Day of Show Contact"
        fullWidth
        defaultValue={band?.contacts?.dayOfShowContact}
      />
      <TextInput
        source="data.bandContacts.merchContact"
        label="Merch Contact"
        defaultValue={band?.contacts.merchContact}
        fullWidth
      />
      <TextInput
        source="data.bandContacts.socialMediaContact"
        label="Social Media Contact"
        defaultValue={band?.contacts?.socialMediaContact}
        fullWidth
      />
      <TextInput
        source="data.bandContacts.soundTechContact"
        label="Sound Tech Contact"
        defaultValue={band?.contacts?.soundTechContact}
        fullWidth
      />
      <TextInput
        source="data.bandContacts.managerContact"
        label="Manager Contact"
        defaultValue={band?.contacts?.managerContact}
        fullWidth
      />
      <TextInput
        source="data.bandContacts.agentContact"
        label="Agent Contact"
        defaultValue={band?.contacts?.agentContact}
        fullWidth
      />
      <TextInput
        source="data.bandContacts.publicistContact"
        label="Publicist Contact"
        defaultValue={band?.contacts?.publicistContact}
        fullWidth
      />

      <BandieTooltip
        tooltip={
          "Information for the promoter that does not fall into any of the above categories"
        }
      >
        <AdvanceHeader>Additional Information</AdvanceHeader>
      </BandieTooltip>
      <BooleanInput
        source="data.info.allowFileUpload"
        label="Provide an area for a promoter to upload files"
        defaultValue={false}
      />
      <RichTextInput
        toolbar={[["bold", "italic", "underline", "link"]]}
        source="data.info.numInParty"
        label="Number in Party"
        defaultValue={generateTotalNumInParty()}
      />
      <RichTextInput
        toolbar={[["bold", "italic", "underline", "link"]]}
        source="data.info.specialRequests"
        label="Special Requests"
        defaultValue={band?.info?.specialRequests}
      />
      <BandieTooltip
        tooltip={
          "Enter any relevant tax information (GST, W8BEN, etc, depending on which country the show is in)."
        }
      >
        <RichTextInput
          toolbar={[["bold", "italic", "underline", "link"]]}
          source="data.info.taxInformation"
          label="Tax Information"
          defaultValue={band?.info?.taxInformation}
        />
      </BandieTooltip>
      <RichTextInput
        toolbar={[["bold", "italic", "underline", "link"]]}
        source="data.info.signOff"
        label="Sign-off"
        defaultValue={band?.info?.signOff}
      />
    </SimpleForm>
  );
};
