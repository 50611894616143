import React from "react";
import { SelectInput, useGetList } from "react-admin";
import BoolWithNotesInput from "./BoolWithNotesInput";
import { Chip, Button } from "@material-ui/core";
import { SimpleFormIterator } from "../ra-common/SimpleFormIterator";

export const getMemberLabel = (mem) => mem.name || mem.email;

export const MemberChip = (props) => {
  const { record } = props;
  if (!record) return null;
  return (
    <Chip
      style={{ margin: 10 }}
      key={record.id}
      label={getMemberLabel(record)}
    />
  );
};

const useCustomMembers = ({
  filter,
  selected,
  choices,
  nonexistentText = "Deleted",
  ...props
}) => {
  const bandIndex = props.source.match(/\[([0-9]+)\]/)?.[1]; // hack to find index
  const selectedMember = selected?.[bandIndex];
  if (selectedMember) {
    const existsInChoices = choices.find((m) => m.id === selectedMember.id);
    if (!existsInChoices) {
      const mem = { ...selectedMember };
      mem.name = `${getMemberLabel(mem)} (${nonexistentText})`;
      choices.push(mem);
    }
  }

  return {
    optionText: getMemberLabel,
    choices,
    ...props,
    source: props.source + ".id", // hack to add id into object
    label: "Name",
  };
};

const MemberSelectInput = (p) => {
  const s = useCustomMembers(p);
  return <SelectInput {...s} />;
};

export const MemberSelect = ({ filter, nonexistentText, ...props }) => {
  const getManyProps = useGetList("bandMembers", undefined, undefined, filter);
  if (!getManyProps.loaded) return null;
  const choices = Object.values(getManyProps.data);
  const selected = props?.fields?.value;
  const sortedChoices = sortMembers(choices);

  return (
    <>
      <SimpleFormIterator {...props} key={props.fields.length}>
        <MemberSelectInput
          choices={sortedChoices}
          selected={selected}
          filter={filter}
          nonexistentText={nonexistentText}
        />
      </SimpleFormIterator>
      <Button
        style={{ color: "#4350AF", width: "175px" }}
        onClick={() => {
          sortedChoices.forEach((c) => {
            const { fields } = props;
            if (fields) {
              if (
                fields.length === 0 ||
                fields.value.findIndex((f) => f?.id === c?.id) < 0
              ) {
                fields.push(c);
              }
            }
          });
        }}
      >
        add all personnel
      </Button>
    </>
  );
};

export const AddMember = ({ filter, nonexistentText, ...props }) => {
  const getManyProps = useGetList("bandMembers", undefined, undefined, filter);
  if (!getManyProps.loaded) return null;
  const choices = Object.values(getManyProps.data);
  const sortedChoices = sortMembers(choices);
  const selected = props?.fields?.value;

  return (
    <>
      <SimpleFormIterator {...props}>
        <MemberSelectInput
          choices={sortedChoices}
          selected={selected}
          filter={filter}
          nonexistentText={nonexistentText}
        />
        <BoolWithNotesInput source="booked" label="Booked" />
      </SimpleFormIterator>
    </>
  );
};

const sortMembers = (memberList) => {
  return memberList.sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
};
