import React from "react";
import { parse } from "date-fns";
import { KeyboardDateInput as RAKeyboardDateInput } from "../lib/react-admin-date-inputs";

// Inspired by https://github.com/mui-org/material-ui-pickers/issues/1526#issuecomment-628237301

/*
 * Beware workarounds involving bugs in material-ui-pickers' design.
 *
 * See https://github.com/mui-org/material-ui-pickers/issues/1358#issuecomment-628015527
 *
 * @material-ui/pickers operate on a Date, but we really want a String.
 * These funky DateUtils let @material-ui/pickers pick dates in the local
 * timezone ... but they ensure outside callers only see ISO8601 Strings.
 */

export const dateTimeFormatter = function dateTimeFormatter(value) {
  // null, undefined and empty string values should not go through convertDateToString
  // otherwise, it returns undefined and will make the input an uncontrolled one.
  if (value == null || value === "") {
    return "";
  }

  return parse(value, "yyyy-MM-dd", new Date());
};

/**
 * Converts a datetime string without timezone to a date object
 * with timezone, using the browser timezone.
 *
 * @param {String} value Date string, formatted as yyyy-MM-ddThh:mm
 * @return {Date}
 */
export const dateTimeParser = function dateTimeParser(value) {
  const date = new Date(value);

  const ret = [
    String(date.getFullYear()),
    String(101 + date.getMonth()).substring(1),
    String(100 + date.getDate()).substring(1),
  ].join("-");

  return value ? ret : "";
};

// options documentation: https://material-ui-pickers.dev/api/DatePicker

// DateInput is used as a ReactAdmin Input component
export const DateInput = ({ ...props }) => (
  <RAKeyboardDateInput
    parse={dateTimeParser}
    format={dateTimeFormatter}
    options={{
      format: "yyyy-MM-dd",
      autoOk: true,
    }}
    {...props}
  />
);
