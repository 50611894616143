import React from "react";
import { TabbedForm as RATabbedForm } from "react-admin";
import { unescapeAll } from "../lib/util";

export const TabbedForm = ({ record, ...props }) => {
  // When a rich text string returns from the server with a html entity encoding, the form library
  // reports that as dirty. to work around that, we'll pass a record to the form that has all html entities unescaped.
  const unescaped = unescapeAll(record)
  // console.log('uuuu', unescaped)
  return <RATabbedForm {...props} record={unescaped} />
}