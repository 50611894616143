import React from "react";
import ListIcon from "@material-ui/icons/List";
import {
  Datagrid,
  TextField,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  Button,
  EditButton,
  List,
  SelectInput,
  ArrayInput
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
import { Subscription } from "../components/subscription";
import { DeleteBandButton } from "../components/pessimisticDeleteButtons";
import { AdvanceHeader } from "../ra-common/advance";
import { BandieTooltip } from "../ra-common/BandieTip";
import { useFiles } from "../ra-common/fetchResource";
import { SimpleFormIterator } from "../ra-common/SimpleFormIterator";

const ManageButton = ({ record }) => {
  if (!record.manager) return null;

  // Since we are navigating outside of our current react-router,
  // we need this to be a full page refresh so that our current
  // router unloads and the new one loads
  return (
    <Button label="Manage" href={`/manage/${record.id}`}>
      <ListIcon />
    </Button>
  );
};

const SettingsButton = (props) => {
  if (!props.record.manager) return null;
  return <EditButton {...props} label="Settings" />;
};

export const BandList = ({ permissions, ...props }) => {
  return (
    <List
      {...props}
      title="Bandie Management Console"
      bulkActionButtons={false}
      pagination={null}
      exporter={false}
    >
      <Datagrid pagination={null}>
        <TextField source="name" label="Band" sortable={false} />
        <ManageButton />
        <SettingsButton />
      </Datagrid>
    </List>
  )
}

const BandTitle = ({ record }) => {
  return <span>{record.name}</span>;
};

export const BandEdit = (props) => {
  const bandId = props.id

  const [bandFiles, loading] = useFiles(bandId)
  if (loading && bandFiles.length === 0) return null;
  console.log('Band files', bandFiles.length)

  return (
    <Edit title={<BandTitle />} {...props}>
      <SimpleForm variant="standard" toolbar={<DeleteBandButton />}>
        <Subscription />
        <TextInput source="id" label="ID" disabled />
        <TextInput source="name" />

        <AdvanceHeader>Band Contacts</AdvanceHeader>
        <TextInput source="contacts.dayOfShowContact" label="Day of Show Contact" fullWidth />
        <TextInput source="contacts.merchContact" label="Merch Contact" fullWidth />
        <TextInput source="contacts.socialMediaContact" label="Social Media Contact" fullWidth />
        <TextInput source="contacts.soundTechContact" label="Sound Tech Contact" fullWidth />
        <TextInput source="contacts.managerContact" label="Manager Contact" fullWidth />
        <TextInput source="contacts.agentContact" label="Agent Contact" fullWidth />
        <TextInput source="contacts.publicistContact" label="Publicist Contact" fullWidth />

        <AdvanceHeader>Social Media Accounts</AdvanceHeader>
        <TextInput source="info.bandSocialMedia.facebook" label="Facebook" fullWidth />
        <TextInput source="info.bandSocialMedia.instagram" label="Instagram" fullWidth />
        <TextInput source="info.bandSocialMedia.tiktok" label="TikTok" fullWidth />
        <TextInput source="info.bandSocialMedia.twitter" label="Twitter" fullWidth />
        <TextInput source="info.bandSocialMedia.website" label="Website" fullWidth />

        <BandieTooltip tooltip={"Select the files from your list of files to be set at defaults for the advances."}>
          <AdvanceHeader>Attachments</AdvanceHeader>

        </BandieTooltip>

        <ArrayInput source="attachmentsDefaults.techRequirements" label="Stage Plot & Tech Requirements">
          <SimpleFormIterator >
            <SelectInput source='id' optionText="title" optionValue="id" choices={bandFiles} label="Tech Requirements" />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="attachmentsDefaults.backlineRequests" label="Backline Requirements">
          <SimpleFormIterator >
            <SelectInput source='id' optionText="title" optionValue="id" choices={bandFiles} label="Backline Requirements" />
          </SimpleFormIterator>
        </ArrayInput>

        <ArrayInput source="attachmentsDefaults.lighting" label="Lighting" reference="attachmentsDefaults.lightingId">
          <SimpleFormIterator >
            <SelectInput source='id' optionText="title" optionValue="id" choices={bandFiles} label="Lighting" defaultValue="title" />
          </SimpleFormIterator>
        </ArrayInput>

        <ArrayInput source="attachmentsDefaults.visuals" label="Visuals">
          <SimpleFormIterator >
            <SelectInput source='id' optionText="title" optionValue="id" choices={bandFiles} label="Visuals" />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="attachmentsDefaults.rider" label="Rider">
          <SimpleFormIterator >
            <SelectInput source='id' optionText="title" optionValue="id" choices={bandFiles} label="Rider" />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="attachmentsDefaults.longBios" label="Long Bios">
          <SimpleFormIterator >
            <SelectInput source='id' optionText="title" optionValue="id" choices={bandFiles} label="Long Bio" />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="attachmentsDefaults.shortBios" label="Short Bios">
          <SimpleFormIterator >
            <SelectInput source='id' optionText="title" optionValue="id" choices={bandFiles} label="Short Bio" />
          </SimpleFormIterator>
        </ArrayInput>
        {/* <SelectInput source='attachmentsDefaults.longBioID' optionText="title" optionValue="id" choices={bandFiles}  label="Long Bio" resettable/>
        <SelectInput source='attachmentsDefaults.shortBioID' optionText="title" optionValue="id" choices={bandFiles}  label="Short Bio"resettable/> */}
        <ArrayInput source="attachmentsDefaults.pressPhotos" label="Press Photos">
          <SimpleFormIterator >
            <SelectInput source='id' optionText="title" optionValue="id" choices={bandFiles} label="PressPhotos" />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="attachmentsDefaults.bandLogos" label="Band Logos">
          <SimpleFormIterator >
            <SelectInput source='id' optionText="title" optionValue="id" choices={bandFiles} label="Band Logos" />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="attachmentsDefaults.mp3s" label="MP3s">
          <SimpleFormIterator >
            <SelectInput source='id' optionText="title" optionValue="id" choices={bandFiles} label="MP3s" />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="attachmentsDefaults.others" label="Others">
          <SimpleFormIterator >
            <SelectInput source='id' optionText="title" optionValue="id" choices={bandFiles} label="others" />
          </SimpleFormIterator>
        </ArrayInput>

        <AdvanceHeader>Monitor Details</AdvanceHeader>
        <TextInput multiline source="production.monitorDetails" label="Monitor Details" />
        <TextInput multiline source="production.iemFrequencies" label="IEM Frequencies" />

        <AdvanceHeader>Additional Information</AdvanceHeader>
        <RichTextInput toolbar={[["bold", "italic", "underline", "link"]]} source="info.specialRequests" label="Special Requests" />
        <BandieTooltip tooltip={'Enter any relevant tax information (GST, W8BEN, etc, depending on which country the show is in)'}>
          <RichTextInput toolbar={[["bold", "italic", "underline", "link"]]} source="info.taxInformation" label="Tax Information" />
        </BandieTooltip>
        <RichTextInput toolbar={[["bold", "italic", "underline", "link"]]} source="info.signOff" label="Sign-off" />

      </SimpleForm>
    </Edit>
  );
};

export const BandCreate = (props) => (
  <Create title={<BandTitle />} {...props}>
    <SimpleForm variant="standard">
      <TextInput source="id" label="ID" disabled />
      <TextInput source="name" />
    </SimpleForm>
  </Create>
);
