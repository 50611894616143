import { set } from "lodash";

const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve({
        file: reader.result,
        associatedField: file.associatedField,
        title: file.file.title,
      });
    };
    reader.onerror = reject;

    reader.readAsDataURL(file.file.rawFile);
  });

// Given a request object, returns a list of objects that map a File to an associated field
// A file is either a JavaScript File object (in the case of a new upload)
// OR our file model {src: ... , title ...} (if the file already exists and was therefore processed)
const getAllFileMappings = (requestObj) => {
  // Filter out undefined mappings - ie. files that have not been added under their corresponding field
  const uploadFileMappings = [
    // { file: requestObj?.production?.stagePlot, associatedField: "production.stagePlot", },
    { file: requestObj?.production?.setList, associatedField: "production.setList", },
    { file: requestObj?.data?.attachments?.longBioUrl, associatedField: "data.attachments.longBioUrl", },
    { file: requestObj?.data?.attachments?.shortBioUrl, associatedField: "data.attachments.shortBioUrl", },
    { file: requestObj?.attachments?.longBioUrl, associatedField: "attachments.longBioUrl", },
    { file: requestObj?.attachments?.shortBioUrl, associatedField: "attachments.shortBioUrl", },
    { file: requestObj?.fileUpload, associatedField: "file", },
  ]

  // For multi-upload fields, unpack array into uploadFileMappings
  const m1 = requestObj?.data?.attachments?.pressPhotosUrl
    ?.map((file, i) => ({ file, associatedField: `data.attachments.pressPhotosUrl[${i}]` }));
  if (m1) uploadFileMappings.push(...m1)

  const m2 = requestObj?.attachments?.pressPhotosUrl
    ?.map((file, i) => ({ file, associatedField: `attachments.pressPhotosUrl[${i}]` }));
  if (m2) uploadFileMappings.push(...m2)

  return uploadFileMappings.filter((x) => x.file !== undefined);
};

export const getAdvanceFileMappings = (requestObj) => {
  const uploadFileMappings = [];

  const m3 = requestObj?.response?.attachments?.map((file, i) => ({
    file,
    associatedField: `response.attachments[${i}]`,
  }));
  if (m3) uploadFileMappings.push(...m3);

  return uploadFileMappings.filter((x) => x.file !== undefined);
};

const mapFiles = (params, getFileMappings) => {
  const requestObj = params.data;

  const uploadFileMappings = getFileMappings(requestObj);

  const deletedFiles = uploadFileMappings
    .filter((f) => f.file == null)
    .map((f) => ({
      src: "DELETED",
      associatedField: f.associatedField,
      title: null,
    }));

  // Files that must be converted to base64
  const newFiles = uploadFileMappings.filter(
    (f) => f.file != null && f.file.rawFile instanceof File
  );

  // Files already encoded in base64 (put back as is)
  const formerFiles = uploadFileMappings
    .filter((f) => f.file != null && !(f.file.rawFile instanceof File))
    .map((x) => {
      const f = x.file;
      f.associatedField = x.associatedField;
      return f;
    });

  if (uploadFileMappings.length === 0) return Promise.resolve(params);

  return Promise.all(newFiles.map(convertFileToBase64))
    .then((files64) =>
      files64.map((f64) => ({
        src: f64.file,
        associatedField: f64.associatedField,
        title: f64.title,
      }))
    )
    .then((transformedFiles) => {
      const allFiles = [...transformedFiles, ...formerFiles, ...deletedFiles];
      const updatedRequestObj = requestObj;
      // get rid of file objects in pressPhotosUrl field (to replace with new ones)
      if (updatedRequestObj.data?.attachments?.pressPhotosUrl !== undefined) {
        updatedRequestObj.data.attachments.pressPhotosUrl = [];
      }
      allFiles.forEach((file) => {
        // deleted files have a property "file" set to null
        const { associatedField } = file;
        if (file.src === "DELETED") file = null;
        set(updatedRequestObj, associatedField, file);
      });
      params.data = updatedRequestObj;
      return params;
    });
};

const addUploadFeature = (
  dataProvider,
  getFileMappings = getAllFileMappings
) => {
  return {
    ...dataProvider,
    create: (resource, params) => {
      return mapFiles(params, getFileMappings).then((p) =>
        dataProvider.create(resource, p)
      );
    },
    update: (resource, params) => {
      return mapFiles(params, getFileMappings).then((p) =>
        dataProvider.update(resource, p)
      );
    },
  };
};

export default addUploadFeature;
