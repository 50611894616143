import React, { useEffect, useMemo, useState } from "react";
import { getBearer } from "../lib/auth0";
import { groupBy, map, sortBy, first, last, startCase } from "lodash";
import { format, fromUnixTime } from "date-fns";

export const goToCustomerPortal = () => {
  const returnURL = encodeURIComponent(window.location.href);
  const url = `/stripe/portalSession?returnURL=${returnURL}`;
  fetch(url, { headers: { Authorization: getBearer() } })
    .then((response) => response.json())
    .then((sessionURL) => {
      if (sessionURL) window.location.href = sessionURL;
    });
};

const getProducts = () => {
  return fetch("/api/v1/getProducts").then((response) => response.json());
};

export const subscriptionButtonStyle = {
  backgroundColor: "#d2eaef",
  borderRadius: 8,
  margin: 2,
  cursor: "pointer",
  flexDirection: "column",
  display: "flex",
  flex: 1,
  padding: 10,
};

export const UpgradeSubscriptionButton = ({ title }) => {
  return (
    <div onClick={goToCustomerPortal} style={subscriptionButtonStyle}>
      <div style={{ color: "#2d4044", fontSize: 16, fontWeight: "bold" }}>
        {title || "Upgrade Subscription"}
      </div>
    </div>
  );
};

const checkout = (bandId, priceId) => {
  const url = `/api/v1/bands/${bandId}/createCheckoutSession?priceId=${priceId}`;
  fetch(url, { headers: { Authorization: getBearer() } })
    .then((response) => response.json())
    .then((sessionURL) => {
      if (sessionURL) window.location.href = sessionURL;
    });
};

const Products = ({ bandId }) => {
  const [products, setProducts] = useState([]);
  const [selectedSubscriptionInterval, setSelectedSubscriptionInterval] =
    useState("year");

  useEffect(() => {
    getProducts().then((products) => {
      setProducts(products);
    });
  }, []);

  const [paymentOptions, subscriptionIntervals] = useMemo(() => {
    const paymentOptions = {};

    products.forEach(({ prices, ...product }) => {
      prices.forEach((price) => {
        if (!paymentOptions[price.interval])
          paymentOptions[price.interval] = [];
        paymentOptions[price.interval].push({ ...product, ...price });
      });
    });

    const subscriptionIntervals = Object.keys(paymentOptions);
    subscriptionIntervals.forEach((interval) => {
      paymentOptions[interval] = groupBy(
        paymentOptions[interval],
        (a) => a.productId
      );
    });

    return [paymentOptions, subscriptionIntervals];
  }, [products]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          maxWidth: 1500,
        }}
      >
        {subscriptionIntervals.map((subscriptionInterval, i) => {
          const isSelected =
            selectedSubscriptionInterval === subscriptionInterval;
          return (
            <div
              key={subscriptionInterval}
              style={{
                padding: 5,
                width: 80,
                margin: 1,
                textAlign: "center",
                cursor: "pointer",
                backgroundColor: isSelected ? "#698c93" : "#d2eaef",
                color: isSelected ? "white" : "#2d4044",
                ...(i === 0
                  ? { borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }
                  : {}),
                ...(i === subscriptionIntervals.length - 1
                  ? { borderTopRightRadius: 8, borderBottomRightRadius: 8 }
                  : {}),
              }}
              onClick={() =>
                setSelectedSubscriptionInterval(subscriptionInterval)
              }
            >
              {startCase(subscriptionInterval)}
            </div>
          );
        })}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          maxWidth: 1500,
        }}
      >
        {map(
          paymentOptions?.[selectedSubscriptionInterval],
          (duplicatePrices) => {
            const sorted = sortBy(duplicatePrices, ["unitAmount"]);
            const cheapest = first(sorted);
            const mostExpensive = last(sorted);
            const {
              productId,
              name,
              description,
              unitAmount,
              interval,
              priceId,
            } = cheapest;
            const isDiscounted = cheapest.unitAmount < mostExpensive.unitAmount;

            return (
              <div
                key={productId}
                style={{
                  backgroundColor: "#d2eaef",
                  borderRadius: 8,
                  margin: 20,
                  cursor: "pointer",
                  flexDirection: "column",
                  minHeight: 100,
                  display: "flex",
                  flex: 1,
                  padding: 15,
                  maxWidth: 350,
                }}
                onClick={() => {
                  checkout(bandId, priceId);
                }}
              >
                {isDiscounted ? (
                  <h2 style={{ color: "#2d4044" }}>
                    {name}{" "}
                    <span style={{ textDecoration: "line-through" }}>
                      ${(mostExpensive.unitAmount / 100.0).toFixed(2)} /{" "}
                      {interval}
                    </span>
                    <br />
                    Promotion price ${(unitAmount / 100.0).toFixed(2)} /{" "}
                    {interval}
                  </h2>
                ) : (
                  <h2 style={{ color: "#2d4044" }}>
                    {name} ${(unitAmount / 100.0).toFixed(2)} / {interval}
                  </h2>
                )}
                <div>{description}</div>
              </div>
            );
          }
        )}
      </div>

      <div
        style={{ color: "grey", fontSize: 12, marginTop: 15, marginBottom: 20 }}
      >
        * All prices are in USD
      </div>
    </>
  );
};

export const Subscription = (props) => {
  const { record: band } = props;
  const {
    id: bandId,
    memberLimit,
    subscriptionName,
    subscriptionExpiryTimestamp,
  } = band;
  return (
    <>
      <h2>Subscription</h2>
      {memberLimit > 0 ? (
        <>
          <div>
            Subscribed to "Bandie {subscriptionName}". band member limit is{" "}
            {memberLimit}
          </div>
          <div>
            Current Subscription period ends on{" "}
            {format(fromUnixTime(subscriptionExpiryTimestamp), "PP")}
          </div>
        </>
      ) : (
        <>
          <div>Currently Unsubscribed.</div>
          <Products bandId={bandId} />
        </>
      )}
    </>
  );
};
