import React, { Component } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PrintIcon from "@material-ui/icons/Print";
import { Button } from "react-admin";

const openUrlInNewWindow = url => {
  const a = document.createElement("a");
  a.href = url;
  a.target = "_blank";
  document.body.appendChild(a);
  a.click();
  a.remove();
}

export class DownloadMenu extends Component {
  constructor(props) {
    super(props);
    this.state = { anchorEl: null };
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
  }

  show(ev) {
    this.setState({ anchorEl: ev.currentTarget });
  }

  hide() {
    this.setState({ anchorEl: null });
  }

  render() {
    const { record: show } = this.props
    const { anchorEl } = this.state
    const hasPressPass = !!show?.pressPasses?.list
    const hasGuestList = !!show?.guestList?.list
    if (!hasPressPass && !hasGuestList) return null;

    return <>
      <Button onClick={this.show} label="Print"><PrintIcon /></Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.hide}>
        {hasGuestList &&
          <MenuItem onClick={() => openUrlInNewWindow(`/manage/${show.bandId}/shows/${show.id}/guestlist`)}>
            Guest List
          </MenuItem>
        }
        {hasPressPass &&
          <MenuItem onClick={() => openUrlInNewWindow(`/manage/${show.bandId}/shows/${show.id}/presslist`)}>
            Press List
          </MenuItem>
        }
      </Menu>
    </>
  }
}