import React from "react";
import {
  Admin,
  Resource,
  AppBar,
  Layout,
  usePermissions,
  UserMenu,
} from "react-admin";
import { Route } from "react-router-dom";
import simpleRestProvider from "ra-data-simple-rest";
import { createBrowserHistory } from "history";
import UsersIcon from "@material-ui/icons/People";
import BandsIcon from "@material-ui/icons/Grade";
import { LoginPage, AuthorizePage, LoginCallbackPage } from "../pages/login";
import LogoutPage from "../pages/logout";
import authProvider from "../ra-common/authProvider";
import httpClient from "../ra-common/httpClient";
import theme from "../ra-common/theme";
import { BandList, BandEdit, BandCreate } from "./band";
import { UserList, UserEdit, UserCreate } from "./user";
import { AccountLink, LogoutLink } from "../ra-common/Menu";
import { AccountPage } from "../pages/account";
import addUploadFeature from "../ra-common/addUploadFeature";

const history = createBrowserHistory();
export const dataProviderMain = addUploadFeature(simpleRestProvider("/api/v1", httpClient));

const routes = [
  <Route exact path="/callback">
    <LoginCallbackPage />
  </Route>,
  <Route exact path="/authorize">
    <AuthorizePage />
  </Route>,
  <Route exact path="/logout">
    <LogoutPage />
  </Route>,
  <Route exact path="/account">
    <AccountPage />
  </Route>,
];

const CustomUserMenu = () => (
  <UserMenu>
    <LogoutLink />
    <AccountLink />
  </UserMenu>
);

const CustomAppBar = (props) => (
  <AppBar position="absolute" userMenu={<CustomUserMenu />} {...props} />
);

const MyLayout = (props) => {
  const { loaded } = usePermissions();
  if (!loaded) return null;
  return <Layout {...props} appBar={CustomAppBar} />;
};

export const RAMainApp = () => {
  return (
    <Admin
      dataProvider={dataProviderMain}
      history={history}
      customRoutes={routes}
      loginPage={LoginPage}
      authProvider={authProvider}
      theme={theme}
      layout={MyLayout}
    >
      {(permissions) => {
        return [
          <Resource
            name="bands"
            list={BandList}
            edit={BandEdit}
            create={BandCreate}
            icon={BandsIcon}
          />,
          permissions === "admin" ? (
            <Resource
              name="users"
              list={UserList}
              edit={UserEdit}
              create={UserCreate}
              icon={UsersIcon}
            />
          ) : null,
        ];
      }}
    </Admin>
  );
};
