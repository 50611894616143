import React from "react";
import {
  SimpleForm,
  Toolbar,
  SaveButton,
  FormDataConsumer,
  FileInput,
  FileField,
} from "react-admin";
import {
  AdvanceMainHeader,
  AdvanceHeader,
  CondUrlField,
  CondTextField,
  AdvanceInfoSection,
  CondLongTextInput,
  getAccommQuestionText,
  getMeetAndGreetQuestionText,
  AddlQuestionsRecip,
  RAConditional,
  CondFileField,
} from "../ra-common/advance";
import { getProp } from "../lib/util";

const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const PromoterAdvanceEditV1 = (props) => {

  return (
      <SimpleForm toolbar={<CustomToolbar />} variant="standard" {...props}>
        <AdvanceMainHeader />

        <AdvanceHeader show="data.general">
          General Show Questions
        </AdvanceHeader>

        <CondLongTextInput
          show="data.general.venueCapacity"
          source="response.general.venueCapacity"
          label="What is the venue capacity?"
        />
        <CondLongTextInput
          show="data.general.otherActs"
          source="response.general.otherActs"
          label="Are there any other acts performing?"
        />
        <CondLongTextInput
          show="data.general.allAges"
          source="response.general.allAges"
          label="Is the show all ages?"
        />

        <AdvanceHeader show="data.transportation">Transportation</AdvanceHeader>

        <CondLongTextInput
          show="data.transportation.shuttle"
          source="response.transportation.shuttle"
          label="Is a shuttle or transport provided by the venue? If so, please provide details."
        />

        <AddlQuestionsRecip path="transportation.addlQuestions" />

        <AdvanceHeader show="data.accommodations">Accommodations</AdvanceHeader>

        <CondTextField
          source="data.accommodations.details"
          label="Existing Details"
          richText={true}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <CondLongTextInput
              show="data.accommodations.question"
              source="response.accommodations.response"
              label={getAccommQuestionText(
                getProp(formData, "data.accommodations.question")
              )}
              {...rest}
            />
          )}
        </FormDataConsumer>

        <AdvanceHeader show="data.contacts">Contacts</AdvanceHeader>
        <CondLongTextInput
          show="data.contacts.dayOfShow"
          source="response.contacts.dayOfShow"
          label="Who is the day of show contact?"
        />
        <CondLongTextInput
          show="data.contacts.production"
          source="response.contacts.production"
          label="Who is the production/sound contact?"
        />
        <CondLongTextInput
          show="data.contacts.sound"
          source="response.contacts.sound"
          label="Who is the sound contact?"
        />
        <CondLongTextInput
          show="data.contacts.lighting"
          source="response.contacts.lighting"
          label="Who is the lighting contact?"
        />
        <CondLongTextInput
          show="data.contacts.backline"
          source="response.contacts.backline"
          label="Who is the backline contact?"
        />
        <CondLongTextInput
          show="data.contacts.hospitality"
          source="response.contacts.hospitality"
          label="Who is the hospitality contact?"
        />
        <CondLongTextInput
          show="data.contacts.transport"
          source="response.contacts.transport"
          label="Who is the transport contact?"
        />
        <CondLongTextInput
          show="data.contacts.merchandise"
          source="response.contacts.merchandise"
          label="Who is the merchandise contact?"
        />
        <CondLongTextInput
          show="data.contacts.marketing"
          source="response.contacts.marketing"
          label="Who is the marketing/social media contact?"
        />
        <CondLongTextInput
          show="data.contacts.payment"
          source="response.contacts.payment"
          label="Who do we collect payment from after the show?"
        />

        <AddlQuestionsRecip path="contacts.addlQuestions" />

        <AdvanceHeader show="data.timings">Timings</AdvanceHeader>
        <CondLongTextInput
          show="data.timings.loadIn"
          source="response.timings.loadIn"
          label="What is our load in time?"
        />
        <CondLongTextInput
          show="data.timings.soundcheck"
          source="response.timings.soundcheck"
          label="What is our soundcheck time?"
        />
        <CondLongTextInput
          show="data.timings.darkStage"
          source="response.timings.darkStage"
          label="Are there any dark stage times that must be observed?"
        />
        <CondLongTextInput
          show="data.timings.doors"
          source="response.timings.doors"
          label="What is doors time?"
        />
        <CondLongTextInput
          show="data.timings.setLength"
          source="response.timings.setLength"
          label="What is our set length?"
        />
        <CondLongTextInput
          show="data.timings.setStart"
          source="response.timings.setStart"
          label="What is our set start time?"
        />
        <CondLongTextInput
          show="data.timings.otherBands"
          source="response.timings.otherBands"
          label="Are there any other band set times that we need to be aware of?"
        />
        <CondLongTextInput
          show="data.timings.showCurfew"
          source="response.timings.showCurfew"
          label="Is there a show curfew? (ie. time the show must end due to noise bylaws, scheduling, etc.?)"
        />
        <CondLongTextInput
          show="data.timings.venueCurfew"
          source="response.timings.venueCurfew"
          label="Is there a venue curfew? (ie. do we need to be completed load-out at a certain time?)"
        />

        <AdvanceHeader show="data.loadIn">Load-in / Parking</AdvanceHeader>
        <CondLongTextInput
          show="data.loadIn.instructions"
          source="response.loadIn.instructions"
          label="Are there any specific load-in instructions (location of load-in, etc.)?"
        />
        <CondLongTextInput
          show="data.loadIn.tempParking"
          source="response.loadIn.tempParking"
          label="Is there specific parking available for load-in?"
        />
        <CondLongTextInput
          show="data.loadIn.permParking"
          source="response.loadIn.permParking"
          label="Where do we park after load-in?"
        />

        <AdvanceHeader show="data.production">Production / Stage</AdvanceHeader>

        <CondLongTextInput
          show="data.production.backlineConfirmed"
          source="response.production.backlineConfirmed"
          label="I have attached our backline list. Can you confirm our backline needs will be met?"
        />
        <CondLongTextInput
          show="data.production.fohConsole"
          source="response.production.fohConsole"
          label="What is the FOH console?"
        />
        <CondLongTextInput
          show="data.production.stageSize"
          source="response.production.stageSize"
          label="What are the stage dimensions?"
        />
        <CondLongTextInput
          show="data.production.projector"
          source="response.production.projector"
          label="Are there screens? If so, do you need us to provide visuals?"
        />
        <CondLongTextInput
          show="data.production.banner"
          source="response.production.banner"
          label="Can we put stage signage/banners onstage during our set?"
        />

        <AddlQuestionsRecip path="production.addlQuestions" />

        <CondTextField
          source="data.production.monitorDetails"
          label="Monitor Details"
        />
        <CondLongTextInput
          show="data.production.monitorDetails"
          source="response.production.monitorDetails"
          label="Additional notes about monitor details?"
        />

        <CondTextField
          source="data.production.iemFrequencies"
          label="IEM Frequencies"
        />
        <CondLongTextInput
          show="data.production.iemFrequencies"
          source="response.production.iemFrequencies"
          label="Additional notes about IEM frequencies?"
        />

        <AdvanceHeader show="data.hospitality">Hospitality</AdvanceHeader>

        <CondLongTextInput
          show="data.hospitality.greenRoom"
          source="response.hospitality.greenRoom"
          label="Is there a green room provided for the band?"
        />

        <CondLongTextInput
          show="data.hospitality.privateBathrooms"
          source="response.hospitality.privateBathrooms"
          label="Are there private bathroom facilities available for the band?"
        />

        <CondLongTextInput
          show="data.hospitality.showers"
          source="response.hospitality.showers"
          label="Are shower facilities available at the venue?"
        />

        <CondLongTextInput
          show="data.hospitality.info"
          source="response.hospitality.info"
          label="I have attached our rider to this email. Please let us know what you will be providing for hospitality!"
        />

        <CondLongTextInput
          show="data.hospitality.meals"
          source="response.hospitality.meals"
          label="Are any meals being provided? If so, please provide details, meal timings, etc."
        />

        <CondLongTextInput
          show="data.hospitality.mealBuyout"
          source="response.hospitality.mealBuyout"
          label="Is there a meal buyout for band and crew for the show? Please specify."
        />

        <CondLongTextInput
          show="data.hospitality.wifi"
          source="response.hospitality.wifi"
          label="Is wifi available at the venue? If so, please provide network name and password."
        />

        <AdvanceHeader show="data.merchandise">Merchandise</AdvanceHeader>

        <CondLongTextInput
          show="data.merchandise.sellerProvided"
          source="response.merchandise.sellerProvided"
          label="Is a Merch Seller provided?"
        />

        <CondLongTextInput
          show="data.merchandise.split"
          source="response.merchandise.split"
          label="What is the merch split?"
        />

        <CondLongTextInput
          show="data.merchandise.limit"
          source="response.merchandise.limit"
          label="Is there a limit to the number of items that we can sell?"
        />

        <CondLongTextInput
          show="data.merchandise.priceMatch"
          source="response.merchandise.priceMatch"
          label="Will we need to price match with the headlining artist?"
        />

        <CondLongTextInput
          show="data.merchandise.racksProvided"
          source="response.merchandise.racksProvided"
          label="Are merch racks provided by the venue?"
        />

        <CondLongTextInput
          show="data.merchandise.location"
          source="response.merchandise.location"
          label="Where in the venue will the merch area be located?"
        />

        <AdvanceHeader show="data.marketing">Marketing</AdvanceHeader>

        <CondLongTextInput
          show="data.marketing.announceDate"
          source="response.marketing.announceDate"
          label="What is the announce date for the show?"
        />

        <CondLongTextInput
          show="data.marketing.ticketPrice"
          source="response.marketing.ticketPrice"
          label="What is the ticket price?"
        />

        <CondLongTextInput
          show="data.marketing.onSaleDate"
          source="response.marketing.onSaleDate"
          label="When do tickets go onsale?"
        />

        <CondLongTextInput
          show="data.marketing.ticketOutlet"
          source="response.marketing.ticketOutlet"
          label="What are the ticket outlets?"
        />

        <CondLongTextInput
          show="data.marketing.sponsors"
          source="response.marketing.sponsors"
          label="Are there any event sponsors/presenting radio stations, etc. that we should be aware of?"
        />

        <CondLongTextInput
          show="data.marketing.socialMedia"
          source="response.marketing.socialMedia"
          label="Can you please send me all the social media links/handles/hashtags for the venue/event?"
        />

        <CondLongTextInput
          show="data.marketing.plan"
          source="response.marketing.plan"
          label="What is your marketing/promotional plan for the event?"
        />

        <CondLongTextInput
          show="data.marketing.requests"
          source="response.marketing.requests"
          label="If there is anything you would like us to do in terms of press/online/social media promotion on our end, please let us know here."
        />

        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <CondLongTextInput
              show="data.marketing.meetAndGreet"
              source="response.marketing.meetAndGreet"
              label={getMeetAndGreetQuestionText(
                getProp(formData, "data.marketing.meetAndGreet")
              )}
              {...rest}
            />
          )}
        </FormDataConsumer>

        <AdvanceHeader show="data.info.bandSocialMedia">Band Social Media</AdvanceHeader>
        <CondTextField
          source="data.info.bandSocialMedia.facebook"
          label="Facebook"
        />
        <CondTextField
          source="data.info.bandSocialMedia.instagram"
          label="Instagram"
        />
        <CondTextField
          source="data.info.bandSocialMedia.tiktok"
          label="Tiktok"
        />
        <CondTextField
          source="data.info.bandSocialMedia.twitter"
          label="Twitter"
        />
        <CondTextField
          source="data.info.bandSocialMedia.website"
          label="Website"
        />
        <AdvanceHeader show="data.pressPasses">Press Pass</AdvanceHeader>

        <CondLongTextInput
          show="data.pressPasses.contact"
          source="response.pressPasses.contact"
          label="If we receive press pass requests, who should we talk to in order to have those requests authorized?"
        />

        <CondLongTextInput
          show="data.pressPasses.instructions"
          source="response.pressPasses.instructions"
          label="Are there any specific press pass instructions? (how to pick up pass, photography restrictions, etc.)"
        />

        <CondLongTextInput
          show="data.pressPasses.submitDeadline"
          source="response.pressPasses.submitDeadline"
          label="When do you need press pass requests submitted by?"
        />

        <AdvanceHeader show="data.guestList">Guest List</AdvanceHeader>

        <CondLongTextInput
          show="data.guestList.numberOfSpots"
          source="response.guestList.numberOfSpots"
          label="Do we get any guest list spots for the show? If so, how many?"
        />

        <CondLongTextInput
          show="data.guestList.instructions"
          source="response.guestList.instructions"
          label="Are there any specific guest list instructions? (how to pick up tickets, etc.)"
        />

        <CondLongTextInput
          show="data.guestList.submitDeadline"
          source="response.guestList.submitDeadline"
          label="When do you need us to submit our guest list by? Who do we submit them to?"
        />

        <AddlQuestionsRecip path="pressPasses.addlQuestions" />

        <AdvanceHeader show="data.addlQuestions">
          Additional Questions
        </AdvanceHeader>
        <AddlQuestionsRecip path="addlQuestions" />

        <AdvanceHeader show="data.attachments">Attachments</AdvanceHeader>
        <CondUrlField
          source="data.attachments.techRequirementsUrl"
          label="Stage Plot & Tech Requirements (PDF)"
        />
        <CondUrlField
          source="data.attachments.backlineRequestsUrl"
          label="Backline Requests (PDF)"
        />
        <CondUrlField
          source="data.attachments.lightingUrl"
          label="Lighting Document (PDF)"
        />
        <CondUrlField
          source="data.attachments.visualsUrl"
          label="Visuals for Screens (download)"
        />
        <CondUrlField
          source="data.attachments.riderUrl"
          label="Hospitality Rider (PDF)"
        />
        <CondFileField
          source="data.attachments.longBioUrl"
          label="Long Bio (PDF)"
        />
        <CondFileField
          source="data.attachments.shortBioUrl"
          label="Short Bio (PDF)"
        />
        <CondFileField
          source="data.attachments.pressPhotosUrl"
          label="Press Photos (Download)"
        />
        <CondTextField
          source="data.attachments.socialLinks"
          label="Band Social Media Links"
        />
        <CondUrlField
          source="data.attachments.bandLogosUrl"
          label="Band Logos (Download)"
        />
        <CondUrlField
          source="data.attachments.mp3sUrl"
          label="MP3s (Download)"
        />
        <CondUrlField
          source="data.attachments.otherUrl"
          label="Other (Download)"
        />

        <AdvanceHeader show="data.bandContacts">Band Contacts</AdvanceHeader>
        <CondTextField
          source="data.bandContacts.dayOfShowContact"
          label="Day of Show Contact"
        />
        <CondTextField
          source="data.bandContacts.merchContact"
          label="Merch Contact"
        />
        <CondTextField
          source="data.bandContacts.socialMediaContact"
          label="Social Media Contact"
        />
        <CondTextField
          source="data.bandContacts.soundTechContact"
          label="Sound Tech Contact"
        />
        <CondTextField
          source="data.bandContacts.managerContact"
          label="Manager Contact"
        />
        <CondTextField
          source="data.bandContacts.agentContact"
          label="Agent Contact"
        />
        <CondTextField
          source="data.bandContacts.publicistContact"
          label="Publicist Contact"
        />

        <AdvanceHeader show="data.info">Additional Information</AdvanceHeader>
        <AdvanceInfoSection />

        <RAConditional source="data.info.allowFileUpload">
          {(props) => {
            return (
              <FileInput
                {...props}
                multiple
                source="response.attachments"
                label="Max file size 20MB"
                accept="image/*,.pdf"
                maxSize={20000000}
              >
                <FileField
                  source="src"
                  title="title"
                  download="Additional attachments"
                />
              </FileInput>
            );
          }}
        </RAConditional>
      </SimpleForm>
  );
};
