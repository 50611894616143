import React from "react";

import {
  List,
  Datagrid,
  TextField,
  RichTextField,
  ChipField,
  ArrayField,
  BooleanField,
  SingleFieldList,
  EditButton,
  Edit,
  Create,
  TabbedForm,
  FormTab,
  CheckboxGroupInput,
  ArrayInput,
  TextInput,
  SelectInput,
  FormDataConsumer,
  RadioButtonGroupInput,
  Filter,
  TabbedFormTabs,
  Toolbar,
  SaveButton,
  FunctionField,
  ReferenceManyField,
  ReferenceField,
  DateInput,
  Button
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
import { Link as RRLink } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ShowIcon from "@material-ui/icons/MicRounded";
import BoolWithNotesInput from "./BoolWithNotesInput";
import BandieTip from "../ra-common/BandieTip";

import { Timezones } from "../ra-common/constants";
import momentTZ from "moment-timezone";
import { ReferenceDayEventsGrid } from "./event";
import {
  MeetingForm,
  TransportationForm,
  PressEngagementForm,
  ItineraryItemForm,
  FlightForm,
  AccommodationBookingForm,
} from "./eventTypes";
import { dayTypes, eventTypes } from "./constants";
import { MemberChip, AddMember } from "./MemberSelect";
import { CloneDay } from './cloneDay'
import { createBasicShow } from "../ra-common/fetchResource";
import { SimpleFormIterator } from "../ra-common/SimpleFormIterator";

// given EventCommon.Type value, return name from eventTypes mapping
const getEventName = (type) => {
  const matchingType = eventTypes.find((e) => e.type === type);
  return matchingType.name;
};

// Give a dayType key, renders a Chip with the full dayType name
const DayTypeField = (props) => {
  const dayType = dayTypes.find((e) => e.id === props.record);
  return <ChipField record={dayType} source="name" />;
};

const DayFilter = (props) => (
  <Filter {...props}>
    <RadioButtonGroupInput
      label=""
      alwaysOn
      source="past"
      choices={[
        { id: true, name: "Past Dates" },
        { id: false, name: "Upcoming Dates" },
      ]}
    />
  </Filter>
);

const CreateShow = ({ record }) => {
  const { bandID, id: dayId } = record

  async function handleCreateShow() {
    const res = await createBasicShow(dayId, bandID)

    if (!res) return null
    const showId = res?.id
    const editShowURL = `manage/${bandID}/shows/${showId}`
    console.log('Win location', window.location)
    window.location.pathname = editShowURL
  }

  return (
    <Button
      component={RRLink}
      label="Create Show"
      onClick={handleCreateShow}
    >
      <ShowIcon />
    </Button>
  )
};

export const DayList = (props) => {
  return (
    <List
      {...props}
      title="Band Calendar"
      bulkActionButtons={false}
      pagination={null}
      exporter={false}
      filters={<DayFilter />}
      filterDefaultValues={{ past: false }}
    >
      <Datagrid rowClick={null} pagination={null}>
        <TextField source="date" sortable={false} noWrap={true} />
        <ArrayField source="data.type" label="Type" sortable={false}>
          <SingleFieldList linkType={false}>
            <DayTypeField />
          </SingleFieldList>
        </ArrayField>
        <EditButton />
        <CreateShow />
        <CloneDay title="Clone Day" />
      </Datagrid>
    </List>
  );
};

const CreateEditForm = ({ editing, ...rest }) => (
  <TabbedForm
    {...rest}
    tabs={<TabbedFormTabs variant="scrollable" />}
    variant="standard"
  >
    <FormTab label="Summary">
      {editing ? (
        <TextField source="date" label="date" />
      ) : (
        <DateInput source="date" label="Date" />
      )}
      <BandieTip
        tooltip="What are you doing today? Check all that apply. If you don't see an applicable category, click “Other” and fill in what you're doing!"
        Component={CheckboxGroupInput}
        source="data.type"
        label="Type"
        choices={dayTypes}
        defaultValue={[]}
      />

      <FormDataConsumer>
        {({ formData, ...rest }) => {
          if (
            formData.data &&
            formData.data.type &&
            formData.data.type.includes("other")
          )
            return <TextInput source="data.otherType" label="Other Type" />;
        }}
      </FormDataConsumer>

      {editing && (
        <div style={{ marginBottom: 20 }}>
          <div
            style={{
              fontWeight: "500",
              fontSize: 16,
              width: 250,
              borderBottom: "1px solid #9E9E9E",
              marginBottom: "10px",
              paddingBottom: 5,
              color: "#3B3B3B",
            }}
          >
            Show details for the day
          </div>
          <ReferenceManyField
            addLabel={false}
            reference="shows"
            source="id"
            target="dayID"
          >
            <Datagrid
              rowClick={null}
              pagination={null}
              empty={<div style={{ fontSize: 14 }}>No shows on this day </div>}
            >
              <TextField source="event" sortable={false} noWrap={true} />
              <TextField source="venue" sortable={false} noWrap={true} />
              <TextField source="venueAddress" sortable={false} noWrap={true} />
              <BooleanField
                source="confirmed.checked"
                label="Confirmed"
                sortable={false}
                noWrap={true}
              />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </div>
      )}

      <RichTextInput
        toolbar={[["bold", "italic", "underline", "link"]]}
        source="data.notes"
        label="Notes"
      />

      <BandieTip
        tooltip="We know, we know. As an artist, you often go through multiple time zones in a day. You have the option to change the time zone for any time related occurrence, but you can put the time zone you want to be the default for the day here."
        Component={SelectInput}
        source="data.defaultTimezone"
        label="Timezone"
        choices={Timezones}
        defaultValue={momentTZ.tz.guess()}
      />
    </FormTab>

    <FormTab label="Full Itinerary">
      <ReferenceDayEventsGrid
        eventType="events"
        name="Itinerary Items"
        title="Itinerary Items"
        form={ItineraryItemForm}
        tooltip="A complete list of all itinerary items for this day"
        enableCreate={false}
        enableEdit={false}
        filter={{ excludeTypes: ['accommodation', 'transportation'] }}
      >
        <TextField source="start.date" label="Start Date" sortable={false} />
        <ReferenceField label="Show" source="showId" reference="shows">
          <TextField source="event" noWrap={true} />
        </ReferenceField>
        <TextField source="start.date" label="Start Date" sortable={false} />
        <TextField source="start.time" label="Start Time" sortable={false} />
        <FunctionField label="Description" render={record => record?.data?.description} />
        <TextField source="data.location" label="Location" sortable={false} />
        <TextField source="data.duration" label="Duration" sortable={false} />
        <ArrayField source="members" label="Personnel" sortable={false}>
          <SingleFieldList linkType={false}>
            <MemberChip />
          </SingleFieldList>
        </ArrayField>
        <RichTextField source="data.notes" label="Notes" sortable={false} />
        <FunctionField
          label="Event Type"
          render={(record) => getEventName(record.type)}
        />
      </ReferenceDayEventsGrid>
    </FormTab>

    <FormTab label="Personnel">
      <ArrayInput source="data.band" label="Band">
        <AddMember />
      </ArrayInput>
      <ArrayInput source="data.crew" label="Crew">
        <AddMember />
      </ArrayInput>
      <ArrayInput source="data.team" label="Team">
        <AddMember />
      </ArrayInput>
      <BandieTip
        tooltip="Have additional contacts you need to add? Input them here!"
        Component={ArrayInput}
        source="data.otherContacts"
        label="Other"
      >
        <SimpleFormIterator>
          <TextInput source="name" label="Name" />
          <TextInput source="type" label="Type" />
          <TextInput source="company" label="Company" />
          <TextInput source="phone" label="Cell Phone" />
          <TextInput source="workPhone" label="Work Phone" />
          <TextInput source="email" label="Email" />
        </SimpleFormIterator>
      </BandieTip>
    </FormTab>

    <FormTab label="Misc Itinerary">
      <ReferenceDayEventsGrid
        eventType="itineraryitems"
        name="Misc Itinerary Item"
        title="Misc Itinerary Items"
        form={ItineraryItemForm}
        tooltip="Have anything to include on the intinerary that doesn't fit anywhere else? Include it here."
        enableCreate={true}
        enableEdit={true}
      >
        <TextField source="start.date" label="Start Date" sortable={false} />
        <TextField source="start.time" label="Start Time" sortable={false} />
        <TextField
          source="data.description"
          label="Description"
          sortable={false}
          style={{ fontWeight: "bold" }}
        />
        <TextField source="data.location" label="Location" sortable={false} />
        <TextField source="data.duration" label="Duration" sortable={false} />
        <ArrayField source="members" label="Personnel" sortable={false}>
          <SingleFieldList linkType={false}>
            <MemberChip />
          </SingleFieldList>
        </ArrayField>
        <RichTextField source="data.notes" label="Notes" sortable={false} />
      </ReferenceDayEventsGrid>
    </FormTab>

    <FormTab label="Ground Transportation">
      <BandieTip
        tooltip="Only mark the “All Ground Transport Booked” as yes when you have ALL of your transportation for that day booked! Still have something left to book for ground transport? Mark this as no--that will mark it with an “X” in the Days listing and help you remember to book!"
        Component={BoolWithNotesInput}
        source="data.transportation.booked"
        label="Booked"
      />
      <BoolWithNotesInput
        source="data.transportation.provided"
        label="Provided"
      />
      <ReferenceDayEventsGrid
        eventType="transportations"
        name="Transportation Booking"
        title="Transportation Bookings"
        form={TransportationForm}
        enableCreate={true}
        enableEdit={true}
      >
        <TextField source="start.date" label="Start Date" sortable={false} />
        <TextField source="start.time" label="Start Time" sortable={false} />
        <TextField source="end.date" label="End Date" sortable={false} />
        <TextField source="end.time" label="End Time" sortable={false} />
        <TextField
          source="data.type"
          label="Type"
          sortable={false}
          style={{ fontWeight: "bold" }}
        />
        <TextField
          source="data.vendor"
          label="Vendor"
          sortable={false}
          style={{ fontWeight: "bold" }}
        />
        <TextField source="data.address" label="Address" sortable={false} />
        <TextField
          source="data.vehicleType"
          label="Vehicle Type"
          sortable={false}
        />
        <TextField source="data.driver" label="Driver" sortable={false} />
      </ReferenceDayEventsGrid>
    </FormTab>

    <FormTab label="Flights/Trains">
      <BandieTip
        tooltip="Only mark the “All Flights/Trains Booked” as yes when you have ALL of your flights/trains for that day booked! Still have something left to book for flights or trains? Mark this as no--that will mark it with an “X” in the Days listing and help you remember to book!"
        Component={BoolWithNotesInput}
        source="data.flights.booked"
        label="Booked"
      />
      <ReferenceDayEventsGrid
        eventType="flights"
        name="Flight/Train"
        title="Flights/Trains"
        form={FlightForm}
        enableCreate={true}
        enableEdit={true}
      >
        <TextField source="start.date" label="Start Date" sortable={false} />
        <TextField source="start.time" label="Start Time" sortable={false} />
        <TextField source="end.date" label="End Date" sortable={false} />
        <TextField source="end.time" label="End Time" sortable={false} />
        <TextField
          source="data.flightNumber"
          label="Number"
          sortable={false}
          style={{ fontWeight: "bold" }}
        />
        <TextField
          source="data.depAirport"
          label="From"
          sortable={false}
          style={{ fontWeight: "bold" }}
        />
        <TextField
          source="data.arrAirport"
          label="To"
          sortable={false}
          style={{ fontWeight: "bold" }}
        />
        <TextField
          source="data.confirmationNumber"
          label="Confirmation"
          sortable={false}
        />
        <ArrayField source="members" label="Passengers" sortable={false}>
          <SingleFieldList linkType={false}>
            <MemberChip />
          </SingleFieldList>
        </ArrayField>
      </ReferenceDayEventsGrid>
    </FormTab>

    <FormTab label="Accommodations">
      <BandieTip
        tooltip="Only mark the “All Accommodations Booked” as yes when you have ALL of your accommodations for that day booked! Still have something left to book for accommodations? Mark this as no--that will mark it with an “X” in the Days listing and help you remember to book!"
        Component={BoolWithNotesInput}
        source="data.accommodation.booked"
        label="Booked"
      />
      <BoolWithNotesInput
        source="data.accommodation.hotelBeforeShowNeeded"
        label="Needed Before Show"
      />
      <BoolWithNotesInput
        source="data.accommodation.providedByVenue"
        label="Provided By Venue"
      />
      <ReferenceDayEventsGrid
        eventType="accommodations"
        name="Accommodation Booking"
        title="Accommodation Bookings"
        form={AccommodationBookingForm}
        enableCreate={true}
        enableEdit={true}
      >
        <TextField source="start.date" label="Start Date" sortable={false} />
        <TextField source="start.time" label="Start Time" sortable={false} />
        <TextField source="end.date" label="End Date" sortable={false} />
        <TextField source="end.time" label="End Time" sortable={false} />
        <TextField
          source="data.type"
          label="Type"
          sortable={false}
          style={{ fontWeight: "bold" }}
        />
        <TextField
          source="data.vendor"
          label="Vendor"
          sortable={false}
          style={{ fontWeight: "bold" }}
        />
        <TextField source="data.address" label="Address" sortable={false} />
        <TextField source="data.rooms" label="Rooms" sortable={false} />
        <TextField
          source="data.reservationCode"
          label="Reservation"
          sortable={false}
        />
        <ArrayField source="members" label="Personnel" sortable={false}>
          <SingleFieldList linkType={false}>
            <MemberChip />
          </SingleFieldList>
        </ArrayField>
      </ReferenceDayEventsGrid>
    </FormTab>

    <FormTab label="Engagements">
      <ReferenceDayEventsGrid
        eventType="meetings"
        name="Engagement"
        title="Engagements"
        form={MeetingForm}
        tooltip="Have something going on other than a show? Add rehearsals, meetings, conference panels, songwriting sessions, industry parties, etc. here."
        enableCreate={true}
        enableEdit={true}
      >
        <TextField source="start.date" label="Start Date" sortable={false} />
        <TextField source="start.time" label="Start Time" sortable={false} />
        <TextField source="data.duration" label="Duration" sortable={false} />
        <TextField source="data.location" label="Location" sortable={false} />
        <TextField
          source="data.description"
          label="Description"
          sortable={false}
          style={{ fontWeight: "bold" }}
        />
      </ReferenceDayEventsGrid>
    </FormTab>

    <FormTab label="Press">
      <ReferenceDayEventsGrid
        eventType="pressengagements"
        name="Press Event"
        title="Press"
        form={PressEngagementForm}
        enableCreate={true}
        enableEdit={true}
      >
        <TextField source="start.date" label="Start Date" sortable={false} />
        <TextField source="start.time" label="Start Time" sortable={false} />
        <TextField
          source="data.outlet"
          label="Outlet"
          sortable={false}
          style={{ fontWeight: "bold" }}
        />
        <TextField source="data.location" label="Location" sortable={false} />
        <TextField source="data.duration" label="Duration" sortable={false} />
        <BooleanField
          source="data.performance.checked"
          label="Performance"
          sortable={false}
        />
        <RichTextField source="data.notes" label="Notes" sortable={false} />
        <ArrayField source="members" label="Personnel" sortable={false}>
          <SingleFieldList linkType={false}>
            <MemberChip />
          </SingleFieldList>
        </ArrayField>
      </ReferenceDayEventsGrid>
    </FormTab>
  </TabbedForm>
);

const DayTitle = ({ record }) => {
  return (
    <div>
      <IconButton
        color="inherit"
        aria-label="previous day"
        disabled={!(record.meta && record.meta.prevID)}
        component={RRLink}
        to={{
          pathname: "/days/" + (record.meta && record.meta.prevID),
        }}
      >
        <ArrowLeftIcon fontSize="inherit" />
      </IconButton>
      <span>{record.date}</span>
      <IconButton
        color="inherit"
        aria-label="previous day"
        disabled={!(record.meta && record.meta.nextID)}
        component={RRLink}
        to={{
          pathname: "/days/" + (record.meta && record.meta.nextID),
        }}
      >
        <ArrowRightIcon fontSize="inherit" />
      </IconButton>
    </div>
  );
};

// Custom toolbar which lacks the delete button
const DayEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const DayEdit = (props) => (
  <Edit title={<DayTitle />} {...props}>
    <CreateEditForm editing redirect={false} toolbar={<DayEditToolbar />} />
  </Edit>
);

export const DayCreate = (props) => {
  return (
    <Create {...props}>
      <CreateEditForm redirect="edit" />
    </Create>
  );
};
