import React, { Component } from "react";
import {
  Admin,
  Resource,
  Layout,
  AppBar,
  Notification,
  Sidebar,
} from "react-admin";
import simpleRestProvider from "ra-data-simple-rest";
import { createBrowserHistory } from "history";
import httpClient from "../ra-common/httpClient";
import theme from "../ra-common/theme";
import { AdvanceEditController } from "./advance";
import addUploadFeature, { getAdvanceFileMappings } from "../ra-common/addUploadFeature";


const MyAppBar = (props) => <AppBar position="absolute" {...props}></AppBar>;
const MySidebar = (props) => <Sidebar {...props} size={0} />;
const MyLayout = (props) => (
  <Layout
    {...props}
    appBar={MyAppBar}
    sidebar={MySidebar}
    notification={Notification}
  />
);

export class RARecipApp extends Component {
  constructor(props) {
    super(props);
    this.state = { key: this.props.match.params.key, };
  }

  render() {
    const dataProvider = addUploadFeature(
      simpleRestProvider("/api/v1/recip/" + this.state.key, httpClient),
      getAdvanceFileMappings
    );
    const history = createBrowserHistory({ basename: "/recip/" + this.state.key, });
    return (
      <Admin
        dataProvider={dataProvider}
        history={history}
        layout={MyLayout}
        theme={theme}
      >
        <Resource name="advances" edit={AdvanceEditController} />
      </Admin>
    );
  }
}
