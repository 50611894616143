import React, { useEffect, useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  RichTextField,
  ArrayField,
  BooleanField,
  SingleFieldList,
  EditButton,
  Edit,
  FormTab,
  ArrayInput,
  TextInput,
  ReferenceInput,
  TabbedFormTabs,
  FileInput,
  FileField,
  required,
  AutocompleteInput,
  ReferenceField,
  Button,
  SelectInput,
  // DeleteButton,
  // SaveButton,
  // Toolbar
} from "react-admin";
import { DateInput } from "../ra-common/date";
import RichTextInput from "ra-input-rich-text";
import { Link as RRLink } from "react-router-dom";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import BoolWithNotesInput from "./BoolWithNotesInput";
import BandieTip from "../ra-common/BandieTip";
import { ReferenceShowEventsGrid } from "./event";
import { ProdSchedForm, MeetAndGreetForm } from "./eventTypes";
import { DownloadMenu } from "./DownloadMenu";
import { MemberChip } from "./MemberSelect";
import { LocationAutoComplete } from "../components/locationAutoComplete";
import { dataProviderMain } from "../ra-main";
import { useFiles } from "../ra-common/fetchResource";
import { SimpleFormIterator } from "../ra-common/SimpleFormIterator";
import { TabbedForm } from "../components/Forms";
// import { useForm } from "react-final-form";

const CreateOrEditShowForm = ({ editing, ...rest }) => {
  const [bandSocialMedia, setBandSocialMedia] = useState({})
  const dpMain = dataProviderMain
  const match = window.location.pathname.match(/\/manage\/(\d+)\/shows/);
  const bandId = match[1]

  useEffect(() => {
    dpMain.getOne('bands', { id: bandId })
      .then(response => {
        setBandSocialMedia(response?.data?.info?.bandSocialMedia)
      }
      )
  }, [bandId, dpMain])

  const [bandFiles, loading] = useFiles(bandId)
  if (loading) return null

  return (
    <TabbedForm
      {...rest}
      tabs={<TabbedFormTabs variant="scrollable" />}
      variant="standard"
    >
      <FormTab label="Show Info">
        {editing ? (
          <ReferenceField label="Day" source="dayId" reference="days">
            <TextField source="date" />
          </ReferenceField>
        ) : (
          <ReferenceInput
            label="Day"
            source="dayId"
            reference="days"
            validate={[required()]}
          >
            <AutocompleteInput optionText="date" />
          </ReferenceInput>
        )}
        <TextInput source="event" label="Event" />
        <LocationAutoComplete {...rest} source="venue" label="Venue" />
        <LocationAutoComplete {...rest} label="Venue Address" source="venueAddress" />
        <TextInput source="promoter" label="Promoter" />
        <TextInput source="headliner" label="Headliner" />
        <TextInput source="openers" label="Openers" />
        <TextInput source="venueCapacity" label="Capacity" />
        <TextInput source="age" label="Ages" />
        <BoolWithNotesInput source="confirmed" label="Confirmed" />
        <BoolWithNotesInput
          source="contractReceived"
          label="Contract Received"
        />
        <BoolWithNotesInput source="arriveDayEarly" label="Arrive Day Early" />
        <TextInput source="paymentDetails" label="Payment Details" />
      </FormTab>

      <FormTab label="Contacts">
        <ArrayInput source="venueContacts" label="Venue">
          <SimpleFormIterator>
            <TextInput source="name" label="Name" />
            <TextInput source="type" label="Type" />
            <TextInput source="company" label="Company" />
            <TextInput source="phone" label="Cell Phone" />
            <TextInput source="workPhone" label="Work Phone" />
            <TextInput source="email" label="Email" />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="promoterContacts" label="Promoter">
          <SimpleFormIterator>
            <TextInput source="name" label="Name" />
            <TextInput source="type" label="Type" />
            <TextInput source="company" label="Company" />
            <TextInput source="phone" label="Cell Phone" />
            <TextInput source="workPhone" label="Work Phone" />
            <TextInput source="email" label="Email" />
          </SimpleFormIterator>
        </ArrayInput>
        <BandieTip
          tooltip="Have additional contacts you need to add? Input them here!"
          Component={ArrayInput}
          source="otherContacts"
          label="Other"
        >
          <SimpleFormIterator>
            <TextInput source="name" label="Name" />
            <TextInput source="type" label="Type" />
            <TextInput source="company" label="Company" />
            <TextInput source="phone" label="Cell Phone" />
            <TextInput source="workPhone" label="Work Phone" />
            <TextInput source="email" label="Email" />
          </SimpleFormIterator>
        </BandieTip>
      </FormTab>

      <FormTab label="Production Schedule">
        <ReferenceShowEventsGrid
          eventType="prodsched"
          name="Production Schedule"
          title="Production Schedule"
          form={ProdSchedForm}
          tooltip="Make sure to add all of your production timings in this section: Venue Arrival, Parking, Load-in, Soundcheck, Dark Stage Times, Doors, Show/Set Times, and Load-out."
          enableCreate={true}
          enableEdit={true}
        >
          <TextField source="start.date" label="Start Date" sortable={false} />
          <TextField source="start.time" label="Start Time" sortable={false} />
          <TextField source="data.duration" label="Duration" sortable={false} />
          <TextField
            source="data.description"
            label="Description"
            sortable={false}
            style={{ fontWeight: "bold" }}
          />
          <ArrayField source="members" label="Personnel" sortable={false}>
            <SingleFieldList linkType={false}>
              <MemberChip />
            </SingleFieldList>
          </ArrayField>
          <RichTextField source="data.notes" label="Notes" sortable={false} />
        </ReferenceShowEventsGrid>
      </FormTab>

      <FormTab label="Meet and Greet">
        <ReferenceShowEventsGrid
          eventType="meetandgreet"
          name="Meet and Greet"
          title="Meet and Greets"
          form={MeetAndGreetForm}
          enableCreate={true}
          enableEdit={true}
        >
          <TextField source="start.date" label="Start Date" sortable={false} />
          <TextField source="start.time" label="Start Time" sortable={false} />
          <RichTextField source="data.notes" label="Notes" sortable={false} />
          <BooleanField
            source="data.performance.checked"
            label="Performance"
            sortable={false}
          />
          <TextField source="data.duration" label="Duration" sortable={false} />
          <TextField source="data.location" label="Location" sortable={false} />
        </ReferenceShowEventsGrid>
      </FormTab>

      <FormTab label="Production">
        <BoolWithNotesInput
          source="production.backlineProvided"
          label="Backline Provided"
          helperText="Specify what backline is provided"
        />
        <RichTextInput
          toolbar={[["bold", "italic", "underline", "link"]]}
          source="production.backlineNotes"
          label="Backline Notes"
        />
        <BoolWithNotesInput
          source="production.rentingBackline"
          label="Renting Backline"
        />
        <BoolWithNotesInput
          source="production.ownEquipment"
          label="Own Equipment"
        />

        <BoolWithNotesInput
          source="production.provided"
          label="Production Provided"
        />
        <TextInput
          source="production.venueProdSpecs"
          label="Venue Production Specs URL"
          helperText="Songwriting Session, Band Rehearsal, etc."
        />
        <BoolWithNotesInput source="production.inEarMonitors" label="IEM" />
        <BoolWithNotesInput
          source="production.tailSplitMonitorProvided"
          label="Split Provided"
        />
        <BoolWithNotesInput
          source="production.fohConsole"
          label="FOH Console"
        />
        <BoolWithNotesInput source="production.lighting" label="Lighting" />
        <BoolWithNotesInput
          source="production.covered"
          label="OutDoor Stage/Covered"
        />
        <BoolWithNotesInput
          source="production.visuals"
          label="Production Screen/Visuals"
        />
        <BoolWithNotesInput source="production.banner" label="Banner" />

        <TextInput source="production.stageSize" label="Stage Size" />

        <SelectInput
          source="production.stagePlot.id"
          optionText="title"
          optionValue="id"
          choices={bandFiles}
          label="Stage Plot"
          allowEmpty={true}
          emptyValue={null}
          emptyText="No Stage Plot"
        />

        <FileInput
          source="production.setList"
          label="Set List (Max 20MB)"
          accept="image/*,.pdf"
          maxSize={20000000}
        >
          <FileField source="src" title="title" download="Set List" />
        </FileInput>
        <BoolWithNotesInput source="production.dressCode" label="Dress Code" />
      </FormTab>

      <FormTab label="Hospitality">
        <BoolWithNotesInput source="hospitality.greenRoom" label="Green Room" />
        <BoolWithNotesInput
          source="hospitality.mealBuyout"
          label="Meal Buyout"
        />
        <BoolWithNotesInput source="hospitality.meals" label="Meals" />
        <BoolWithNotesInput
          source="hospitality.privateBathrooms"
          label="Private Bathrooms"
        />
        <BoolWithNotesInput
          source="hospitality.provided"
          label="Hospitality Provided"
        />
        <BoolWithNotesInput source="hospitality.showers" label="Showers" />
        <BoolWithNotesInput
          source="hospitality.cateringInformed"
          label="Has catering been informed of food allergies/preferences?"
        />
        <TextInput
          source="hospitality.wifiNetwork"
          label="Wifi Network"
        ></TextInput>
        <TextInput
          source="hospitality.wifiPassword"
          label="Wifi Password"
        ></TextInput>
      </FormTab>

      <FormTab label="Merchandise">
        <BoolWithNotesInput
          source="merchandise.limit"
          label="Limit to Number of Items"
        />
        <TextInput source="merchandise.location" label="Location of Merch" />
        <BoolWithNotesInput
          source="merchandise.sellerProvided"
          label="Seller Provided"
        />
        <BoolWithNotesInput
          source="merchandise.racksProvided"
          label="Racks Provided"
        />
        <BoolWithNotesInput source="merchandise.split" label="Split" />
        <RichTextInput
          toolbar={[["bold", "italic", "underline", "link"]]}
          source="merchandise.notes"
          label="Notes"
        />
        <BoolWithNotesInput
          source="merchandise.priceMatch"
          label="Price Match"
        />
        <BoolWithNotesInput
          source="merchandise.ship"
          label="Ship Prior to Show"
        />
      </FormTab>

      <FormTab label="Social Media">
        <RichTextInput
          toolbar={[["bold", "italic", "underline", "link"]]}
          source="socialMedia.notes"
          label="Notes"
        />
        <TextInput
          source="socialMedia.facebook"
          label="Facebook"
          defaultValue={bandSocialMedia?.facebook}
        />
        <TextInput
          source="socialMedia.instagram"
          label="Instagram"
          defaultValue={bandSocialMedia?.instagram}
        />
        <TextInput
          source="socialMedia.tiktok"
          label="Tiktok"
          defaultValue={bandSocialMedia?.tiktok}
        />
        <TextInput
          source="socialMedia.twitter"
          label="Twitter"
          defaultValue={bandSocialMedia?.twitter}
        />
        <TextInput
          source="socialMedia.website"
          label="Website"
          defaultValue={bandSocialMedia?.website}
        />

        <RichTextInput
          toolbar={[["bold", "italic", "underline", "link"]]}
          source="socialMedia.requests"
          label="Requests"
        />
        <RichTextInput
          toolbar={[["bold", "italic", "underline", "link"]]}
          source="socialMedia.hashtags"
          label="Hashtags"
        />
        <RichTextInput
          toolbar={[["bold", "italic", "underline", "link"]]}
          source="socialMedia.otherBandHandles"
          label="Other Band Handles"
        />
        <RichTextInput
          toolbar={[["bold", "italic", "underline", "link"]]}
          source="socialMedia.promoterHandles"
          label="Promoter Handles"
        />
        <RichTextInput
          toolbar={[["bold", "italic", "underline", "link"]]}
          source="socialMedia.venueHandles"
          label="Venue Handles"
        />
      </FormTab>

      <FormTab label="Marketing">
        <BoolWithNotesInput source="marketing.provided" label="Provided" />
        <DateInput source="marketing.onSaleDate" label="On Sale Date" />
        <DateInput source="marketing.announceDate" label="Announcement Date" />
        <TextInput source="marketing.sponsors" label="Sponsors" />
        <TextInput
          source="marketing.presentingRadioStation"
          label="Presenting Radio Station"
        />
        <TextInput source="marketing.ticketOutlet" label="Ticket Outlet Name" />
        <RichTextInput
          toolbar={[["bold", "italic", "underline", "link"]]}
          source="marketing.ticketOutletNotes"
          label="Ticket Outlet Notes"
        />
        <TextInput source="marketing.ticketPrice" label="Ticket Price" />
      </FormTab>

      <FormTab label="Press Passes">
        <RichTextInput
          toolbar={[["bold", "italic", "underline", "link"]]}
          source="pressPasses.notes"
          label="Notes"
        />
        <BoolWithNotesInput source="pressPasses.submitted" label="Submitted" />
        <DateInput source="pressPasses.submittedDate" label="Submitted Date" />
        <TextInput source="pressPasses.numberOfSpots" label="Number of Spots" />
        <BandieTip
          tooltip="Input the names of the people who require a press pass here. Always be sure to clear these people with the venue, promoter, and headlining act (if applicable) and be sure to communicate and rules, guidelines and restrictions set out by the venue, promoter, or headlining act to press pass holders."
          Component={ArrayInput}
          source="pressPasses.list"
          label="Press Pass List"
        >
          <SimpleFormIterator>
            <TextInput source="name" label="Name" />
          </SimpleFormIterator>
        </BandieTip>
      </FormTab>

      <FormTab label="Guest List">
        <RichTextInput
          toolbar={[["bold", "italic", "underline", "link"]]}
          source="guestList.notes"
          label="Notes"
        />
        <BoolWithNotesInput
          source="guestList.getGuestList"
          label="Do we get a Guest List?"
        />
        <TextInput source="guestList.numberOfSpots" label="Number of Spots" />
        <BoolWithNotesInput source="guestList.submitted" label="Submitted" />
        <BandieTip
          tooltip="Input the names of your guests here. Don’t forget to send this list to the venue and/or promoter! Be sure to communicate any ticket pickup instructions and access rules to your guests."
          Component={ArrayInput}
          source="guestList.list"
          label="Guest List"
        >
          <SimpleFormIterator>
            <TextInput source="name" label="Name" />
          </SimpleFormIterator>
        </BandieTip>
      </FormTab>
    </TabbedForm>
  );
};
const CreateAdvanceButton = ({ record }) => {
  const { dayId, id } = record
  return (
    <Button
      component={RRLink}
      to={{ pathname: "/advances/create", search: `?day_id=${dayId}&show_id=${id}` }}
      label="Create Advance"
    >
      <ChatBubbleIcon />
    </Button>
  )
};

const NoShow = () => <div />;
export const ShowList = (props) => {
  return (
    <List
      {...props}
      title="Shows"
      bulkActionButtons={false}
      pagination={null}
      exporter={false}
      empty={<NoShow />}
      sort={{ field: "dayId", order: "DESC" }}
    >
      <Datagrid rowClick={null} pagination={null} >
        <ReferenceField label="Day" source="dayId" reference="days">
          <TextField source="date" />
        </ReferenceField>
        <TextField source="event" sortable={false} noWrap={false} />
        <TextField source="venue" sortable={false} noWrap={false} />
        <TextField source="venueAddress" sortable={false} noWrap={false} />
        <BooleanField
          source="confirmed.checked"
          label="Confirmed"
          sortable={false}
          noWrap={true}
        />
        <EditButton />
        <CreateAdvanceButton />
        <DownloadMenu />
      </Datagrid>
    </List>
  );
};

// const ShowEditToolbar = (props) => {
//   const form = useForm()
//   console.log('FOFOFOFO', form)
//   return <Toolbar {...props} style={{ justifyContent: 'space-between' }}>
//     <SaveButton />
//     {/* <SaveButton onSave={() => {
//       form.reset()
//     }} /> */}
//     <DeleteButton />
//   </Toolbar>
// }

export const ShowEdit = (props) => {
  return (
    <Edit title={<ShowTitle />} {...props}>
      <CreateOrEditShowForm
        // toolbar={<ShowEditToolbar />} 
        editing redirect={false}
      />
    </Edit>
  )
};

const ShowTitle = ({ record }) => {
  return <span> {record.event ? `${record.event}` : 'Show'}</span>;
};
