import { fetchUtils } from "react-admin";
import { getBearer } from "../lib/auth0";

export default (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  options.headers.set("Authorization", getBearer());
  return fetchUtils.fetchJson(url, options);
};
