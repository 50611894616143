import React, { Component } from "react";
import {
  Admin,
  Resource,
  Layout,
  AppBar,
  UserMenu,
  Loading,
} from "react-admin";
import simpleRestProvider from "ra-data-simple-rest";
import { createBrowserHistory } from "history";
import { Route, withRouter } from "react-router-dom";
import MembersIcon from "@material-ui/icons/Face";
import CalendarIcon from "@material-ui/icons/Today";
import ShowIcon from "@material-ui/icons/MicRounded";
import AdvanceIcon from "@material-ui/icons/QuestionAnswer";
import FileIcon from "@material-ui/icons/Description";
import authProvider from "../ra-common/authProvider";
import httpClient from "../ra-common/httpClient";
import theme from "../ra-common/theme";
import { DayList, DayEdit, DayCreate } from "./day";
import {
  AdvanceList,
  AdvanceEdit,
  AdvanceCreate,
  AdvanceShow,
} from "./advance";
import { FileList, FileCreate } from "./files";
import { BandMemberList, BandMemberEdit, BandMemberCreate } from "./bandMember";
import { PrintGuestlistPage } from "./guestlists";
import addUploadFeature from "../ra-common/addUploadFeature";
import LogoutPage from "../pages/logout";
import { ShowEdit, ShowList } from "./manageShowComponents";
import {
  AccountLink,
  ExitRoute,
  LogoutLink,
  SwitchBandsLink,
} from "../ra-common/Menu";
import { AccountPage } from "../pages/account";
import { bandHasSubscription, useBand } from "../lib/bandUtils";

const CustomUserMenu = () => (
  <UserMenu>
    <SwitchBandsLink />
    <LogoutLink />
    <AccountLink />
  </UserMenu>
);

const appBar = (props) => (
  <AppBar position="absolute" {...props} userMenu={<CustomUserMenu />} />
);
const layout = (props) => <Layout {...props} appBar={appBar} />;

const RAManage = (props) => {
  const { bandid, dataProvider, history } = props;
  const band = useBand(bandid);

  // Wait until the bandName has loaded prior to rendering (and creating the routes),
  // otherwise we will render the routes multiple times.
  if (!band?.name) {
    return (
      <Loading
        loadingPrimary="Loading"
        loadingSecondary="Bandie is loading, just a moment please"
      />
    );
  }

  const PrintGuestlistPageWrapper = withRouter((props) => (
    <PrintGuestlistPage
      {...props}
      dataProvider={dataProvider}
      bandName={band.name}
      pressList={false}
    />
  ));

  const PrintPresslistPageWrapper = withRouter((props) => (
    <PrintGuestlistPage
      {...props}
      dataProvider={dataProvider}
      bandName={band.name}
      pressList={true}
    />
  ));

  const routes = [
    <Route exact path="/exit">
      <ExitRoute />
    </Route>,
    <Route exact path="/shows/:showId/guestlist" noLayout>
      <PrintGuestlistPageWrapper />
    </Route>,
    <Route exact path="/shows/:showId/presslist" noLayout>
      <PrintPresslistPageWrapper />
    </Route>,
    <Route exact path="/logout">
      <LogoutPage />
    </Route>,
    <Route exact path="/account">
      <AccountPage />
    </Route>,
  ];

  const hasSub = bandHasSubscription(band);

  return (
    <Admin
      dataProvider={dataProvider}
      history={history}
      authProvider={authProvider}
      layout={layout}
      customRoutes={routes}
      theme={theme}
    >
      <Resource name="bandMembers" icon={MembersIcon} options={{ label: "Members" }} list={BandMemberList} edit={BandMemberEdit} create={BandMemberCreate} />
      <Resource name="days" list={hasSub && DayList} edit={DayEdit} create={DayCreate} icon={CalendarIcon} />
      <Resource name="shows" list={hasSub && ShowList} edit={ShowEdit} icon={ShowIcon} />
      <Resource name="advances" list={hasSub && AdvanceList} show={AdvanceShow} edit={AdvanceEdit} create={AdvanceCreate} icon={AdvanceIcon} />
      <Resource name="files" list={hasSub && FileList} create={FileCreate} icon={FileIcon} />
      <Resource name="flights" list={false} />
      <Resource name="events" list={false} />
      <Resource name="accommodations" list={false} />
      <Resource name="itineraryitems" list={false} />
      <Resource name="meetings" list={false} />
      <Resource name="pressengagements" list={false} />
      <Resource name="transportations" list={false} />
      <Resource name="prodsched" list={false} />
      <Resource name="meetandgreet" list={false} />
      <Resource name="locations" list={false} />
    </Admin>
  );
};

export const createDataProviderManage = (bandId) => {
  const dataProvider = simpleRestProvider(
    `/api/v1/bands/${bandId}`,
    httpClient
  );
  return addUploadFeature(dataProvider);
};

export class RAManageApp extends Component {
  constructor(props) {
    super(props);
    const bandid = this.props.match.params.bandid;
    this.state = { bandid: bandid, bandName: undefined };
    this.history = createBrowserHistory({ basename: "/manage/" + bandid });
    this.dataProvider = createDataProviderManage(bandid);
  }

  componentDidMount() {
    httpClient("/api/v1/bands/" + this.state.bandid).then(async (resp) => {
      this.setState({ bandName: resp.json.name });
    });
  }

  render() {
    const { bandid } = this.state;
    return (
      <RAManage
        bandid={bandid}
        dataProvider={this.dataProvider}
        history={this.history}
      />
    );
  }
}
