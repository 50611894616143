import React, { Component, useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  Create,
  withDataProvider,
  showNotification,
  Loading,
  ReferenceField,
  Show,
  ShowButton,
} from "react-admin";
import { parse } from "query-string";
import { getProp } from "../lib/util";
import { AdvanceTitle } from "../ra-common/advance";
import Tooltip from "@material-ui/core/Tooltip";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import ClipCopy from "@material-ui/icons/FileCopy";
import { getBand } from "../lib/bandUtils";
import { set } from "lodash";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { AdvanceFormV1, AdvanceShowV1 } from "./advanceV1";
import { AdvanceFormV2, AdvanceShowV2 } from "./advanceV2";

// This renders the magic link that can be used by a recipient to access the advance
class RecipLink extends Component {
  render() {
    const server = window.location.protocol + "//" + window.location.host;
    const advanceID = this.props.record.id;
    const key = this.props.record.key;
    const advanceUrl = `${server}/recip/${key}/advances/${advanceID}`;
    return (
      <div style={{ display: "flex" }}>
        <a
          href={advanceUrl}
          target="_blank"
          rel="noopener noreferrer"
          style={{ marginRight: "1em" }}
        >
          {advanceUrl}
        </a>
        <CopyToClipBoard link={advanceUrl} />
      </div>
    );
  }
}

const CopyToClipBoard = ({ link }) => {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  const handleClick = () => {
    navigator.clipboard.writeText(link);
    setIsSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setIsSnackbarOpen(false);
  };

  return (
    <div>
      <ClipCopy style={{ color: "#303BA6" }} onClick={handleClick} />
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={3000}
        message="Text copied to clipboard"
        onClose={handleCloseSnackbar}
      >
        <SnackbarContent
          style={{
            backgroundColor: "#21998D",
            justifyContent: "center",
            fontSize: 16,
          }}
          message="Advance Link Copied To Clipboard"
        />
      </Snackbar>
    </div>
  );
};

class ShowResponseButton extends Component {
  render() {
    if (this.props.record.response) {
      return <ShowButton label="Response" {...this.props} />;
    }
    return null;
  }
}

const NoAdvances = () => <div />;

const AdvanceTitleComponent = (props) => {
  switch (props?.record?.version) {
    case "v_1":
      return <TextField source="data.title" sortable={false} />;
    case "v_2":
    default:
      return <TextField source="data_v2.title" sortable={false} />;
  }
};

// these are just placeholder displays for now
// until we actually build the questionnaire
export const AdvanceList = (props) => {
  return (
    <div>
      <div style={{ textAlign: "right" }}>
        <Tooltip
          title={
            <React.Fragment>
              <p>
                Edit your existing advances, copy the advance link to send to
                promoters, and see the promoter’s response to your advance all
                from this advance page!
              </p>
              <p>
                Need to create a brand new advance? Go do the Days section of
                the menu, and select “Create Advance” beside the day you need
                the advance for.
              </p>
              <p>DID YOU KNOW?</p>
              <p>
                You can create multiple advances for the same day! You can
                create a General Advance, Tech Advance, Hospitality advance—so
                that you are sending the right questions to the right people.
                Just remember to title them accordingly so you can tell which
                advance is which!
              </p>
            </React.Fragment>
          }
          arrow
        >
          <ContactSupportIcon
            style={{ marginTop: 16, marginLeft: 12, color: "#bfe3e0" }}
          />
        </Tooltip>
      </div>
      <List
        {...props}
        title="Advances"
        bulkActionButtons={false}
        pagination={null}
        exporter={false}
        actions={null}
        empty={<NoAdvances />}
      >
        <Datagrid rowClick={null} pagination={null}>
          <ReferenceField
            label="Date"
            source="dayID"
            reference="days"
            sortable={false}
          >
            <TextField source="date" noWrap={true} />
          </ReferenceField>
          <RecipLink label="Link" />
          <AdvanceTitleComponent label="Title" />
          <EditButton />
          <ShowResponseButton />
          {/* <TextField source="version" label={"Version"} sortable={false} />; */}
        </Datagrid>
      </List>
    </div>
  );
};

const AdvanceShowContent = (props) => {
  const { record } = props;
  const { version } = record;
  switch (version) {
    case "v_1":
      return <AdvanceShowV1 {...props} />;
    case "v_2":
      return <AdvanceShowV2 {...props} />;
    default:
      return null
    }
};

export const AdvanceShow = (props) => (
  <Show title={<AdvanceTitle />} {...props}>
    <AdvanceShowContent />
  </Show>
);

export const AdvanceEdit = (props) => (
  <Edit title={<AdvanceTitle />} {...props}>
    <CreateEditForm redirect={false} editing />
  </Edit>
);

export const AdvanceCreate = (props) => {
  const { day_id: day_id_string, show_id } = parse(props.location.search);
  if (!day_id_string || !show_id) return <div>No day specified</div>;
  const dayID = parseInt(day_id_string, 10);
  const showId = parseInt(show_id, 10);
  return (
    <Create {...props}>
      <CreateEditForm
        redirect="edit"
        defaultValue={{ dayID: dayID, showId: showId }}
      />
    </Create>
  );
};

// This class is connected to withDataProvider so that it has access to make
// its own requests to the API. Following this pattern:
// https://marmelab.com/react-admin/doc/2.9/Actions.html#using-the-withdataprovider-decorator
class _CreateEditForm extends Component {
  state = { day: {}, loadingDay: true, show:{} };

  async componentDidMount() {
    const { dataProvider, record, defaultValue, editing } = this.props;
    const dayID = record.dayID || defaultValue.dayID;
    const showId = defaultValue?.showId

    try {
      // Load the day resource that corresponds to this advance resource
      const { data } = await dataProvider.getOne("days", { id: dayID });
      const band = await getBand(data.bandID);
      if (!record?.data?.attachments?.longBioUrl) {
        set(
          record,
          "data.attachments.longBioUrl",
          band?.attachments?.longBioUrl
        );
      }
      if (!record?.data?.attachments?.shortBioUrl) {
        set(
          record,
          "data.attachments.shortBioUrl",
          band?.attachments?.shortBioUrl
        );
      }
      if (!record?.data?.attachments?.pressPhotosUrl) {
        set(
          record,
          "data.attachments.pressPhotosUrl",
          band?.attachments?.pressPhotosUrl
        );
      }

      this.setState({ day: data, band });
      if (showId && !editing ) {
        const { data } = await dataProvider.getOne("shows", { id: showId });
        this.setState({ show: data });
      }
    } catch (error) {
      showNotification("Error: could not access corresponding day", "warning");
    } finally {
      this.setState({ loadingDay: false });
    }
  }

  render() {
    // Strip off some props, and pass the rest on to <SimpleForm/>
    const { dataProvider, editing, ...rest } = this.props;
    if (this.state.loadingDay) {
      return (
        <Loading
          loadingPrimary="Loading"
          loadingSecondary="Bandie is loading, just a moment please"
        />
      );
    }

    // The entire day object is available for displaying or generating
    // default values within this form.
    const { band, day, show } = this.state;
    // Helper function
    const dayHasVal = (keys) => {
      const val = getProp(day, keys);

      // If it's an empty object, return false
      if (
        Object.prototype.toString.call(val) === "[object Object]" &&
        Object.keys(val).length === 0
      )
        return false;

      return Boolean(val);
    };
    const showHasVal = (keys) =>{

      if(editing) return false
   
      const val = getProp(show, keys);
      // If it's an empty object, return false
      if (
        Object.prototype.toString.call(val) === "[object Object]" &&
        Object.keys(val).length === 0
      )
        return false;

      return Boolean(val);
    }

    const generateTotalNumInParty = () => {
      const bandCount = day?.data?.band?.length || 0;
      const crewCount = day?.data?.crew?.length || 0;
      return `There will be ${
        bandCount + crewCount
      } total in our party. Band: ${bandCount}, Crew: ${crewCount}.`;
    };

    let AdvanceFormComponent;
    switch (rest.record.version) {
      case "v_1":
        AdvanceFormComponent = AdvanceFormV1;
        break;
      case "v_2":
      default:
        AdvanceFormComponent = AdvanceFormV2;
        break;
    }

    return (
      <AdvanceFormComponent
        band={band}
        dayHasVal={dayHasVal}
        showHasVal={showHasVal}
        day={day}
        generateTotalNumInParty={generateTotalNumInParty}
        dataProvider={dataProvider}
        editing={editing}
        {...rest}
      />
    );
  }
}

const CreateEditForm = withDataProvider(_CreateEditForm);
