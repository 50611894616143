import React, { useState } from "react";
import { AutocompleteInput, useGetList } from "react-admin";
import { get } from "lodash";

const asChoice = (text) => ({ id: text + "-choice", address: text });

export const LocationAutoComplete = ({ filter, selected, ...props }) => {
  const [addedChoice, setAddedChoice] = useState();
  const getManyProps = useGetList("locations", undefined, undefined, filter);

  const locationSuggestions = Object.values(getManyProps.data);
  if (addedChoice) locationSuggestions.push(addedChoice);

  const values = props.initialValues || props.record;
  const value = get(values, props.source);
  if (value) {
    const existsInChoices = locationSuggestions.find(
      (m) => m.address === value
    );
    if (!existsInChoices) locationSuggestions.push(asChoice(value));
  }

  const selectInputProps = {
    ...props,
    allowEmpty: true,
    optionText: "address",
    optionValue: "address",
    choices: locationSuggestions,
    onCreate: (text) => {
      const newChoice = asChoice(text);
      setAddedChoice(newChoice);
      return newChoice;
    },
  };

  return <AutocompleteInput {...selectInputProps} />;
};
