import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  Create,
  SimpleForm,
  TextInput,
  TopToolbar,
  CreateButton,
  BooleanInput,
  BooleanField,
  required
} from "react-admin";
import { DeleteUserMemberButton } from "../components/pessimisticDeleteButtons";
import {
  UpgradeSubscriptionButton,
  subscriptionButtonStyle,
} from "../components/subscription";
import { useBand } from "../lib/bandUtils";

const ListActions = ({ band, ...props }) => {
  const memberCount = props.ids?.length ?? 0;
  const { memberLimit } = band;
  const canAddMember = memberCount < memberLimit;

  return (
    <TopToolbar
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
      }}
    >
      <div
        style={{
          borderRadius: 8,
          margin: 2,
          flexDirection: "column",
          display: "flex",
          flex: 1,
          padding: 10,
          fontWeight: 500,
        }}
      >
        Members: {memberCount}/{memberLimit}
      </div>
      {canAddMember ? (
        <CreateButton />
      ) : memberLimit === 0 ? (
        <div
          style={subscriptionButtonStyle}
          onClick={() => (window.location.pathname = `/bands/${band.id}`)}
        >
          Subscribe to add members
        </div>
      ) : (
        <UpgradeSubscriptionButton />
      )}
    </TopToolbar>
  );
};

export const BandMemberList = (props) => {
  const bandId = window.location.pathname.match(/manage\/([0-9]+)/)?.[1]; // hack to find bandId

  const band = useBand(bandId);
  const { memberLimit } = band;

  return (
    <List
      {...props}
      title="Members"
      bulkActionButtons={false}
      pagination={null}
      exporter={false}
      actions={<ListActions band={band} />}
      empty={
        memberLimit === 0 ? (
          <div
            style={{
              marginTop: 100,
              display: "flex",
              flex: "center",
            }}
          >
            <button
              onClick={() => (window.location.pathname = `/bands/${bandId}`)}
              style={{
                backgroundColor: "#d2eaef",
                borderRadius: 8,
                border: "none",
                margin: 2,
                cursor: "pointer",
                flexDirection: "column",
                display: "flex",
                flex: 1,
                padding: 10,
                alignItems: "center",
                fontSize: 20,
              }}
            >
              Create Band Subscription
            </button>
          </div>
        ) : undefined
      }
    >
      <Datagrid rowClick="edit" pagination={null}>
        <TextField source="name" sortable={false} label="Name" />
        <TextField source="phone" sortable={false} label="Phone" />
        <TextField source="email" sortable={false} label="Email" />
        <TextField source="position" sortable={false} label="Position" />
        <TextField source="company" sortable={false} label="Company" />
        <BooleanField source="allAccess" sortable={false} label="All Access" />
        <BooleanField source="manager" sortable={false} label="Manager" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

const MemberTitle = ({ record }) => {
  return <span>{record.name}</span>;
};

export const BandMemberEdit = (props) => {
  const deleteMemberMessage =
    "Are you sure you want to permanently delete this member? You will not be able to recover this member or add them to new events. If you’ve reached your member limit, your subscription can easily be updated by clicking Members or Account.";
  return (
    <Edit title={<MemberTitle />} {...props}>
      <SimpleForm
        redirect={false}
        variant="standard"
        toolbar={<DeleteUserMemberButton deleteMessage={deleteMemberMessage} />}
      >
        <TextInput source="id" label="ID" disabled />
        <TextInput source="email" label="Email" disabled />
        <BooleanInput source="allAccess" label="All Access" />
        <BooleanInput source="manager" label="Manager" />
        <TextInput source="name" label="Name" />
        <TextInput source="phone" label="Phone" />
        <TextInput source="position" label="Position" />
        <TextInput source="company" label="Company" />
      </SimpleForm>
    </Edit>
  );
};

export const BandMemberCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="edit" variant="standard">
      <TextInput source="email" label="Email" validate={[required()]} />
      <BooleanInput source="allAccess" label="All Access" />
      <BooleanInput source="manager" label="Manager" />
      <TextInput source="name" label="Name" />
      <TextInput source="phone" label="Phone" />
      <TextInput source="position" label="Position" />
      <TextInput source="company" label="Company" />
    </SimpleForm>
  </Create>
);
