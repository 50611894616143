import React, {useState} from 'react'
import {
    Button,
    SimpleForm,
    DateInput,
  } from "react-admin";
import IconContentAdd from "@material-ui/icons/Add";
import IconCancel from "@material-ui/icons/Cancel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { getBearer } from '../lib/auth0';

export const CloneDay = ({ title, ...props }) => {
    const [showDialog, setShowDialog] = useState(false);
    const [selectedDay, setSelectedDay] = useState()
    const {id: dayId,bandID} = props.record
    const handleOpenDialog = () => setShowDialog(true);
  
    const handleCloseDialog = () => setShowDialog(false);

    const handleSave = () => {
        const url = `/api/v1/bands/${bandID}/days/${dayId}/clone?date=${selectedDay}`
        fetch(url, { headers: { Authorization: getBearer() }, method:"POST" })
        .then((response) => response.json())
        .catch(err => console.log(err))
        // reload the page
        setShowDialog(false);
        window.location.reload()
    };
    return (
      <>
        <Button onClick={handleOpenDialog} label="Clone">
          <IconContentAdd />
        </Button>
        <Dialog
          fullWidth
          open={showDialog}
          onClose={handleCloseDialog}
          aria-label={title}
        >
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <div>Select which day you would like to clone the current day to.</div>
            <SimpleForm toolbar={false} >
                <DateInput source='day' resource='days' onChange={(d)=>setSelectedDay(d.target.value)}/>
            </SimpleForm>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleSave}
              handleSubmitWithRedirect={() => { }}
              label='Clone Day'
            />
            <Button label="ra.action.cancel" onClick={handleCloseDialog}>
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };
  