import React from "react";
import {
  Datagrid,
  TextField,
  ArrayField,
  SingleFieldList,
  ChipField,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  EditButton,
  BooleanField,
  BooleanInput,
  ArrayInput,
  ReferenceInput,
  List,
  SelectInput,
} from "react-admin";
import { DeleteUserMemberButton } from "../components/pessimisticDeleteButtons";
import { getMemberLabel } from "../ra-manage/MemberSelect";
import { SimpleFormIterator } from "../ra-common/SimpleFormIterator";

// This is basically just a ChipField that selects its color based
// on whether the current record has manager==true or allaccess==true
const BandChip = (props) => {
  const color = props.record.manager
    ? "secondary"
    : props.record.allAccess
    ? "primary"
    : "default";
  return <ChipField {...props} color={color} />;
};

export const UserList = (props) => (
  <List
    {...props}
    title="Bandie Management Console"
    bulkActionButtons={false}
    pagination={null}
    exporter={false}
  >
    <Datagrid pagination={null}>
      <TextField source="id" label="ID" sortable={false} />
      <TextField source="email" sortable={false} />
      <BooleanField source="admin" sortable={false} />
      <ArrayField source="members" label="Bands" sortable={false}>
        <SingleFieldList linkType={false}>
          <BandChip source="bandName" />
        </SingleFieldList>
      </ArrayField>
      <EditButton />
    </Datagrid>
  </List>
);

const UserTitle = ({ record }) => {
  return <span>{record.email}</span>;
};

export const UserEdit = (props) => {
  const deleteMessage =
    "Are you sure you want to permanently delete this user? You will not be able to recover this user and they will not have access to Bandie. If you’ve reached your member limit, your subscription can easily be updated by clicking Members or Account.";
  return (
    <Edit title={<UserTitle />} {...props}>
      <SimpleForm
        variant="standard"
        toolbar={<DeleteUserMemberButton deleteMessage={deleteMessage} />}
      >
        <TextInput source="id" label="ID" disabled />
        <TextInput source="email" />
        <BooleanInput source="admin" />
        <ArrayInput source="members" label="Band Associations">
          <SimpleFormIterator>
            <ReferenceInput label="Band" source="bandID" reference="bands">
              <SelectInput optionText={getMemberLabel} />
            </ReferenceInput>
            <BooleanInput label="All Access" source="allAccess" />
            <BooleanInput label="Manager" source="manager" />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export const UserCreate = (props) => (
  <Create title={<UserTitle />} {...props}>
    <SimpleForm variant="standard">
      <TextInput source="id" label="ID" disabled />
      <TextInput source="email" />
      <BooleanInput source="admin" />
      <ArrayInput source="bandAssociations" label="Band Associations">
        <SimpleFormIterator>
          <ReferenceInput label="Band" source="bandID" reference="bands">
            <SelectInput optionText={getMemberLabel} />
          </ReferenceInput>
          <BooleanInput label="All Access" source="allAccess" />
          <BooleanInput label="Manager" source="manager" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);
