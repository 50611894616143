import momentTZ from "moment-timezone";
import React from "react";
import MenuItem from "@material-ui/core/MenuItem";

export const Timezones = [];

for (var i = 0; i < momentTZ.tz.names().length; i++) {
  Timezones.push({
    id: momentTZ.tz.names()[i],
    name: momentTZ.tz.names()[i],
  });
}

export const Hours = [
  <MenuItem key="00" value="00">
    00
  </MenuItem>,
  <MenuItem key="01" value="01">
    01
  </MenuItem>,
  <MenuItem key="02" value="02">
    02
  </MenuItem>,
  <MenuItem key="03" value="03">
    03
  </MenuItem>,
  <MenuItem key="04" value="04">
    04
  </MenuItem>,
  <MenuItem key="05" value="05">
    05
  </MenuItem>,
  <MenuItem key="06" value="06">
    06
  </MenuItem>,
  <MenuItem key="07" value="07">
    07
  </MenuItem>,
  <MenuItem key="08" value="08">
    08
  </MenuItem>,
  <MenuItem key="09" value="09">
    09
  </MenuItem>,
  <MenuItem key="10" value="10">
    10
  </MenuItem>,
  <MenuItem key="11" value="11">
    11
  </MenuItem>,
  <MenuItem key="12" value="12">
    12
  </MenuItem>,
  <MenuItem key="13" value="13">
    13
  </MenuItem>,
  <MenuItem key="14" value="14">
    14
  </MenuItem>,
  <MenuItem key="15" value="15">
    15
  </MenuItem>,
  <MenuItem key="16" value="16">
    16
  </MenuItem>,
  <MenuItem key="17" value="17">
    17
  </MenuItem>,
  <MenuItem key="18" value="18">
    18
  </MenuItem>,
  <MenuItem key="19" value="19">
    19
  </MenuItem>,
  <MenuItem key="20" value="20">
    20
  </MenuItem>,
  <MenuItem key="21" value="21">
    21
  </MenuItem>,
  <MenuItem key="22" value="22">
    22
  </MenuItem>,
  <MenuItem key="23" value="23">
    23
  </MenuItem>,
  <MenuItem key="1+00" value="1+00">
    1+00
  </MenuItem>,
  <MenuItem key="1+01" value="1+01">
    1+01
  </MenuItem>,
  <MenuItem key="1+02" value="1+02">
    1+02
  </MenuItem>,
  <MenuItem key="1+03" value="1+03">
    1+03
  </MenuItem>,
  <MenuItem key="1+04" value="1+04">
    1+04
  </MenuItem>,
  <MenuItem key="1+05" value="1+05">
    1+05
  </MenuItem>,
  <MenuItem key="1+06" value="1+06">
    1+06
  </MenuItem>,
  <MenuItem key="1+07" value="1+07">
    1+07
  </MenuItem>,
];

export const Minutes = [
  <MenuItem key="00" value="00">
    00
  </MenuItem>,
  <MenuItem key="01" value="01">
    01
  </MenuItem>,
  <MenuItem key="02" value="02">
    02
  </MenuItem>,
  <MenuItem key="03" value="03">
    03
  </MenuItem>,
  <MenuItem key="04" value="04">
    04
  </MenuItem>,
  <MenuItem key="05" value="05">
    05
  </MenuItem>,
  <MenuItem key="06" value="06">
    06
  </MenuItem>,
  <MenuItem key="07" value="07">
    07
  </MenuItem>,
  <MenuItem key="08" value="08">
    08
  </MenuItem>,
  <MenuItem key="09" value="09">
    09
  </MenuItem>,
  <MenuItem key="10" value="10">
    10
  </MenuItem>,
  <MenuItem key="11" value="11">
    11
  </MenuItem>,
  <MenuItem key="12" value="12">
    12
  </MenuItem>,
  <MenuItem key="13" value="13">
    13
  </MenuItem>,
  <MenuItem key="14" value="14">
    14
  </MenuItem>,
  <MenuItem key="15" value="15">
    15
  </MenuItem>,
  <MenuItem key="16" value="16">
    16
  </MenuItem>,
  <MenuItem key="17" value="17">
    17
  </MenuItem>,
  <MenuItem key="18" value="18">
    18
  </MenuItem>,
  <MenuItem key="19" value="19">
    19
  </MenuItem>,
  <MenuItem key="20" value="20">
    20
  </MenuItem>,
  <MenuItem key="21" value="21">
    21
  </MenuItem>,
  <MenuItem key="22" value="22">
    22
  </MenuItem>,
  <MenuItem key="23" value="23">
    23
  </MenuItem>,
  <MenuItem key="24" value="24">
    24
  </MenuItem>,
  <MenuItem key="25" value="25">
    25
  </MenuItem>,
  <MenuItem key="26" value="26">
    26
  </MenuItem>,
  <MenuItem key="27" value="27">
    27
  </MenuItem>,
  <MenuItem key="28" value="28">
    28
  </MenuItem>,
  <MenuItem key="29" value="29">
    29
  </MenuItem>,
  <MenuItem key="30" value="30">
    30
  </MenuItem>,
  <MenuItem key="31" value="31">
    31
  </MenuItem>,
  <MenuItem key="32" value="32">
    32
  </MenuItem>,
  <MenuItem key="33" value="33">
    33
  </MenuItem>,
  <MenuItem key="34" value="34">
    34
  </MenuItem>,
  <MenuItem key="35" value="35">
    35
  </MenuItem>,
  <MenuItem key="36" value="36">
    36
  </MenuItem>,
  <MenuItem key="37" value="37">
    37
  </MenuItem>,
  <MenuItem key="38" value="38">
    38
  </MenuItem>,
  <MenuItem key="39" value="39">
    39
  </MenuItem>,
  <MenuItem key="40" value="40">
    40
  </MenuItem>,
  <MenuItem key="41" value="41">
    41
  </MenuItem>,
  <MenuItem key="42" value="42">
    42
  </MenuItem>,
  <MenuItem key="43" value="43">
    43
  </MenuItem>,
  <MenuItem key="44" value="44">
    44
  </MenuItem>,
  <MenuItem key="45" value="45">
    45
  </MenuItem>,
  <MenuItem key="46" value="46">
    46
  </MenuItem>,
  <MenuItem key="47" value="47">
    47
  </MenuItem>,
  <MenuItem key="48" value="48">
    48
  </MenuItem>,
  <MenuItem key="49" value="49">
    49
  </MenuItem>,
  <MenuItem key="50" value="50">
    50
  </MenuItem>,
  <MenuItem key="51" value="51">
    51
  </MenuItem>,
  <MenuItem key="52" value="52">
    52
  </MenuItem>,
  <MenuItem key="53" value="53">
    53
  </MenuItem>,
  <MenuItem key="54" value="54">
    54
  </MenuItem>,
  <MenuItem key="55" value="55">
    55
  </MenuItem>,
  <MenuItem key="56" value="56">
    56
  </MenuItem>,
  <MenuItem key="57" value="57">
    57
  </MenuItem>,
  <MenuItem key="58" value="58">
    58
  </MenuItem>,
  <MenuItem key="59" value="59">
    59
  </MenuItem>,
];
