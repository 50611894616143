import React from "react";
import { EventForm } from "./event";
import BoolWithNotesInput from "./BoolWithNotesInput";
import { TextInput, CheckboxGroupInput, ArrayInput } from "react-admin";
import RichTextInput from "ra-input-rich-text";
import DateTimeTZInput from "./DateTimeTZInput";
import TimeTZInput from "./TimeTZInput";
import BandieTip, { BandieTooltip } from "../ra-common/BandieTip";
import Tooltip from "@material-ui/core/Tooltip";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import { MemberSelect } from "./MemberSelect";
import { LocationAutoComplete } from "../components/locationAutoComplete";
import { useDay } from "../ra-common/fetchResource";
import { isEmpty } from "lodash";
import { useFormState } from "react-final-form";

const anyDayId = (initialValues) => {
  const { dayId, dayID, dayid } = initialValues;
  return dayId ?? dayID ?? dayid; // I don't even know anymore
};

const MemberSelectForDateRange = (props) => {
  const { initialValues } = props;
  const { values } = useFormState()
  const { start, end } = values ?? {};

  // pull out
  const bandMemberFilter = {};
  if (start?.date || end?.date) {
    bandMemberFilter.startDate = start?.date ?? end?.date;
    bandMemberFilter.endDate = end?.date ?? start?.date;
  } else {
    bandMemberFilter.dayid = anyDayId(initialValues);
  }

  return (
    <BandieTip
      {...props}
      tooltip="As you change the start and end dates, the list will include all personnel that are present on the days within the range of dates."
      Component={ArrayInput}
      source="members"
      label="Personnel"
    >
      <MemberSelect filter={bandMemberFilter} nonexistentText={'Not in date range'} />
    </BandieTip>
  );
};

export const MeetingForm = (props) => {
  const timeZone = props.initialValues.defaultTimezone || "";
  return (
    <EventForm {...props}>
      <TimeTZInput source="start" label="Start" defaultTimeZone={timeZone} />
      <TextInput
        source="data.description"
        label="Description"
        variant="standard"
        helperText="Songwriting Session, Band Rehearsal, etc."
      />
      <LocationAutoComplete
        {...props}
        source="data.location"
        label="Location"
        variant="standard"
      />
      <TextInput
        source="data.duration"
        label="Duration"
        variant="standard"
        helperText="0h00"
      />
      <RichTextInput
        toolbar={[["bold", "italic", "underline", "link"]]}
        source="data.notes"
        label="Notes"
        variant="standard"
      />
      {props.members.length > 0 ? (
        <BandieTip
          tooltip="Choose which members of your team will get this timing added to their customized individual schedule!"
          Component={ArrayInput}
          source="members"
          label="Personnel"
        >
          <MemberSelect filter={{ dayid: anyDayId(props.initialValues) }} />
        </BandieTip>
      ) : (
        <div style={{ display: "flex", alignItems: "center" }}>
          <p
            style={{
              fontSize: "12px",
              opacity: 0.54,
              marginRight: "1rem",
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            }}
          >
            Personnel
          </p>
          <Tooltip
            title={
              "You must have contacts added in the Personnel tab in order to add members."
            }
            arrow
          >
            <ContactSupportIcon style={{ color: "#bfe3e0" }} />
          </Tooltip>
        </div>
      )}
    </EventForm>
  );
};

export const ProdSchedForm = (props) => {
  const dayId = props?.initialValues?.dayId || "";
  const match = window.location.pathname.match(/\/manage\/(\d+)\/shows/);
  const bandId = match[1];
  let timezone = "";

  const [day, loading] = useDay(bandId, dayId);
  if (loading && isEmpty(day)) return null;
  timezone = day?.data?.defaultTimezone;

  return (
    <EventForm {...props}>
      <TimeTZInput source="start" label="Start" defaultTimeZone={timezone} />
      <TextInput
        source="data.description"
        label="Description"
        variant="standard"
      />
      <LocationAutoComplete
        {...props}
        source="data.location"
        label="Location"
        variant="standard"
      />
      <TextInput source="data.duration" label="Duration" variant="standard" />
      <RichTextInput
        toolbar={[["bold", "italic", "underline", "link"]]}
        source="data.notes"
        label="Notes"
        variant="standard"
      />
      <BandieTip
        tooltip="Choose which members of your team will get this timing added to their customized individual schedule! If you do not choose any members, all members listed on this event will receive this timing by default."
        Component={ArrayInput}
        source="members"
        label="Personnel"
      >
        <MemberSelect filter={{ dayid: anyDayId(props.initialValues) }} />
      </BandieTip>
    </EventForm>
  );
};

export const TransportationForm = (props) => {
  const { initialValues } = props;
  const timeZone = initialValues?.defaultTimeZone || "";

  return (
    <EventForm {...props}>
      <DateTimeTZInput
        source="start"
        label="Start"
        defaultTimeZone={timeZone}
      />
      <DateTimeTZInput source="end" label="End" defaultTimeZone={timeZone} />
      <TextInput source="data.driver" label="Driver" variant="standard" />
      <TextInput source="data.type" label="Type" variant="standard" />
      <TextInput
        source="data.vendor"
        label="Rental Company"
        variant="standard"
      />
      <LocationAutoComplete
        {...props}
        source="data.address"
        label="Address"
        variant="standard"
      />
      <TextInput
        source="data.vehicleType"
        label="Vehicle Type"
        variant="standard"
      />
      <TextInput
        source="data.reservationCode"
        label="Reservation"
        variant="standard"
      />
      <RichTextInput
        toolbar={[["bold", "italic", "underline", "link"]]}
        source="data.notes"
        label="Notes"
        variant="standard"
        fullWidth
      />
      <BoolWithNotesInput
        source="data.booked"
        label="Booked"
        variant="standard"
      />
      <MemberSelectForDateRange {...props} />
    </EventForm>
  );
};

export const PressEngagementForm = (props) => {
  const timeZone = props.initialValues.defaultTimezone || "";
  return (
    <EventForm {...props}>
      <TimeTZInput
        source="start"
        label="Start"
        variant="standard"
        defaultTimeZone={timeZone}
      />
      <TextInput source="data.type" label="Type" variant="standard" />
      <TextInput source="data.outlet" label="Outlet" variant="standard" />
      <LocationAutoComplete
        {...props}
        source="data.location"
        label="Location"
        variant="standard"
      />
      <TextInput source="data.duration" label="Duration" variant="standard" />
      <BoolWithNotesInput source="data.performance" label="Performance" />
      <RichTextInput
        toolbar={[["bold", "italic", "underline", "link"]]}
        source="data.notes"
        label="Notes"
        variant="standard"
      />
      <BandieTip
        tooltip="Choose which members of your team will get this timing added to their customized individual schedule! If you do not choose any members, all members listed on this event will receive this timing by default."
        Component={ArrayInput}
        source="members"
        label="Personnel"
      >
        <MemberSelect filter={{ dayid: anyDayId(props.initialValues) }} />
      </BandieTip>
    </EventForm>
  );
};

export const ItineraryItemForm = (props) => {
  const timeZone = props.initialValues.defaultTimezone || "";
  return (
    <EventForm {...props}>
      <TimeTZInput source="start" label="Start" defaultTimeZone={timeZone} />
      <TextInput
        source="data.description"
        label="Description"
        variant="standard"
      />
      <LocationAutoComplete
        {...props}
        source="data.location"
        label="Location"
        variant="standard"
      />
      <TextInput source="data.duration" label="Duration" variant="standard" />
      <RichTextInput
        toolbar={[["bold", "italic", "underline", "link"]]}
        source="data.notes"
        label="Notes"
        variant="standard"
      />
      <BandieTip
        tooltip="Choose which members of your team will get this timing added to their customized individual schedule! If you do not choose any members, all members listed on this event will receive this timing by default."
        Component={ArrayInput}
        source="members"
        label="Personnel"
      >
        <MemberSelect filter={{ dayid: anyDayId(props.initialValues) }} />
      </BandieTip>
    </EventForm>
  );
};

// Mapping of available hotel amenities"
const amenities = [
  { id: "hotTub", name: "Hot Tub" },
  { id: "pool", name: "Pool" },
  { id: "gym", name: "Gym" },
  { id: "breakfast", name: "Breakfast" },
  { id: "roomService", name: "Room Service" },
  { id: "parking", name: "Parking" },
  { id: "gearLockup", name: "Gear Lockup" },
  { id: "laundry", name: "Laundry" },
  { id: "kitchen", name: "Kitchen" },
];
export const AccommodationBookingForm = (props) => {
  const timeZone = props.initialValues.defaultTimezone || "";
  return (
    <EventForm {...props}>
      <DateTimeTZInput
        source="start"
        label="Start"
        defaultTimeZone={timeZone}
      />
      <DateTimeTZInput source="end" label="End" defaultTimeZone={timeZone} />
      <TextInput source="data.type" label="Type" variant="standard" />
      <TextInput source="data.vendor" label="Vendor" variant="standard" />
      <LocationAutoComplete
        {...props}
        source="data.address"
        label="Address"
        variant="standard"
      />
      <TextInput source="data.rooms" label="Rooms" variant="standard" />
      <TextInput
        source="data.reservationCode"
        label="Reservation"
        variant="standard"
      />
      <BandieTip
        tooltip="Help your tourmates plan by letting them know what amenities are available at your accommodations! Don’t know what is available? We suggest a simple Google search of your accommodations to find the answers."
        Component={CheckboxGroupInput}
        source="data.amenities"
        label="Amenities"
        choices={amenities}
      />
      <RichTextInput
        toolbar={[["bold", "italic", "underline", "link"]]}
        source="data.notes"
        label="Notes"
        variant="standard"
      />
      <BoolWithNotesInput source="data.booked" label="Booked" />
      <MemberSelectForDateRange {...props} />
    </EventForm>
  );
};

export const FlightForm = (props) => {
  const timeZone = props.initialValues.defaultTimezone || "";
  return (
    <EventForm {...props}>
      <DateTimeTZInput
        source="start"
        label="Departure Time"
        defaultTimeZone={timeZone}
      />
      <DateTimeTZInput
        source="end"
        label="Arrival Time"
        defaultTimeZone={timeZone}
      />
      <TextInput
        source="data.flightNumber"
        label="Flight/Train Number"
        variant="standard"
      />
      <TextInput
        source="data.confirmationNumber"
        label="Confirmation Number"
        variant="standard"
      />
      <TextInput
        source="data.depAirport"
        label="Departure Airport/Station"
        variant="standard"
      />
      <TextInput source="data.layover" label="Layover" variant="standard" />
      <TextInput
        source="data.arrAirport"
        label="Arrival Airport/Station"
        variant="standard"
      />
      <RichTextInput
        toolbar={[["bold", "italic", "underline", "link"]]}
        source="data.notes"
        label="Notes"
        variant="standard"
      />
      <BoolWithNotesInput source="data.booked" label="Booked" />
      <MemberSelectForDateRange {...props} />
    </EventForm>
  );
};

export const MeetAndGreetForm = (props) => {
  const dayId = props?.initialValues?.dayId || "";
  const match = window.location.pathname.match(/\/manage\/(\d+)\/shows/);
  const bandId = match[1];
  let timezone = "";

  const [day, loading] = useDay(bandId, dayId);
  if (loading && isEmpty(day)) return null;
  timezone = day?.data?.defaultTimezone;

  return (
    <EventForm {...props}>
      <BoolWithNotesInput source="data.required" label="Required" />
      <TimeTZInput source="start" label="Start" defaultTimeZone={timezone} />
      <TextInput source="data.duration" label="Duration" variant="standard" />
      <LocationAutoComplete
        {...props}
        source="data.location"
        label="Location"
        variant="standard"
      />
      <RichTextInput
        toolbar={[["bold", "italic", "underline", "link"]]}
        source="data.notes"
        label="Notes"
        variant="standard"
        fullWidth
      />
      <BoolWithNotesInput source="data.escort" label="Escort" />
      <BoolWithNotesInput
        source="data.merchandise"
        label="Merchandise required"
      />
      <BoolWithNotesInput
        source="data.performance"
        label="Meet and Greet performance"
      />
      <BoolWithNotesInput source="data.photographer" label="Photographer" />

      <BandieTooltip tooltip="Choose which members of your team will get this timing added to their customized individual schedule! If you do not choose any members, all members listed on this event will receive this timing by default.">
        <ArrayInput source="members" label="Personnel">
          <MemberSelect filter={{ dayid: anyDayId(props.initialValues) }} />
        </ArrayInput>
      </BandieTooltip>
    </EventForm>
  );
};
