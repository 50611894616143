import React, { Component } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { MenuItemLink } from "react-admin";

// MenuItemLink can't use onClick, and the 'to' prop is constrainted
// to this RA instance's basepath. As a workaround we navigate to an
// '/exit' route, which then forces a page refresh back to RAMainApp.
export const SwitchBandsLink = props => {
  return <MenuItemLink {...props} to="/exit" primaryText="Switch Band" leftIcon={<ArrowBackIcon />} />
}

export const LogoutLink = props => {
  return <MenuItemLink {...props} to="/logout" primaryText="Logout" leftIcon={<PowerSettingsNewIcon />} />
}

export const AccountLink = props => {
  return <MenuItemLink {...props} to="/account" primaryText="Account" leftIcon={<AccountCircle />} />
}

// ExitRoute reloads the page back to RAMainApp
export class ExitRoute extends Component {
  componentDidMount() {
    window.location.href = "/bands";
  }

  render() {
    return null;
  }
}