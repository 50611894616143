import React from "react";
import { PromoterAdvanceEditV1 } from "./advanceV1";
import {Edit} from "react-admin";
import {AdvanceTitle } from "../ra-common/advance";
import { PromoterAdvanceEditV2 } from "./advanceV2";

const AdvanceEditContent = (props) => {
  const { record } = props;
  const { version } = record;
  switch (version) {
    case "v_1":
      return <PromoterAdvanceEditV1 {...props} />;
    case "v_2":
      return <PromoterAdvanceEditV2 {...props} />;
    default:
      return <PromoterAdvanceEditV2 {...props} />
  }
};

export const AdvanceEditController = (props) => {
  return (
    <Edit title={<AdvanceTitle />} {...props}>
      <AdvanceEditContent />
    </Edit>
  );
};
