import { isEmpty, isString } from "lodash";
import he from "he";

/* *** Implementation of lodash.get function ***
   https://gist.github.com/harish2704/d0ee530e6ee75bad6fd30c98e5ad9dab#gistcomment-2060415
   Allows accessing into deeply nested objects. If any intermediate
   object isn't there, does not throw error. Rather, it returns
   defaultVal (or undefined).
*/
export function getProp(object, keys, defaultVal) {
  keys = Array.isArray(keys) ? keys : keys.split(".");
  object = object[keys[0]];
  if (object && keys.length > 1) {
    return getProp(object, keys.slice(1), defaultVal);
  }
  return object === undefined ? defaultVal : object;
}

export const removeEmpty = obj =>
  Object.fromEntries(
    Object.entries(obj)
      .map(([k, v]) => {
        if (v === Object(v)) {
          const rem = removeEmpty(v)
          return [k, isEmpty(rem) ? null : rem]
        }
        return [k, v]
      })
      .filter(([_, v]) => v !== undefined && v !== null && v !== '')
  );

export const unescapeAll = obj =>
  Object.fromEntries(
    Object.entries(obj)
      .map(([k, v]) => {
        if (typeof v === 'object' && !Array.isArray(v) && v !== null) return [k, unescapeAll(v)]
        if (isString(v)) return [k, he.decode(v)]
        return [k, v]
      })
  );