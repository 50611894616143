import React, { Component } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { Timezones, Hours, Minutes } from "../ra-common/constants";
import { withInput } from "../ra-common/input";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { dateTimeFormatter } from "../ra-common/date";

function _isTBA(o) {
  return !o.hasOwnProperty("time");
}

export class DateTimeTZInput extends Component {
  constructor(props) {
    super(props);

    this.handleTimezoneChange = this.handleTimezoneChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleTBAToggle = this.handleTBAToggle.bind(this);
    this.mkTimeChangeHandler = this.mkTimeChangeHandler.bind(this);
  }

  handleTimezoneChange = (event) => {
    let newValue = Object.assign({}, this.props.ff.input.value);
    newValue.timezone = event.target.value;
    this.props.ff.input.onChange(newValue);
  };

  handleDateChange = (d, val) => {
    let newValue = Object.assign({}, this.props.ff.input.value);
    newValue.date = val;
    this.props.ff.input.onChange(newValue);
  };

  handleTBAToggle = () => {
    if (_isTBA(this.props.ff.input.value)) {
      this.props.ff.input.onChange({
        date: this.props.ff.input.value.date || this.props.defaultDate,
        time: "00:00",
        timezone:
          this.props.ff.input.value.timezone || this.props.defaultTimezone,
      });
    } else {
      this.props.ff.input.onChange({
        date: this.props.ff.input.value.date,
        timezone: this.props.ff.input.value.timezone,
      });
    }
  };

  // get hours and minutes and concatenate them to get 00:00 time format
  mkTimeChangeHandler = (type) => (event) => {
    let newValue = Object.assign({}, this.props.ff.input.value);

    // depending on whether hour or minute is being changed
    // change just the part before or after the colon respectively
    if (type === "minute") {
      newValue.time =
        newValue.time.substr(0, newValue.time.indexOf(":")) +
        ":" +
        event.target.value;
    }
    if (type === "hour") {
      newValue.time =
        event.target.value +
        ":" +
        newValue.time.substr(newValue.time.indexOf(":") + 1);
    }

    // if timezone isn't explicitly selected, the time object has no timezone property
    // because handleTimezoneChange function is never called. So if that's the case,
    // fill it with the default timezone
    if (!this.props.ff.input.value.timezone) {
      newValue.timezone = this.props.defaultTimezone;
    }

    this.props.ff.input.onChange(newValue);
  };

  render() {
    const value = this.props.ff.input.value;
    const tba = _isTBA(value);

    let date = this.props.ff.input.value.date || this.props.defaultDate || "";
    let hour = "";
    let minute = "";
    let timezone =
     this.props.ff.input.value.timezone ||this.props?.defaultTimeZone ||  "";

    // Compute values for the three <Selects/> and date only if we're not TBA
    if (!tba) {
      date = this.props.ff.input.value.date || this.props.defaultDate || "";

      // split and keep part before colon of "00:00" to get hours
      hour = value.time.substr(0, value.time.indexOf(":"));

      // keep part after colon to get minutes
      minute = value.time.substr(value.time.indexOf(":") + 1);

      // first look for existing timezone, if none exists use defaultTimezone
      timezone =
       this.props.ff.input.value.timezone || this.props?.defaultTimeZone ||  "";
    }

    return (
      <div style={{ marginTop: 16, marginBottom: 16 }}>
        <div style={{ fontSize: "0.75rem", color: "#757575" }}>
          {this.props.label}
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>
            <Switch checked={!tba} onChange={this.handleTBAToggle} />
          </div>
          <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                value={dateTimeFormatter(date)}
                disabled={tba}
                onChange={this.handleDateChange}
                format="yyyy-MM-dd"
                autoOk={true}
              />
            </MuiPickersUtilsProvider>

            <div style={{ display: "flex", flexDirection: "row" }}>
              <div>
                <FormControl>
                  <Select
                    style={{ fontSize: "0.85rem" }}
                    value={hour}
                    onChange={this.mkTimeChangeHandler("hour")}
                    inputProps={{
                      name: "time",
                      id: "time",
                    }}
                    disabled={tba}
                    displayEmpty={tba}
                  >
                    {Hours}
                  </Select>
                </FormControl>
              </div>
              :
              <div>
                <FormControl>
                  <Select
                    style={{ fontSize: "0.85rem" }}
                    value={minute}
                    onChange={this.mkTimeChangeHandler("minute")}
                    inputProps={{
                      name: "time",
                      id: "time",
                    }}
                    disabled={tba}
                    displayEmpty={tba}
                  >
                    {Minutes}
                  </Select>
                </FormControl>
              </div>
              <div>
                <FormControl>
                  <Select
                    style={{ fontSize: "0.85rem" }}
                    value={timezone}
                    onChange={this.handleTimezoneChange}
                    inputProps={{
                      name: "Timezone",
                      id: "timezone",
                    }}
                    disabled={tba}
                    displayEmpty={tba}
                  >
                    {Timezones.map((tz, i) => (
                      <MenuItem key={i} value={tz.id}>
                        {tz.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withInput(DateTimeTZInput);
