import React from "react";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import { teal, red } from "@material-ui/core/colors";
import { useInput } from "ra-core";

const FiniteSwitch = withStyles({
  switchBase: {
    color: red[600],
    "&$checked": { color: teal[500] },
    "& + $track": { backgroundColor: red[300] },
  },
  checked: {},
  track: {},
})(Switch);

const NullSwitch = withStyles({
  switchBase: { opacity: 0, },
  track: { backgroundColor: "#999" },
  checked: {},
})(Switch);

const TrinarySwitch = props => {
  const inputState = useInput(props)
  const onChange = value => inputState.input.onChange(value);
  const handleBoolSetNull = () => onChange(undefined);
  const handleBoolChange = (_, value) => onChange(value);

  // Indicate whether or not a checked bool exists with color. If exists, the toggle is teal. If doesn't exist, toggle is grey
  const checked = inputState.input.value;
  const checkedExists = checked === true || checked === false
  if (!checkedExists) return <NullSwitch checked={null} onChange={handleBoolChange} />
  return <FiniteSwitch checked={checked} onChange={checked ? handleBoolChange : handleBoolSetNull} />
}

export default TrinarySwitch